<template>
  <v-container>
    <h2>Test herramienta para calcular el precio de un producto</h2>
    <v-text-field v-model="code" @input="calcularPrecios"></v-text-field>
    <p><strong v-if="data.precioRecomendado" class="green--text">{{(data.precioRecomendado).toFixed(3)}}</strong></p>
    <pre>{{data}}</pre>
  </v-container>
</template>

<script>
import ReferenciaService from '@/services/referencia.service'

export default {
  name:'HerramientasPrecio',

  data(){
    return{
      code:'',
      data:'',
    }
  },

  methods:{
    async calcularPrecios(){
      const precio = await ReferenciaService.calcularPrecio(this.code)
      this.data = precio
    }
  }
}
</script>
