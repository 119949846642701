import axios from '@/axios.config'

class ClienteService {
  static async getCategorias() {
    return await axios.get("/api/clientes/categorias").then((res) => res.data);
  }

  static create(data) {
    return axios.post("/api/clientes", data).then((res) => res.data);
  }

  static findAll(queryParams) {
    return axios
      .get("/api/clientes", { params: queryParams })
      .then((res) => res.data);
  }

  static findByPk(id) {
    return axios.get(`/api/clientes/${id}`).then((res) => res.data);
  }

  static update(id, data) {
    return axios.put(`/api/clientes/${id}`, data).then((res) => res.data);
  }

  static addMail(data) {
    return axios.post(`/api/mails`, data).then((res) => res.data);
  }

  static findMail(mailId) {
    return axios.get(`/api/mails/${mailId}`).then((res) => res.data);
  }

  static updateMail(mailId, data) {
    return axios.put(`api/mails/${mailId}`, data).then((res) => res.data);
  }

  static addTelefono(data) {
    return axios.post(`/api/telefonos`, data).then((res) => res.data);
  }

  static findTelefono(telefonoId) {
    return axios.get(`/api/telefonos/${telefonoId}`).then((res) => res.data);
  }

  static updateTelefono(telefonoId, data) {
    return axios
      .put(`api/telefonos/${telefonoId}`, data)
      .then((res) => res.data);
  }

  static getTransporte(transporteId) {
    return axios
      .get(`/api/transportes/${transporteId}`)
      .then((res) => res.data);
  }

  static getTransportes() {
    return axios.get("/api/transportes").then((res) => res.data);
  }

  static createTransporte(data) {
    return axios.post("/api/transportes", data).then((res) => res.data);
  }

  static updateTransporte(transporteId, data) {
    return axios
      .put(`/api/transportes/${transporteId}`, data)
      .then((res) => res.data);
  }

  static createDireccion(data) {
    return axios.post("/api/direcciones", data).then((res) => res.data);
  }

  static findDireccionByClienteId(clienteId) {
    return axios
      .get(`/api/clientes/${clienteId}/direcciones`)
      .then((res) => res.data);
  }

  static findDireccionByPk(id) {
    return axios.get(`/api/direcciones/${id}`).then((res) => res.data);
  }

  static updateDireccion(direccionId, data) {
    return axios
      .put(`/api/direcciones/${direccionId}`, data)
      .then((res) => res.data);
  }

  static findPagos() {
    return axios.get("/api/pagos").then((res) => res.data);
  }

  static exportarFacturas(cliente,desdeFecha,hastaFecha) {
    return axios
      .get(`/api/clientes/${cliente.id}/facturas`, {
        params: {desdeFecha, hastaFecha },
        responseType: "blob",
      })
      .then((res) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(res.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute(
          "download",
          `facturas_${cliente.nombre}_${new Date().toLocaleDateString()}.csv`
        ); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);

        return res.data;
      })
      .catch((error) => {
        alert(error);
        return error;
      });
  }
}

export default ClienteService