import AuthService from "@/services/auth.service";

import ConsultasIndex from "@/views/consultas/ConsultasIndex";

const rutasConsultas = [
  {
    path: "/consultas",
    component: ConsultasIndex,
    name: "consultas.index",
  },
];

export default rutasConsultas.map((route) => {
  // Agregar a cada ruta un Guard Before Enter para comprobar si el usuario autenticado tiene el rol
  return {
    ...route,
    beforeEnter: (to, from, next) => {
      if (AuthService.user().roles.includes("consultas")) next();
      else next({ name: "Error404View" });
    },
  };
});
