<template>
  <v-container>
    <h1 class="subtitle-1 grey--text">
      <router-link :to="{ name: 'pedidos.index' }">Pedidos</router-link>
    </h1>
    <v-alert v-if="porcentajeVolumen <= 90 && porcentajeVolumen" type="warning">
      El pedido esta incompleto, {{ SumaVolumenM2 }}m2. Hace un total del
      <strong>{{ porcentajeVolumen }}%</strong> del camion.
    </v-alert>
    <v-alert
      v-if="porcentajeVolumen > 90 && porcentajeVolumen < 110"
      type="success"
    >
      El pedido esta completo, {{ SumaVolumenM2 }}m2. Hace un total del
      <strong>{{ porcentajeVolumen }}%</strong> del camion.
    </v-alert>
    <v-alert v-if="porcentajeVolumen >= 110 && porcentajeVolumen" type="error">
      El pedido es superior a un trailer, {{ SumaVolumenM2 }}m2. Hace un total
      del <strong>{{ porcentajeVolumen }}%</strong> del camion.
    </v-alert>
    <v-card :loading="loaders.card">
      <v-container>
        <h2 class="mb-5 grey--text">Formulario Pedido</h2>
        <v-row>
          <v-col>
            <InputDate v-model="fecha" />
          </v-col>
          <v-col>
            <InputDate v-model="fechaExigida" />
          </v-col>
        </v-row>
        <v-text-field label="Numero de pedido" v-model="numero"></v-text-field>

        <AutoCliente :clienteId="fetchedPedido.cliente.id" v-model="cliente" />
        <AutoDireccion
          v-model="direccion"
          :cliente-id="cliente.id"
          :direccionId="fetchedPedido.direccion.id"
          class="mb-5"
        />
        <v-switch label="Recogida propia" v-model="recogidaPropia"></v-switch>
        <v-row v-for="(d, idx) in detalleCosteCalculado" :key="idx">
          <v-col cols="2">
            <v-text-field
              v-model.number="detalle[idx].cantidad"
              label="Uds"
              @click:append="swapCompletar(idx)"
              :append-icon="
                detalle[idx] && detalle[idx].completar
                  ? detalle[idx].completar == 1
                    ? 'mdi-plus'
                    : 'mdi-minus'
                  : 'mdi-equal'
              "
            >
            </v-text-field>
          </v-col>
          <v-col>
            <InputReferencia
              :idx="idx"
              :codigo="detalle[idx].referencia.codigo"
              @fetch="setDetalle"
              @close="removeDetalle(idx)"
              :clienteId="cliente.id"
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
              label="Precio"
              v-model="detalle[idx].precio"
              type="number"
              step="any"
              :error-messages="
                d.referencia.precio && d.precioConPorte > d.precio
                  ? `El precio de coste es ${d.precioConPorte.toFixed(2)}`
                  : null
              "
            ></v-text-field>
          </v-col>
					<v-col cols="2">
						<v-text-field label="Precio Imp." readonly :value="d.precioConImpuesto">

						</v-text-field>
					</v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            <v-btn color="primary" fab small @click="addDetalle">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="4">
            <v-row>
              <v-col>
                <v-text-field
                  label="IVA"
                  v-model.number="iva"
                  type="number"
                ></v-text-field>
                <v-text-field
                  label="Rec. Eq."
                  v-model.number="recEq"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea v-model="nota" label="nota"></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              v-if="!fetchedPedido.id"
              color="primary"
              :loading="loaders.btn"
              block
              :disabled="$v.$invalid"
              @click="crear"
              >Guardar pedido</v-btn
            >
            <v-btn
              v-else
              color="primary"
              :loading="loaders.btn"
              block
              :disabled="$v.$invalid"
              @click="actualizar"
              >Editar pedido</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import { required, minValue } from "vuelidate/lib/validators";

import AutoCliente from "@/components/AutoCliente";
import AutoDireccion from "@/components/AutoDireccion";
import InputReferencia from "@/components/InputReferencia";
import InputDate from "@/components/InputDate";

import PedidoService from "@/services/pedido.service";
import SnackbarService from "@/services/snackbar.service";
import ReferenciaService from "@/services/referencia.service";

export default {
  name: "PedidosForm",

  components: { AutoCliente, AutoDireccion, InputReferencia, InputDate },

  data() {
    return {
      cliente: {},
      direccion: {},
      numero: null,
      detalle: [],
      fecha: new Date(),
      fechaExigida: null,
      iva: 21,
      recEq: null,
      recogidaPropia: false,
      nota: null,
      loaders: {
        btn: false,
        card: true,
      },
      fetchedPedido: {
        cliente: {},
        direccion: {},
      },
    };
  },

  methods: {
    addDetalle() {
      this.detalle.push({
        cantidad: 0,
        referencia: {},
        precio: 0,
        completar: 0,
      });
    },

    swapCompletar(idx) {
      switch (this.detalle[idx].completar) {
        case 1:
          this.detalle[idx].completar = -1;
          break;
        case -1:
          this.detalle[idx].completar = 0;
          break;
        default:
          this.detalle[idx].completar = 1;
      }
    },

    removeDetalle(idx) {
      this.detalle.splice(idx, 1);
    },

    setDetalle(data) {
      const { idx } = data;
      this.detalle[idx].referencia = data;
    },

    async crear() {
      this.loaders.btn = true;

      const detalle = this.detalle.map((detalle) => {
        return {
          cantidad: detalle.cantidad,
          codigo: detalle.referencia.detalle.codeNormalizado,
          precio: detalle.precio,
          completar: detalle.completar,
        };
      });

      await PedidoService.create({
        clienteId: this.cliente.id,
        direccionId: this.direccion.id,
        detalle,
        fecha: this.fecha,
        fechaExigida: this.fechaExigida,
        iva: this.iva,
        recEq: this.recEq,
        numero: this.numero,
        recogidaPropia: this.recogidaPropia,
        nota: this.nota,
      });
      this.loaders.btn = false;
      await SnackbarService.set({ message: "Se ha creado el pedido" });
      this.$router.push({ name: "pedidos.index" });
    },

    async actualizar() {
      this.loaders.btn = true;

      const detalle = this.detalle.map((detalle) => {
        return {
          cantidad: detalle.cantidad,
          codigo: detalle.referencia.detalle.codeNormalizado,
          precio: detalle.precio,
          completar: detalle.completar,
        };
      });

      await PedidoService.update(this.fetchedPedido.id, {
        clienteId: this.cliente.id,
        direccionId: this.direccion.id,
        detalle,
        fecha: this.fecha,
        fechaExigida: this.fechaExigida,
        iva: this.iva,
        recEq: this.recEq,
        numero: this.numero,
        recogidaPropia: this.recogidaPropia,
        nota: this.nota,
      });
      this.loaders.btn = false;
      await SnackbarService.set({ message: "Se ha editado el pedido" });
      this.$router.push({ name: "pedidos.index" });
    },
  },

  computed: {
    SumaVolumenM2() {
      // Si no hay referencias
      if (this.detalle.length < 1) return 0;
      // Reducer
      return this.detalle.reduce((total, detalle) => {
        // Si la referencia iterada no es correcta (El objeto esta vacio),no se puede calcular su volumen. Pasar a la siguiente.
        if (Object.entries(detalle.referencia).length === 0) return total;
        // Suma total de volumenes
        return total + detalle.cantidad * detalle.referencia.volumen.volumen;
      }, 0);
    },

    porcentajeVolumen() {
      const m2Trailer = 22_000;
      return parseInt((this.SumaVolumenM2 / m2Trailer) * 100);
    },

    detalleCosteCalculado() {
			const detalle = this.detalle.map(detalle => {
				let precioConImpuesto;
				if(detalle?.referencia?.detalle && detalle?.referencia?.detalle?.excento == false && detalle.precio){
					precioConImpuesto = detalle.referencia.detalle.kgs * 0.45 + Number(detalle.precio)
				}else{
					precioConImpuesto = detalle.precio
				}
				return {
					...detalle,
					precioConImpuesto
				}
			})


      // El coste de transporte se saca de la direccion
      if (!this.direccion || !this.direccion.transporte) return detalle;
      // Calcular el coste de transporte por m2 y aplicar la subida sobre el precio para tener precioCoste+precioTransporte
      const costeTransporte = this.direccion.transporte.precio;
      const m2CosteTransporte = costeTransporte / 22_000; // 22_000m2 por trailer
      return detalle.map((detalle) => {
        if (!detalle.referencia.detalle || detalle.referencia.error)
          return detalle

        const sobreCosteTransporte =
          detalle.referencia.volumen.volumen * m2CosteTransporte;
        return {
          ...detalle,
          precioConPorte:
            detalle.referencia.precio.precio + sobreCosteTransporte,
        };
      });
    },
  },

  validations: {
    cliente: { required },
    direccion: { required },
    detalle: {
      required,
      $each: {
        cantidad: { required, minValue: minValue(1) },
        referencia: { detalle: { codeNormalizado: { required } } },
      },
    },
  },

  async created() {
    const { id } = this.$route.params;
    if (!id) return (this.loaders.card = false);
    const pedido = await PedidoService.findByPk(id);
    console.log(pedido);
    this.cliente = pedido.cliente;
    this.iva = pedido.iva;
    this.recEq = pedido.recEq;
    this.fecha = new Date(pedido.fecha);
    this.fechaExigida = pedido.fechaExigida
      ? new Date(pedido.fechaExigida)
      : null;
    this.fetchedPedido = pedido;
    this.numero = pedido.numero;
    this.recogidaPropia = pedido.recogidaPropia;
    this.nota = pedido.nota;

    this.detalle = await Promise.all(
      pedido.detalle.map(async (detalle) => {
        const detalleFetched = await ReferenciaService.detalle(
          detalle.referencia.codigo
        );
        return {
          ...detalle,
          referencia: {
            ...detalle.referencia,
            ...detalleFetched,
          },
        };
      })
    );

    this.loaders.card = false;
  },
};
</script>