<template>
  <v-container>
    <v-form>
      <v-card>
        <v-container>
          <v-row>
            <v-col>
              <h2>Costes</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="4"
              v-for="variable in variables"
              :key="variable.nombre"
            >
              <v-text-field
                :label="variable.nombre"
                type="number"
                v-model.number="variable.precio"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn color="primary" block @click="updateAll">Guardar</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import VariableService from "@/services/variable.service";
import Snackbar from '@/services/snackbar.service'

export default {
  name: "CostesVariables",

  data() {
    return {
      variables: [
      ],
    };
  },

  async created() {
    this.variables = await VariableService.findAll();
  },

  methods:{
    async updateAll(){
      await VariableService.updateAll(this.variables)
      await Snackbar.set({message:'Se han actualizado los costes'})
      await this.$router.push({name:'costes.index'})
    }
  }
};
</script>