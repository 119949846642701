<template>
  <v-autocomplete v-model="selectedCliente" :items="clientes" item-text="nombre" return-object label="Cliente" append-icon="mdi-close" @click:append="selectedCliente={}"/>
</template>

<script>
import ClienteService from '@/services/cliente.service'

export default {
  name:'AutoCliente',

  props:['clienteId'],

  data(){
    return{
      clientes:[],
      selectedCliente:{},
    }
  },

  watch:{
    selectedCliente(){
      this.$emit('input',this.selectedCliente)
    },

    async clienteId(){
      if(this.clienteId){
        this.selectedCliente = await ClienteService.findByPk(this.clienteId)
      }
    }
  },

  async created(){
    const res = await ClienteService.findAll()
    this.clientes = res.rows
    if(this.clienteId){
      this.selectedCliente = await ClienteService.findByPk(this.clienteId)
    }
  }
}
</script>