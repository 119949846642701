import AuthService from '@/services/auth.service'
import ProduccionCrear from '@/views/produccion/ProduccionCrear'

const rutasProduccion = [
  {
    path:'/produccion/crear',
    component: ProduccionCrear,
    name:'produccion.crear'
  }
]

export default rutasProduccion.map(route=>{
  // Agregar a cada ruta un Guard Before Enter para comprobar si el usuario autenticado tiene el rol
  return{
    ...route,
    beforeEnter:(to,from,next)=>{
      if(AuthService.user().roles.includes('produccion')) next()
      else next({name:'Error404View'})
    }
  } 
})