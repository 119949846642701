import AuthService from '@/services/auth.service'

import BancoIndex from '@/views/banco/BancoIndex'
import RecibosIndex from '@/views/banco/recibos/RecibosIndex'
import RecibosForm from '@/views/banco/recibos/RecibosForm'

const rutasBanco = [
  {
    path:'/banco',
    component:BancoIndex,
    name:'banco.index',
  },
  {
    path:'/banco/recibos',
    component:RecibosIndex,
    name:'banco.recibos.index',
  },
  {
    path:'/banco/recibos/crear',
    component:RecibosForm,
    name:'banco.recibos.crear',
  },
  {
    path:'/banco/recibos/editar/:id',
    component:RecibosForm,
    name:'banco.recibos.editar',
  },
]


export default rutasBanco.map(route=>{
  // Agregar a cada ruta un Guard Before Enter para comprobar si el usuario autenticado tiene el rol
  return{
    ...route,
    beforeEnter:(to,from,next)=>{
      if(AuthService.user().roles.includes('banco')) next()
      else next({name:'Error404View'})
    }
  } 
})