
<template>
  <v-container>
    <v-row>
      <v-col>
        <h1 class="subtitle-1 grey--text">Clientes</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn color="primary" dark class="mb-5" :to="{ name: 'clientes.form' }"
          >Crear cliente</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          solo
          label="Buscar"
          v-model="filtroCliente"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="clientes"
          :server-items-length="totalClientes"
          :options.sync="options"
          :footer-props="footerProps"
          dense
          disable-sort
        >
          <template v-slot:[`item.nombre`]="{ item }">
            <router-link
              :to="{ name: 'clientes.show', params: { id: item.id } }"
              >{{ item.nombre }}</router-link
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ClienteService from "@/services/cliente.service";

export default {
  name: "ClientesIndex",
  data() {
    return {
      headers: [
        { text: "id", value: "id" },
        { text: "Nombre", value: "nombre" },
      ],
      clientes: [],
      loading: true,
      filtroCliente: "",
      totalClientes: -1,
      options: {},
      footerProps: {
        "items-per-page-options": [25, 50, 100],
      },
    };
  },

  async created() {
    await this.fetchClientes()
  },

  methods: {
    async fetchClientes() {
      this.loading = true;
      const res = await ClienteService.findAll({
        cliente: this.filtroCliente,
        ...this.options,
      });
      this.clientes = res.rows;
      this.totalClientes = res.count;
      this.loading = false;
    },
  },

  watch: {
    options: {
      handler() {
        this.fetchClientes();
      },
      deep: true,
    },

    filtroCliente() {
      this.options.page = 1;
      this.fetchClientes();
    },
  },
};
</script>