<template>
  <v-container>
    <h1 class="subtitle-1 grey--text">Usuarios</h1>
    <v-btn color="primary" class="my-5" :to="{name:'usuarios.crear'}">crear usuario</v-btn>
    <v-card>
      <v-list>
        <v-list-item v-for="usuario in usuarios" :key="usuario._id" link :to="{name:'usuarios.roles',params:{id:usuario._id}}">
          <v-list-item-content>
            <v-list-item-title>{{usuario.email}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-container>
</template>

<script>
import UsuarioService from '@/services/usuario.service'

export default {
  name: "UsuariosIndex",

  data(){
    return{
      usuarios:[]
    }
  },

  created(){
    UsuarioService.getUsuarios()
      .then(usuarios=>this.usuarios=usuarios)
      .catch(error=>console.error(error))
  }
};
</script>