<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card :loading="loaders.card">
          <v-container>
            <v-row>
              <v-col>
                <h1>Abono Factura</h1>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p>Factura: <router-link :to="{name:'facturas.show',params:{id:factura.id}}">{{factura.numero}}</router-link></p>
                <p>Fecha: {{factura.createdAt | fecha}}</p>
                <p>Importe: {{factura.importeTotal}}</p>
                <p v-if="abono.numero">Numero Abono: {{abono.numero}}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  type="number"
                  label="Importe abono"
                  v-model.number="abono.importe"
                  hint="Sin iva, recargo o descuento"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea v-model="abono.descripcion" label="Descripción del abono"> </v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn :loading="loaders.btn" v-if="!abono.id" block color="primary" @click="crear">Crear abono</v-btn>
                <v-btn :loading="loaders.btn" v-if="abono.id" block color="primary" @click="editar">Editar abono</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FacturaService from '@/services/factura.service'
import AbonoService from '@/services/factura/abono.service'
import SnackbarService from "@/services/snackbar.service";

export default {
  name: "FacturasAbonoForm",

  data() {
    return {
      abono:{
        facturaId: null,
        importe: null,
        descripcion: null,
      },
      factura:{},
      loaders:{
        card:true,
        btn:false,
      }
    };
  },

  async created(){
    const {facturaId,id} = this.$route.params
    this.factura = await FacturaService.findByPk(facturaId)
    this.abono.facturaId = this.factura.id
    if(id){
      this.abono = await AbonoService.findByPk(facturaId,id)
    }
    this.loaders.card = false
  },

  methods:{
    async crear(){
      this.loaders.btn = true
      await AbonoService.create(this.factura.id,this.abono)
      await SnackbarService.set({
        message:'Se ha creado el abono'
      })
      this.$router.push({name:'facturas.show',params:{id:this.factura.id}})
      this.loaders.btn = false
    },

    async editar(){
      this.loaders.btn = true
      await AbonoService.update(this.factura.id, this.abono.id, this.abono)
      await SnackbarService.set({
        message:'Se ha editado el abono'
      })
      this.$router.push({name:'facturas.show',params:{id:this.factura.id}})
      this.loaders.btn = false
    }
  }
};
</script>