import axios from '@/axios.config'

class PedidoService {
  static create(data){
    return axios.post('/api/pedidos',data)
      .then(res=>res.data)
  }

  static findAll(queryParams){
    return axios.get('/api/pedidos',{params:queryParams})
      .then(res=>res.data)
  }

  static findByPk(pedidoId){
    return axios.get(`/api/pedidos/${pedidoId}`)
      .then(res=>res.data)
  }

  static update(pedidoId,data){
    return axios.put(`/api/pedidos/${pedidoId}`,data)
    .then(res=>res.data)
  }
}

export default PedidoService