import axios from '@/axios.config'

class AlbaranService {
  static create(data){
    return axios.post('/api/albaranes',data)
      .then(res=>res.data)
  }

  static update(albaranId,data){
    return axios.put(`/api/albaranes/${albaranId}`,data)
      .then(res=>res.data)
  }

  static findAll(queryParams){
    return axios.get('/api/albaranes',{params:queryParams})
      .then(res=>res.data)
  }

  static findByPk(id){
    return axios.get(`/api/albaranes/${id}`)
      .then(res=>res.data)
  }

  static downloadPDF(presupuestoId){
    return axios.get(`/api/albaranes/${presupuestoId}/pdf`,{responseType:'blob'})
    .then(response=>{
      window.open(URL.createObjectURL(response.data));
    })
    .catch(err=>{
      alert(err)
    })
  }

  static downloadCMRPDF(data){
    return axios.post(`/api/albaranes/cmr`,data,{responseType: 'blob', headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/pdf'
  }})
    .then( response=>{
      window.open(URL.createObjectURL(response.data));
    })
  }

  static findAllDetalles(){
    return axios.get('/api/albaranes/detalles')
      .then(res=>res.data)
  }

}

export default AlbaranService