import AuthService from '@/services/auth.service'

import ClientesIndex from '@/views/clientes/ClientesIndex'
import ClientesShow from '@/views/clientes/ClientesShow'
import ClientesForm from '@/views/clientes/ClientesForm'
import ClientesMailForm from '@/views/clientes/ClientesMailForm'
import ClientesTelefonoForm from '@/views/clientes/ClientesTelefonoForm'
import ClientesDireccionForm from '@/views/clientes/ClientesDireccionForm'

const rutasClientes = [
  {
    path:'/clientes',
    component:ClientesIndex,
    name:'clientes.index',
  },
  {
    path:'/clientes/:id',
    component: ClientesShow,
    name: 'clientes.show'
  },
  {
    path:'/clientes/:id/editar',
    component: ClientesForm,
    name: 'clientes.form'
  },
  {
    path:'/clientes/:clienteId/mail',
    component: ClientesMailForm,
    name: 'clientes.mail'
  },
  {
    path:'/clientes/:clienteId/telefono',
    component: ClientesTelefonoForm,
    name: 'clientes.telefono'
  },
  {
    path:'/clientes/:clienteId/direccion/:id?',
    component: ClientesDireccionForm,
    name: 'clientes.direccion'
  },
]

export default rutasClientes.map(route=>{
  // Agregar a cada ruta un Guard Before Enter para comprobar si el usuario autenticado tiene el rol
  return{
    ...route,
    beforeEnter:(to,from,next)=>{
      if(AuthService.user().roles.includes('clientes')) next()
      else next({name:'Error404View'})
    }
  } 
})