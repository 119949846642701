<template>
  <v-app>
    <AppNavbar
      v-if="$route.name !== 'auth.login' && $route.name !== 'auth.reset'"
    />
    <AppSnackbar />
    <v-main class="grey lighten-4">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import AppNavbar from "@/components/AppNavbar";
import AppSnackbar from "@/components/AppSnackbar";

export default {
  name: "App",

  components: {
    AppNavbar,
    AppSnackbar,
  },
};
</script>