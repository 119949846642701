<template>
  <nav>
    <v-app-bar app color="primary" dark dense>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-app-bar-title>PlasticosQ2</v-app-bar-title>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app class="primary" dark>
      <v-container class="white--text my-5">
        <v-row>
          <v-icon v-if="user" x-large class="mx-auto"
            >mdi-account-circle</v-icon
          >
          <v-icon v-else x-large class="mx-auto">mdi-help-circle</v-icon>
        </v-row>
        <v-row justify="center">
          <span v-if="user">{{ user.mail }}</span>
          <span v-else>Anonimo</span>
        </v-row>
        <v-row>
          <v-col class="text-center" v-if="!user">
            <router-link class="white--text" :to="{ name: 'login' }"
              >Login</router-link
            >
          </v-col>
          <v-col class="text-center" v-else>
            <v-btn small outlined @click="logout">Cerrar sesión</v-btn>
          </v-col>
        </v-row>
      </v-container>

      <v-list dense>
        <v-list-item
          v-for="link in linksPermitidos"
          :key="link.icon"
          link
          :to="{ name: link.routeName }"
        >
          <v-list-item-action>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ link.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import AuthService from "@/services/auth.service";

export default {
  name: "AppNavbar",

  data() {
    return {
      drawer: false,
      links: [
        { icon: "mdi-home", text: "Inicio", routeName: "home" },
        {
          icon: "mdi-shield-account",
          text: "Usuarios",
          routeName: "usuarios.index",
          rol: "administrador",
        },
        {
          icon: "mdi-flag",
          text: "Features",
          routeName: "features.index",
          rol: "features",
        },
        {
          icon: "mdi-currency-eur",
          text: "Costes",
          routeName: "costes.index",
          rol: "costes",
        },
        {
          icon: "mdi-account",
          text: "Clientes",
          routeName: "clientes.index",
          rol: "clientes",
        },
        {
          icon: "mdi-account-tie-voice",
          text: "Pedidos",
          routeName: "pedidos.index",
          rol: "pedidos",
        },
        {
          icon: "mdi-file-sign",
          text: "Presupuestos",
          routeName: "presupuestos.index",
          rol: "presupuestos",
        },
        {
          icon: "mdi-factory",
          text: "Fabricación",
          routeName: "fabricacion.index",
          rol: "fabricacion",
        },
        {
          icon: "mdi-cog",
          text: "Produccion",
          routeName: "produccion.crear",
          rol: "produccion",
        },
        {
          icon: "mdi-truck",
          text: "Albaranes",
          routeName: "albaranes.index",
          rol: "albaranes",
        },
        {
          icon: "mdi-cash-multiple",
          text: "Facturas",
          routeName: "facturas.index",
          rol: "facturas",
        },
        {
          icon: "mdi-account-cash",
          text: "Proveedores",
          routeName: "proveedores.index",
          rol: "proveedores",
        },
        {
          icon: "mdi-cart",
          text: "Compras",
          routeName: "compras.index",
          rol: "compras",
        },
        {
          icon: "mdi-bank",
          text: "Banco",
          routeName: "banco.index",
          rol: "banco",
        },
        {
          icon: "mdi-package-variant-closed",
          text: "Referencias",
          routeName: "referencias.index",
          rol: "referencias",
        },
        {
          icon: "mdi-hammer-screwdriver",
          text: "Herramientas",
          routeName: "herramientas.index",
          rol: "herramientas",
        },
        {
          icon: "mdi-chart-bar",
          text: "Consultas",
          routeName: "consultas.index",
          rol: "consultas",
        },
				{
					icon:"mdi-watch",
					text: "Horas",
					routeName: "horas.index",
				}
      ],
    };
  },

  methods: {
    logout() {
      AuthService.logout();
    },
  },

  computed: {
    user() {
      return AuthService.user();
    },
    linksPermitidos() {
      if (!this.user.roles)
        return [
          {
            icon: "mdi-home",
            routeName: "home",
            text: "Inicio",
          },
        ];
      return this.links.filter((link) => {
        // Si la ruta no tiene asignada un rol siempre mostrar
        if (!link.rol) return true;
        // Si titien asginado algun rol comprobar si el usuario lo tiene.
        if (this.user.roles.includes(link.rol)) return true;
      });
    },
  },
};
</script>