import axios from 'axios'
import {VUE_APP_API_BASE_URL} from './config'
import AuthService from '@/services/auth.service'

const defaultOptions = {
  baseURL: VUE_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  }
};

let axiosInstance = axios.create(defaultOptions);

axiosInstance.interceptors.request.use(function (config) {
  const user = AuthService.user()
  if (user) {
    const token = user.accessToken
    config.headers.Authorization = token ? `Bearer ${token}` : '';
  }
  return config;
});

export default axiosInstance;