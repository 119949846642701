import AuthService from '@/services/auth.service'

import ProveedoresIndex from '@/views/proveedores/ProveedoresIndex'
import ProveedoresForm from '@/views/proveedores/ProveedoresForm'


const rutasProveedores = [
  {
    path:'/proveedores',
    component:ProveedoresIndex,
    name:'proveedores.index',
  },
  {
    path:'/proveedores/formulario/:id?',
    component:ProveedoresForm,
    name:'proveedores.formulario',
  },
]

export default rutasProveedores.map(route=>{
  // Agregar a cada ruta un Guard Before Enter para comprobar si el usuario autenticado tiene el rol
  return{
    ...route,
    beforeEnter:(to,from,next)=>{
      if(AuthService.user().roles.includes('proveedores')) next()
      else next({name:'Error404View'})
    }
  } 
})