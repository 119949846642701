import axios from '@/axios.config'

class FabricacionService {
  static findAll(queryParams){
    return axios.get('/api/fabricaciones',{params:queryParams})
      .then(res=>res.data)
  }

  static marcarFabricado(id,data){
    return axios.post(`/api/fabricaciones/${id}/marcar-fabricado`,data)
      .then(res=>res.data)
  }
}

export default FabricacionService