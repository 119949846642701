<template>
  <v-container>
    <!-- <pre>{{detalleCosteCalculado}}</pre> -->
    <v-alert v-if="!checkTransporteGiven" type="error">
      La direccion seleccionada no tiene asignado un coste de transporte. No se
      puede calcular costes.
    </v-alert>
    <v-alert v-else :type="BeneficioTotal > 200 ? 'success' : 'warning'">
      El beneficio total de la factura es de: {{ BeneficioTotal.toFixed(2) }}
    </v-alert>
    <v-card :loading="loaders.card">
      <v-card-title>Formulario Factura</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              readonly
              label="Cliente"
              :value="factura.cliente.nombre"
            />
          </v-col>
          <v-col>
            <v-text-field
              readonly
              label="Entrega"
              :value="factura.direccion.nombre"
            />
          </v-col>
        </v-row>
        <v-row v-if="factura.recogidaPropia">
          <v-col>
            <v-chip color="primary">Recogida propia</v-chip>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              readonly
              label="Albaran"
              :value="dataAlbaran.numero"
            />
          </v-col>
        </v-row>
        <v-row v-for="(detalle, idx) in factura.detalle" :key="detalle.id">
          <v-col cols="2"
            ><v-text-field
              label="Cantidad"
              v-model="detalle.cantidad"
            ></v-text-field
          ></v-col>
          <v-col
            ><InputReferencia
              :idx="idx"
              :codigo="detalle.referencia.codigo"
              @fetch="setDetalle"
              @close="removeDetalle(idx)"
              :clienteId="dataAlbaran.clienteId"
          /></v-col>
          <v-col cols="2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-on="on"
                  label="Precio"
                  v-model="detalle.precio"
                  type="number"
                ></v-text-field>
              </template>
              <span
                v-if="
                  detalle.referencia &&
                  detalleCosteCalculado &&
                  detalle.referencia.precio
                "
              >
                <table>
                  <tr>
                    <td>Coste</td>
                    <td class="text-right">
                      {{ detalle.referencia.precio.precio }}
                    </td>
                  </tr>
                  <tr>
                    <td>Coste+P</td>
                    <td class="text-right">
                      {{ detalleCosteCalculado[idx].precioConPorte }}
                    </td>
                  </tr>
                  <tr>
                    <td>Beneficio</td>
                    <td class="text-right">
                      {{
                        detalleCosteCalculado[idx].precio -
                        detalleCosteCalculado[idx].precioConPorte
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>U.Pres.</td>
                    <td class="text-right">
                      {{ detalle.referencia.queries.ultimoPrecioPresupuestado }}
                    </td>
                  </tr>
                  <tr>
                    <td>U.Fact.</td>
                    <td class="text-right">
                      {{ detalle.referencia.queries.ultimoPrecioFacturado }}
                    </td>
                  </tr>
                </table>
              </span>
              <span v-else>Pendiente cambios...</span>
            </v-tooltip>
          </v-col>
					<v-col cols="2">
						<v-text-field label="Precio Imp." readonly :value="detalleCosteCalculado[idx].precioConImpuesto">
						</v-text-field>
					</v-col>
        </v-row>

				<v-row>
					<v-col class="text-center">
						<v-btn color="primary" fab small @click="addDetalle">
							<v-icon>mdi-plus</v-icon>
						</v-btn>
					</v-col>
				</v-row>

        <v-row>
          <v-col>
            <v-text-field
              label="Presupuestos"
              readonly
              :value="factura.presupuestos"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
					<v-col cols="4">
						<v-text-field cols="4"
							label="KGs Mas de un Uso"
							placeholder="Solo para plastico reutilizable"
							v-model.number="factura.kgs_reutilizables"	
						>
						</v-text-field>
					</v-col>
          <v-col cols="4">
            <v-text-field
            cols="4"
                label="KGs manipulados"
                :value="kgsTotal"
                readonly
              />
          </v-col>
        </v-row>
        <v-row>
        <v-spacer></v-spacer>
        <v-col cols="4">
          <v-text-field
          cols="4"
              label="Impuesto Plastico KGs"
              :value="importeImpuestoKgs"
              readonly
            />
        </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="4"
            ><v-text-field
              label="% IVA"
              v-model.number="factura.fiscal.iva"
            ></v-text-field
          ></v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="4"
            ><v-text-field
              readonly
              label="Importe IVA"
              :value="importeIva.toFixed(2)"
            ></v-text-field
          ></v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="4"
            ><v-text-field
              label="% Rec. Eq."
              v-model.number="factura.fiscal.recEq"
          /></v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="4"
            ><v-text-field
              readonly
              label="Importe Rec. Eq."
              :value="importeRecEq.toFixed(2)"
            ></v-text-field
          ></v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="4"
            ><v-text-field
              label="% Descuento"
              v-model.number="factura.fiscal.descuento"
          /></v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="4"
            ><v-text-field
              readonly
              label="Importe Descuento"
              :value="importeDescuento.toFixed(2)"
            ></v-text-field
          ></v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="4"
            ><v-text-field
              readonly
              label="Importe B.Imponible"
              :value="baseImponible.toFixed(2)"
            ></v-text-field
          ></v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="4"
            ><v-text-field
              readonly
              label="Total Factura"
              :value="importeTotal.toFixed(2)"
            ></v-text-field
          ></v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea label="Nota" v-model="factura.nota"></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              v-if="!factura.id"
              color="primary"
              :loading="loaders.btn"
              block
              :disabled="$v.$invalid"
              @click="crear"
              >Crear factura</v-btn
            >
            <v-btn
              v-else
              color="primary"
              :loading="loaders.btn"
              block
              :disabled="$v.$invalid"
              @click="actualizar"
              >Actualizar factura</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { required, decimal } from "vuelidate/lib/validators";

import AlbaranService from "@/services/albaran.service";
import FacturaService from "@/services/factura.service";
import SnackbarService from "@/services/snackbar.service";
import ReferenciaService from "@/services/referencia.service";

import InputReferencia from "@/components/InputReferencia";

export default {
  name: "FacturasForm",

  components: { InputReferencia },

  data() {
    return {
      fecha: new Date(),
      factura: {
        cliente: {},
        direccion: {},
        usuario: {},
        detalle: [],
        fiscal: {
          iva: 21,
          recEq: null,
          descuento: null,
        },
        nota: null,
				kgs_reutilizables: 0,
      },
      dataAlbaran: {},
      loaders: {
        card: true,
        btn: false,
      },
    };
  },

  computed: {
    kgsTotal(){
      if(!this.factura || !this.factura.detalle) return 0
      const total = this.factura.detalle.reduce((totalKgs,detalle) => {
        // Excentos
        if(detalle?.referencia?.detalle?.excento){
          return totalKgs
        }

        // Caluclo No excentos
        if(detalle?.referencia?.detalle?.kgs){
          return totalKgs + (detalle.cantidad * detalle.referencia.detalle.kgs)
        }else{
          return totalKgs
        }
      },0)

      return total.toFixed(3)
    },

    importeImpuestoKgs(){
      return (this.kgsTotal * 0.45).toFixed(3)
    },
    
    baseImponible() {
			const total = this.detalleCosteCalculado.reduce((total, detalle) => {
        const { precioConImpuesto, cantidad } = detalle;
        const totalDetalle = precioConImpuesto * cantidad;
        return total + totalDetalle;
      }, 0);
      if (isNaN(total)) return 0;
      return total;
    },
    importeIva() {
      if (!this.factura.fiscal.iva) return 0;
      const total = (this.factura.fiscal.iva / 100) * this.baseImponible;
      if (isNaN(total)) return 0;
      return total;
    },
    importeRecEq() {
      if (!this.factura.fiscal.recEq) return 0;
      const total = (this.factura.fiscal.recEq / 100) * this.baseImponible;
      if (isNaN(total)) return 0;
      return total;
    },
    importeDescuento() {
      if (!this.factura.fiscal.descuento) return 0;
      const total = (this.factura.fiscal.descuento / 100) * this.baseImponible;
      if (isNaN(total)) return 0;
      return total;
    },
    importeTotal() {
      const total =
        this.baseImponible +
        this.importeIva +
        this.importeRecEq -
        this.importeDescuento;
      if (isNaN(total)) return 0;
      return total;
    },

    detalleCosteCalculado() {

			const detalle = this.factura.detalle.map(detalle => {
				let precioConImpuesto;
				if (detalle?.referencia?.detalle && detalle?.referencia?.detalle?.excento == false && detalle.precio && detalle.referencia.detalle.kgs) {
					precioConImpuesto = (detalle.referencia.detalle.kgs * 0.45 + Number(detalle.precio)).toFixed(3)
				} else {
					precioConImpuesto = detalle.precio
				}
				return {
					...detalle,
					precioConImpuesto
				}
			})


      // El coste de transporte se saca de la direccion
      if (!this.factura.direccion || !this.factura.direccion.transporte)
        return detalle;
      // Calcular el coste de transporte por m2 y aplicar la subida sobre el precio para tener precioCoste+precioTransporte
      let costeTransporte = this.factura.direccion.transporte.precio;
      if (this.factura.recogidaPropia) {
        costeTransporte = 0;
      }
      const m2CosteTransporte = costeTransporte / 22_000; // 22_000m2 por trailer
      return detalle.map((d) => {
        if (!d.referencia) return "error detalleCosteCalculado";
        if (
          !d.referencia.detalle ||
          d.referencia.error ||
          !d.referencia.volumen ||
          d.referencia.precio.error
        )
          return d;
        const sobreCosteTransporte =
          d.referencia.volumen.volumen * m2CosteTransporte;
        return {
          ...d,
          precioConPorte: d.referencia.precio.precio + sobreCosteTransporte,
          beneficio:
            d.precio - d.referencia.precio.precio - sobreCosteTransporte,
        };
      });
    },

    SumaVolumenM2() {
      // Si no hay referencias
      if (this.factura.detalle.length < 1) return 0;
      // Reducer
      return this.factura.detalle.reduce((total, detalle) => {
        if (!detalle.referencia) return total;
        // Si la referencia iterada no es correcta (El objeto esta vacio),no se puede calcular su volumen. Pasar a la siguiente.
        if (Object.entries(detalle.referencia).length === 0) return total;
        // Suma total de volumenes
        return total + detalle.cantidad * detalle.referencia.volumen.volumen;
      }, 0);
    },

    porcentajeVolumen() {
      const m2Trailer = 22_000;
      return parseInt((this.SumaVolumenM2 / m2Trailer) * 100);
    },

    checkTransporteGiven() {
      if (!this.factura.direccion || !this.factura.direccion.transporte)
        return false;
      return true;
    },

    BeneficioTotal() {
      if (this.detalleCosteCalculado.length < 1) return 0;
      // Iterar cada detalle del presupuesto y sacar datos sumados de precios y costes
      let totalPrecio = this.detalleCosteCalculado.reduce((total, detalle) => {
        const venta = detalle.precio;
        const coste = detalle.precioConPorte;
        const beneficioPorReferencia = venta - coste;
        const sumatorioBeneficio = beneficioPorReferencia * detalle.cantidad;
        return total + sumatorioBeneficio;
      }, 0);
      if (isNaN(totalPrecio)) return 0;
      return totalPrecio;
    },
  },

  async beforeCreate() {
    // Comprobar si la ruta es de factura edicion
    const { id } = this.$route.params;
    if (id) {
      const factura = await FacturaService.findByPk(id);
      this.factura = factura;
      this.dataAlbaran = factura.albaran;
      this.factura.cliente = this.factura.albaran.cliente;
      this.factura.direccion = this.factura.albaran.direccion;
      this.factura.fiscal = {};
      this.factura.fiscal.iva = this.factura.iva;
      this.factura.fiscal.descuento = this.factura.descuento;
      this.factura.fiscal.recEq = this.factura.recEq;
			this.kgs_reutilizables = this.factura.kgs_reutilizables
      this.loaders.card = false;

      // Presupuestos
      this.factura.presupuestos = this.dataAlbaran.presupuestos;

      return false;
    }

    // Si NO se esta editando la factura

    // Sacar datos de los albaranes y fabricaciones con las que se esta creando
    const { albaranId } = this.$route.params;
    if (!albaranId) return false;
    // Fetch al backend
    const dataAlbaran = await AlbaranService.findByPk(albaranId);

    // Guardar los datos a parte para trabajar con ello
    this.dataAlbaran = dataAlbaran;

    // Presupuestos
    this.factura.presupuestos = this.dataAlbaran.presupuestos.reduce(
      (array, presupuesto) => {
        array.push(presupuesto.numero);
        return array;
      },
      []
    );

    // IVA
    let arrayIva = this.dataAlbaran.fabricaciones.map((fabricacion) => {
      if (!fabricacion || !fabricacion.presupuestoDetalle) return null;
      return fabricacion.presupuestoDetalle.presupuesto.iva;
    });
    arrayIva = arrayIva.filter((iva) => iva); // Filtro para quitar los que son null
    this.factura.fiscal.iva = 0;
    if (arrayIva.every((iva) => iva === arrayIva[0])) {
      this.factura.fiscal.iva = arrayIva[0];
    }

    // Recargo de Equivalencia
    let arrayRecEq = this.dataAlbaran.fabricaciones.map((fabricacion) => {
      if (!fabricacion || !fabricacion.presupuestoDetalle) return null;
      return fabricacion.presupuestoDetalle.presupuesto.recEq;
    });
    arrayRecEq = arrayRecEq.filter((recEq) => recEq);
    this.factura.fiscal.recEq = 0;
    if (arrayRecEq.every((recEq) => recEq === arrayRecEq[0])) {
      this.factura.fiscal.recEq = arrayRecEq[0];
    }

    // Descuento
    let arrayDescuento = this.dataAlbaran.fabricaciones.map((fabricacion) => {
      if (!fabricacion || !fabricacion.presupuestoDetalle) return null;
      return fabricacion.presupuestoDetalle.presupuesto.descuento;
    });
    arrayDescuento = arrayDescuento.filter((descuento) => descuento);
    this.factura.fiscal.descuento = 0;
    if (arrayDescuento.every((descuento) => descuento === arrayDescuento[0])) {
      this.factura.fiscal.descuento = arrayDescuento[0];
    }

    // Generar los datos con los que se van a trabajar en el formulario
    this.factura.cliente = dataAlbaran.cliente;
    this.factura.direccion = dataAlbaran.direccion;
    this.factura.usuario = dataAlbaran.usuario;
    this.factura.numero = dataAlbaran.numero;
    this.factura.detalle = dataAlbaran.detalle;
    this.factura.albaranId = dataAlbaran.id;
    this.factura.recogidaPropia = dataAlbaran.recogidaPropia;
    this.factura.nota = dataAlbaran.nota;

    this.factura.detalle = await Promise.all(
      this.factura.detalle.map(async (detalle) => {
        const detalleFetched = await ReferenciaService.detalle(
          detalle.referencia.codigo
        );
        if (
          this.dataAlbaran &&
          this.dataAlbaran.fabricaciones &&
          this.dataAlbaran.presupuestos.length > 0
        ) {
          const fabricacionId = detalle.fabricacionId;
          // Hay que buscar en los datos del albaran a travez de fabricacion->detallePressupuest->precio
          const fabricacionBuscada = this.dataAlbaran.fabricaciones.find(
            (fabricacion) => {
              if (!fabricacion) return false;
              return fabricacion.id === fabricacionId;
            }
          );
          if (fabricacionBuscada) {
            var precio = fabricacionBuscada.presupuestoDetalle.precio;
          } else {
            precio = null;
          }
        }

        return {
          ...detalle,
          precio: precio,
          referencia: {
            ...detalle.referencia,
            ...detalleFetched,
          },
        };
      })
    );

    this.loaders.card = false;
  },

  validations: {
    factura: {
      fiscal: {
        iva: {
          required,
          decimal,
        },
        recEq: {
          decimal,
        },
        descuento: {
          decimal,
        },
      },
      detalle: {
        $each: {
          cantidad: { required, decimal },
          referencia: { codigo: { required } },
          precio: { required, decimal },
        },
      },
    },
  },

  methods: {
    async crear() {
      this.loaders.btn = true;

      // Formatear el payload enviado por el request POST.
      let factura = this.factura;
      factura.detalle = this.detalleCosteCalculado.map((detalle, idx) => {
        if (this.detalleCosteCalculado) {
          var beneficio =
            this.detalleCosteCalculado[idx].precio -
            this.detalleCosteCalculado[idx].precioConPorte;
        } else {
          beneficio = null;
        }
        return {
          ...detalle,
          beneficio,
        };
      });

      await FacturaService.create({
        ...factura,
        kgs_impuesto_especial: Number(this.kgsTotal),
        importe_impuesto_especial: Number(this.importeImpuestoKgs)
      });
      await SnackbarService.set({ message: "Se ha creado la factura" });
      this.loaders.btn = false;
      this.$router.push({ name: "facturas.index" });
    },

    async actualizar() {
      this.loaders.btn = true;
      // Formatear el payload enviado por el request POST.
      let factura = this.factura;
      factura.detalle = this.detalleCosteCalculado.map((detalle, idx) => {
        if (this.detalleCosteCalculado) {
          var beneficio =
            this.detalleCosteCalculado[idx].precio -
            this.detalleCosteCalculado[idx].precioConPorte;
        } else {
          beneficio = null;
        }
        return {
          ...detalle,
          beneficio,
        };
      });
      await FacturaService.update(this.factura.id, {
        ...factura,
        kgs_impuesto_especial: Number(this.kgsTotal),
        importe_impuesto_especial: Number(this.importeImpuestoKgs)
      });
      await SnackbarService.set({ message: "Se ha editado la factura" });
      this.loaders.btn = false;
      this.$router.push({ name: "facturas.index" });
    },

    setDetalle(data) {
      const { idx } = data;
      this.factura.detalle[idx].referencia = data;
    },


		removeDetalle(idx) {
			this.factura.detalle.splice(idx, 1);
		},

		addDetalle() {
			this.factura.detalle.push({
				cantidad: 0,
				precio: 0,
				referencia: {},
			});
		},

  },
};
</script>