import axios from '@/axios.config'

class CompraService {

  static findAll(queryParams){
    return axios.get('/api/compras',{params:queryParams})
      .then(res=>res.data)
  }

  static findByPk(id){
    return axios.get(`/api/compras/${id}`)
      .then(res=>res.data)
  }

  static create(data){
    return axios.post('/api/compras',data)
      .then(res=>res.data)
  }

  static update(pedidoId,data){
    return axios.put(`/api/compras/${pedidoId}`,data)
    .then(res=>res.data)
  }

  static destroy(pedidoId){
    return axios.delete(`/api/compras/${pedidoId}`)
    .then(res=>res.data)
  }
  
  static findPagos(){
    return axios.get('/api/pagos')
      .then(res=>res.data)
  }

  static exportar(fechaLimite){
    return axios.get(`/api/compras/exportar`,{params:{fechaLimite},responseType:'blob'})
      .then(res=>{
        // create file link in browser's memory
        const href = URL.createObjectURL(res.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `factura_venta_${new Date().toLocaleDateString()}.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);

        return res.data
      })
      .catch(error=>{
        alert(error)
        return error
      })
  }


  static importarPresentadas(arrayNumerosFacturasPresentadas){
    return axios.post('/api/compras/importar-presentadas', arrayNumerosFacturasPresentadas)
      .then(res=>res.data)
  }

}

export default CompraService