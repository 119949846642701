<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Features flags</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols=3 v-for="feature in featuresFlags" :key="feature.id">
         <v-switch  :label="feature.feature" v-model="feature.state" @change="updateFeatureFlag(feature.id,feature.state)"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AdminFeaturesFlagsService from '@/services/admin/features.service'
import SnackbarService from '@/services/snackbar.service'

export default {
  name:'FeaturesIndex',

  data(){
    return{
      featuresFlags: []
    }
  },
  
  methods:{
    async fetchFeaturesFlags(){
      this.featuresFlags = await AdminFeaturesFlagsService.findAll()
    },

    async updateFeatureFlag(id,state){
      const res = await AdminFeaturesFlagsService.update(id, {state})
      console.log(res)
      await SnackbarService.set({
        message: res.msg,
      })
      await this.fetchFeaturesFlags()

    }
  },

  created(){
    this.fetchFeaturesFlags()
  }
}
</script>