<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card :loading="loaders.card">
          <v-container>
            <v-row>
              <v-col>
                <v-btn :loading="loaders.btn" outlined color="primary" @click="downloadPDF">PDF</v-btn>
                <v-btn outlined color="primary" v-if="abono.id||abono.factura.id" class="ml-3" :to="{name:'facturas.abono.editar',params:{id:abono.id,facturaId:abono.factura.id}}">Editar</v-btn>
                <v-btn outlined color="error" class="ml-3" @click="eliminar">Eliminar</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p>Abono: {{abono.numero}}</p>
                <p>Factura: <router-link v-if="abono.factura.id" :to="{name:'facturas.show',params:{id:abono.factura.id}}">{{abono.factura.numero}}</router-link></p>
                <p>Fecha: {{abono.createdAt | fecha}}</p>
                <p>Importe: {{abono.importe}}</p>
                <p>Descuento: {{abono.factura.descuento || 0}}</p>
                <p>Base Imponible: {{baseImponible}}</p>
                <p>IVA: {{abono.factura.iva || 0}}</p>
                <p>Rec.Eq.: {{abono.factura.recEq || 0}}</p>
                <p>Total: {{totalAbono}}</p>
                <p>Descripcion Abono: {{abono.descripcion}}</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AbonoService from '@/services/factura/abono.service'
import SnackbarService from "@/services/snackbar.service";

export default {
  name:'FacturasAbonoShow',

  data(){
    return{
      loaders:{
        card:true,
        btn:false,
      },
      abono:{factura:{}},
    }
  },

  computed:{
    baseDescuento(){
      const descuento = this.abono.factura.descuento
      const total = this.abono.importe
      const importeDescuento = total * descuento / 100
      return importeDescuento
    },

    baseIva(){
      const iva = this.abono.factura.iva
      const total = this.abono.importe
      return total * iva /100
    },

    baseRecEq(){
      const recEq = this.abono.factura.recEq
      const total = this.abono.importe
      return total * recEq /100
    },

    baseImponible(){
      const baseImponible = this.abono.importe - this.baseDescuento
      return baseImponible
    },

    totalAbono(){
      return this.baseImponible + this.baseIva + this.baseRecEq
    }
  },

  async created(){
    const {id,facturaId} = this.$route.params
    if(id && facturaId){
      this.abono = await AbonoService.findByPk(facturaId,id)
    }
    this.loaders.card = false
  },

  methods:{
    async eliminar(){
      if(!confirm('Seguro que quieres eliminar este abono?')) return false
      await AbonoService.delete(this.abono.factura.id,this.abono.id)
      console.log('Eliminado!')
      await SnackbarService.set({
        message:'Se ha eliminado el abono'
      })
      this.$router.push({name:'facturas.show',params:{id:this.abono.factura.id}})

    },

    async downloadPDF(){
      this.loaders.btn = true
      await AbonoService.downloadPDF(this.abono.factura.id,this.abono.id)
      this.loaders.btn = false
    }
  }
}
</script>