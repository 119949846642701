/*
	Archivo de configuracion para cada tipo de entorno (DEV, PROD, TEST, ETC.)
*/

const NODE_ENV = process.env.NODE_ENV

let VUE_APP_BASE_URL, VUE_APP_API_BASE_URL, VUE_APP_MS_AUTH_URL

if(NODE_ENV === 'production'){
	VUE_APP_BASE_URL="https://fabrica2.q-2.es"
	VUE_APP_API_BASE_URL="https://api.fabrica.q-2.es"
	VUE_APP_MS_AUTH_URL="https://auth.fabrica.q-2.es"
}

if(NODE_ENV === 'development'){
	VUE_APP_BASE_URL="http://localhost:8080"
	VUE_APP_API_BASE_URL="http://localhost:3001"
	VUE_APP_MS_AUTH_URL="http://localhost:3002"
}

export {
	VUE_APP_API_BASE_URL,
	VUE_APP_BASE_URL,
	VUE_APP_MS_AUTH_URL
}