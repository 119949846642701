<template>
  <v-container>
    <Modal ref="confirm" />
    <v-row>
      <v-select
        v-model="selected"
        class="text-uppercase"
        label="Tipo de registro"
        item-text="tipo"
        item-value="id"
        :items="types"
        :menu-props="{ dense: true, class: 'text-uppercase' }"
      ></v-select>
    </v-row>
    <v-row>
      <v-btn dark block color="primary" @click="empezar">EMPEZAR</v-btn>
    </v-row>
  </v-container>
</template>

<script>
import AuthService from "@/services/auth.service";
import SnackbarService from '@/services/snackbar.service';
import HorasService from '@/services/horas.service';
import Modal from "./HorasModal.vue";

export default {
  name: "HorasNew",
  props:['user'],
  components: {
    Modal,
  },
  data: () => {
    return {
      types: [{tipo:"Jornada", id:1}, {tipo:"Vacaciones",id:2}, {tipo:"Baja",id:3}],
      selected: {tipo:'Jornada',id:1},
    };
  },
  methods: {
    async empezar() {
      if (
        await this.$refs.confirm.open(
          "Confirm",
          "Are you sure you want to delete this record?"
        )
      ) {
        const user = await AuthService.user();
        await HorasService.createNew(user.id, this.selected)
        await SnackbarService.set({
          message:'Se ha creado un nuevo registro',
        })
        this.$emit('start')
      }
    },
  },
};
</script>