<template>
  <v-container>
    <v-alert v-if="porcentajeVolumen <= 90 && porcentajeVolumen" type="warning">
      El pedido esta incompleto, {{ SumaVolumenM2 }}m2. Hace un total del
      <strong>{{ porcentajeVolumen }}%</strong> del camion.
    </v-alert>
    <v-alert
      v-if="porcentajeVolumen > 90 && porcentajeVolumen < 110"
      type="success"
    >
      El pedido esta completo, {{ SumaVolumenM2 }}m2. Hace un total del
      <strong>{{ porcentajeVolumen }}%</strong> del camion.
    </v-alert>
    <v-alert v-if="porcentajeVolumen >= 110 && porcentajeVolumen" type="error">
      El pedido es superior a un trailer, {{ SumaVolumenM2 }}m2. Hace un total
      del <strong>{{ porcentajeVolumen }}%</strong> del camion.
    </v-alert>
    <v-card :loading="loaders.card">
      <v-container>
        <h2 class="mb-5 grey--text">Formulario Albaran</h2>
        <v-row>
          <v-col>
            <AutoCliente
              v-model="albaran.cliente"
              :cliente-id="initialData.clienteId"
            />
            <AutoDireccion
              v-model="albaran.direccion"
              :cliente-id="albaran.cliente.id"
              :direccion-id="initialData.direccionId"
            />
            <v-switch
              label="Recogida propia"
              v-model="albaran.recogidaPropia"
            ></v-switch>
          </v-col>
        </v-row>
        <v-row v-for="detalle in albaran.fixedDetalle" :key="detalle.id">
          <v-col cols="2">
            <v-text-field
              v-model.number="detalle.cantidad"
              label="Uds"
              :append-icon="
                detalle.completar
                  ? detalle.completar == 1
                    ? 'mdi-plus'
                    : 'mdi-minus'
                  : 'mdi-equal'
              "
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Referencia"
              v-model="detalle.referencia.codigo"
              readonly
              :messages="
                detalle.queried ? detalle.queried.detalle.nombre : null
              "
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-for="(d, idx) in albaran.detalle" :key="idx">
          <v-col cols="2">
            <v-text-field
              label="Uds"
              v-model.number="d.cantidad"
            ></v-text-field>
          </v-col>
          <v-col>
            <InputReferencia
              :idx="idx"
              :codigo="d.referencia.codigo"
              @fetch="setDetalle"
              @close="removeDetalle(idx)"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            <v-btn color="primary" fab small @click="addDetalle">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea label="Nota" v-model="albaran.nota"></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              v-if="!albaran.id"
              color="primary"
              :disabled="$v.$invalid"
              :loading="loaders.btn"
              block
              @click="crearAlbaran"
              >Guardar albaran</v-btn
            >
            <v-btn
              v-else
              color="primary"
              :disabled="$v.$invalid"
              :loading="loaders.btn"
              block
              @click="actualizarAlbaran"
              >Actualizar albaran</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import { required, numeric } from "vuelidate/lib/validators";

import DataService from "@/services/data.service";
import ReferenciaService from "@/services/referencia.service";
import AlbaranService from "@/services/albaran.service";
import SnackbarService from "@/services/snackbar.service";

import AutoCliente from "@/components/AutoCliente";
import AutoDireccion from "@/components/AutoDireccion";
import InputReferencia from "@/components/InputReferencia";

export default {
  name: "AlbaranesForm",

  components: {
    InputReferencia,
    AutoCliente,
    AutoDireccion,
  },

  data() {
    return {
      initialData: {
        clienteId: null,
        cliente: {},
        direccionId: null,
        direccion: {},
      },
      albaran: {
        id: null,
        cliente: {},
        direccion: {},
        detalle: [],
        fixedDetalle: [],
        recogidaPropia: false,
        nota: null,
      },
      loaders: {
        btn: false,
        card: true,
      },
    };
  },

  methods: {
    addDetalle() {
      this.albaran.detalle.push({
        cantidad: 0,
        referencia: {},
      });
    },

    removeDetalle(idx) {
      this.albaran.detalle.splice(idx, 1);
    },

    setDetalle(data) {
      const { idx } = data;
      this.albaran.detalle[idx].referencia = data;
    },

    async crearAlbaran() {
      this.loaders.btn = true;
      const createdAlbaran = await AlbaranService.create(this.albaran);
      await AlbaranService.downloadPDF(createdAlbaran.id);
      await SnackbarService.set({ message: "Se ha generado el albaran" });
      this.$router.push({ name: "albaranes.index" });
      this.loaders.btn = false;
    },

    async actualizarAlbaran() {
      this.loaders.btn = true;
      await AlbaranService.update(this.albaran.id, this.albaran);
      await AlbaranService.downloadPDF(this.albaran.id);
      await SnackbarService.set({ message: "Se ha actualizado el albaran" });
      this.$router.push({ name: "albaranes.index" });
      this.loaders.btn = false;
    },
  },

  validations: {
    albaran: {
      cliente: { required },
      direccion: { required },
      detalle: {
        $each: {
          cantidad: { required, numeric },
          referencia: { detalle: { codeNormalizado: { required } } },
        },
      },
      fixedDetalle: {
        $each: {
          cantidad: { required, numeric },
          queried: { detalle: { codeNormalizado: { required } } },
        },
      },
    },
  },

  async created() {
    const { id } = this.$route.params;
    if (id) {
      console.log(id);
      const albaran = await AlbaranService.findByPk(id);
      this.albaran = albaran;
      this.initialData.clienteId = albaran.cliente.id;
      this.initialData.direccionId = albaran.direccion.id;
      this.loaders.card = false;
      return false;
    }

    // Datos que vienen por VUEX desde pesta;a de fabricacion
    const data = await DataService.getData();
    console.log(data);
    DataService.unsetData();
    if (!data) return (this.loaders.card = false);
    this.albaran.cliente = data[0].cliente;

    // DATOS INICIALES
    // Cliente y direccion
    this.initialData.direccionId = data[0].presupuestoDetalle
      ? data[0].presupuestoDetalle.presupuesto.direccionId
      : null;
    this.initialData.clienteId = data[0].cliente.id;
    this.initialData.cliente = data[0].clienteId;
    // Detalles (fixedDetalle)
    let arrayTipoRecogidas = []; //Array para rellenar que tipos de entregas vienen de cada fabricacion
    for (let i = 0; i < data.length; i++) {
      const codigo = data[i].referencia.codigo;
      const detalle = await ReferenciaService.detalle(codigo);
      data[i].queried = detalle;
      data[i].cantidad = data[i].presupuestoDetalle
        ? data[i].cantidad
        : data[i].cantidad;
      data[i].completar = data[i].presupuestoDetalle
        ? data[i].presupuestoDetalle.completar
        : null;
      arrayTipoRecogidas.push(data[i].recogidaPropia);
    }
    // Comprobar si todas las fabricaciones/detalleFixed tienen recogida Propia. Si hay combinados entonces mandar un aviso!
    console.log(arrayTipoRecogidas);
    if (arrayTipoRecogidas.some((valor) => valor != arrayTipoRecogidas[0])) {
      alert(
        "CUIDADO! Se estan mezclando fabricaciones/presupuestos con y sin recogida propia!"
      );
    } else {
      this.albaran.recogidaPropia = arrayTipoRecogidas[0];
    }

    // Fabricaciones para crear albaran-factura
    this.albaran.fixedDetalle = data;

    this.loaders.card = false;
  },

  computed: {
    porcentajeVolumen() {
      const m2Trailer = 22_000;
      return parseInt((this.SumaVolumenM2 / m2Trailer) * 100);
    },

    SumaVolumenM2() {
      let sumaVolumenesM2 = 0;
      if (this.albaran.detalle.length > 0) {
        sumaVolumenesM2 += this.albaran.detalle.reduce((total, detalle) => {
          if (!detalle || !detalle.referencia || !detalle.referencia.volumen)
            return total;
          return total + detalle.cantidad * detalle.referencia.volumen.volumen;
        }, 0);
      }
      if (this.albaran.fixedDetalle && this.albaran.fixedDetalle.length > 0) {
        sumaVolumenesM2 += this.albaran.fixedDetalle.reduce(
          (total, detalle) => {
            return total + detalle.cantidad * detalle.queried.volumen.volumen;
          },
          0
        );
      }
      return sumaVolumenesM2;
    },
  },
};
</script>