<template>
  <v-autocomplete v-model="selectedDireccion" :items="direcciones" item-text="nombre" return-object label="Direccion" append-icon="mdi-close" @click:append="selectedDireccion={}" :suffix="selectedDireccion.transporte?`${selectedDireccion.transporte.localidad}-${selectedDireccion.transporte.proveedor}`:''"/>
</template>

<script>
import ClienteService from '@/services/cliente.service'

export default {
  name:'AutoDireccion',

  props:['clienteId','direccionId'],

  data(){
    return{
      direcciones:[],
      selectedDireccion:{},
    }
  },

  watch:{
    selectedDireccion(){
      this.$emit('input',this.selectedDireccion)
    },

    async direccionId(){
      console.log(this.direccionId)
      if(this.clienteId){
        this.direcciones = await ClienteService.findDireccionByClienteId(this.clienteId)
        if(this.direccionId){
          this.selectedDireccion = await ClienteService.findDireccionByPk(this.direccionId)
        }
        this.$emit('input',this.selectedDireccion)
      }
    },

    async clienteId(){
      this.selectedDireccion = {}
      this.direcciones = []
      if(this.clienteId){
        this.direcciones = await ClienteService.findDireccionByClienteId(this.clienteId)
        this.$emit('input',this.selectedDireccion)
      }
    }
  },

  async created(){
    if(this.direccionId){
      this.selectedDireccion = await ClienteService.findDireccionByPk(this.direccionId)
    }
  }
}
</script>