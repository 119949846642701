import AuthService from '@/services/auth.service'

import PedidosIndex from '@/views/pedidos/PedidosIndex'
import PedidosForm from '@/views/pedidos/PedidosForm'
import PedidosShow from '@/views/pedidos/PedidosShow'

const rutasPedidos = [
  {
    path:'/pedidos',
    component:PedidosIndex,
    name:'pedidos.index',
  },
  {
    path:'/pedidos/crear',
    component:PedidosForm,
    name:'pedidos.crear'
  },
  {
    path:'/pedidos/editar/:id',
    component:PedidosForm,
    name:'pedidos.editar'
  },
  {
    path:'/pedidos/:id',
    component:PedidosShow,
    name:'pedidos.show',
  }
]

export default rutasPedidos.map(route=>{
  // Agregar a cada ruta un Guard Before Enter para comprobar si el usuario autenticado tiene el rol
  return{
    ...route,
    beforeEnter:(to,from,next)=>{
      if(AuthService.user().roles.includes('pedidos')) next()
      else next({name:'Error404View'})
    }
  } 
})