import axios from '@/axios.config'

class PresupuestoService {
  static async create(data){
    return axios.post('/api/presupuestos',data)
      .then(res=>res.data)
  }
  
  static async findAll(queryParams){
    return axios.get('/api/presupuestos',{params:queryParams})
      .then(res=>res.data)
  }

  static async findByPk(presupuestoId){
    return axios.get(`/api/presupuestos/${presupuestoId}`)
      .then(res=>res.data)
  }

  static async aceptar(presupuestoId){
    return axios.post(`/api/presupuestos/${presupuestoId}/aceptar`)
      .then(res=>res.data)
  }

  static async desaceptar(presupuestoId){
    return axios.post(`/api/presupuestos/${presupuestoId}/desaceptar`)
      .then(res=>res.data)
  }

  static async downloadPDF(presupuestoId){
    return axios.get(`/api/presupuestos/${presupuestoId}/pdf`,{responseType:'blob'})
    .then(response=>{
      window.open(URL.createObjectURL(response.data));
    })
    .catch(err=>{
      alert(err)
    })
  }

}

export default PresupuestoService