<template>
  <v-container>
    <v-form>
      <v-card>
        <v-container>
          <v-row>
            <v-col>
              <h2>Costes para calculos del algoritmo</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="6"
              v-for="variable in variablesProduccion"
              :key="variable.id"
            >
              <v-text-field
                :label="variable.nombre"
                v-model.number="variable.valor"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn
                block
                color="primary"
                @click.prevent="updateVariablesProduccion"
                >Actualizar variables</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import VariableService from "@/services/variable.service.js";
import SnackbarService from "@/services/snackbar.service.js";

export default {
  name: "CostesProduccion",
  data() {
    return {
      variablesProduccion: [],
    };
  },
  methods: {
    getVariablesProduccion() {
      VariableService.findAllProduccion().then((response) => {
        this.variablesProduccion = response;
      });
    },
    async updateVariablesProduccion() {
      await VariableService.updateAllProdccion(this.variablesProduccion);
      await SnackbarService.set({
        message: "Se han actualizado las variables de produccion",
      });
      await this.$router.push({ name: "costes.index" });
    },
  },
  created() {
    this.getVariablesProduccion();
  },
};
</script>