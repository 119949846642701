import dayjs from "dayjs"
import Vue from "vue"

Vue.filter('fecha', fecha=>{
  if(!fecha)return 'Sin fecha'
  if(!dayjs(fecha).isValid()) return 'Fecha invalida'
  return dayjs(fecha).format('DD/MM/YY')
})

Vue.filter('datetime', datetime => {
	if(!datetime) return 'No hay fecha'
	if (!dayjs(datetime).isValid()) return "Fecha invalida";
	return dayjs(datetime).format('DD/MM/YY  HH:mm:ss')
})
