<template>
  <v-container>
    <h1 class="subtitle-1 grey--text">
      <router-link :to="{ name: 'pedidos.index' }">Pedidos</router-link>
    </h1>
    <v-card :loading="loaders.card">
      <v-card-title>Pedido</v-card-title>
      <v-card-actions v-if="pedido">
        <v-btn
          outlined
          :to="{ name: 'pedidos.editar', params: { id: pedido.id } }"
          color="primary"
          small
          >Editar</v-btn
        >
      </v-card-actions>
      <v-card-text v-if="pedido">
        <p>Numero: {{ pedido.numero }}</p>
        <p>Fecha: {{ pedido.fecha | fecha }}</p>
        <p>Fecha Solicitada: {{ pedido.fechaExigida | fecha }}</p>
        <p>Cliente: {{ pedido.cliente.nombre }}</p>
        <p>
          Lugar de entrega: {{ pedido.direccion.nombre }}
          <v-chip small v-if="pedido.recogidaPropia" color="primary"
            >Recogida propia</v-chip
          >
        </p>
        <p>Creado por: {{ pedido.usuario.mail }}</p>
        <p>
          Presupuestos:
          <span v-if="pedido.presupuestos.length < 1">Sin presupuestar</span>
          <span
            v-for="presupuesto in pedido.presupuestos"
            :key="presupuesto.id"
          >
            <router-link
              :to="{
                name: 'presupuestos.show',
                params: { id: presupuesto.id },
              }"
              >{{ presupuesto.numero }}</router-link
            >
          </span>
        </p>

        <v-divider class="my-5"></v-divider>

        <p>Detalles:</p>
        <ul>
          <li v-for="detalle in pedido.detalle" :key="detalle.id">
            <p>
              {{ detalle.cantidad }} - {{ detalle.referencia.nombre }} -
              {{ detalle.precio }}€
							<span v-if="detalle.referencia.codigo.includes('X')"> - Impuesto excluido</span>
              <v-chip small color="success" v-if="detalle.completar == 1"
                >Rellenar</v-chip
              ><v-chip small color="error" v-if="detalle.completar == -1"
                >Quitar</v-chip
              >
            </p>
          </li>
        </ul>
        <p class="mt-10">Notas:</p>
        <p>{{ pedido.nota }}</p>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import PedidoService from "@/services/pedido.service";

export default {
  name: "PedidosShow",

  data() {
    return {
      pedido: null,
      loaders: {
        card: true,
        btn: false,
      },
    };
  },

  async created() {
    const pedidoId = this.$route.params.id;
    this.pedido = await PedidoService.findByPk(pedidoId);
    this.loaders.card = false;
  },
};
</script>