import AuthService from '@/services/auth.service'

import FacturasIndex from '@/views/facturas/FacturasIndex'
import FacturasForm from '@/views/facturas/FacturasForm'
import FacturasShow from '@/views/facturas/FacturasShow'
import FacturasAbonoForm from '@/views/facturas/FacturasAbonoForm'
import FacturasAbonoShow from '@/views/facturas/FacturasAbonoShow'

const rutasFacturas = [
  {
    path:'/facturas',
    component:FacturasIndex,
    name:'facturas.index',
  },
  {
    path:'/facturas/crear/:albaranId',
    component: FacturasForm,
    name: 'facturas.crear'
  },
  {
    path:'/facturas/editar/:id',
    component: FacturasForm,
    name: 'facturas.editar'
  },
  {
    path:'/facturas/:id',
    component: FacturasShow,
    name: 'facturas.show'
  },
  {
    path:'/facturas/:facturaId/abono/crear',
    component: FacturasAbonoForm,
    name: 'facturas.abono.crear'
  },
  {
    path:'/facturas/:facturaId/abono/:id',
    component: FacturasAbonoShow,
    name: 'facturas.abono.show'
  },
  {
    path:'/facturas/:facturaId/abono/:id/editar',
    component: FacturasAbonoForm,
    name: 'facturas.abono.editar',
		beforeEnter:(to, from, next) => {
			if (AuthService.hasRoles(["facturas.editar"])) next();
      else next({ name: "Error404View" });
		}
  },
]

export default rutasFacturas.map(route=>{
  // Agregar a cada ruta un Guard Before Enter para comprobar si el usuario autenticado tiene el rol
  return{
    ...route,
    beforeEnter:(to,from,next)=>{
      if(AuthService.user().roles.includes('facturas')) next()
      else next({name:'Error404View'})
    }
  } 
})