import axios from '@/axios.config'

class LaminableService {
  static findAll(){
    return axios.get('/api/laminables')
      .then(res=>res.data)
  }

  static updateAll(data){
    return axios.put('/api/laminables',data)
      .then(res=>res.data)
  }
}

export default LaminableService