var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('h1',{staticClass:"subtitle-1 grey--text"},[_vm._v("Compras")])])],1),_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"to":{name:'compras.formulario'},"color":"primary"}},[_vm._v("Crear compra")])],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"solo":"","label":"Filtro","placeholder":"Insertar nombre del proveedor a buscar"},model:{value:(_vm.filtroProveedor),callback:function ($$v) {_vm.filtroProveedor=$$v},expression:"filtroProveedor"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-switch',{attrs:{"label":"Solo vencidas"},model:{value:(_vm.soloVencidas),callback:function ($$v) {_vm.soloVencidas=$$v},expression:"soloVencidas"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"loading":_vm.loading,"items":_vm.compras,"headers":_vm.headers,"server-items-length":_vm.totalCompras,"options":_vm.options,"dense":"","footer-props":_vm.footerProps,"disable-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.numero",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{name:'compras.formulario',params:{id:item.id}}}},[_vm._v(_vm._s(item.numero))])]}},{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("fecha")(item.fecha)))]}},{key:"item.fechaVencimiento",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("fecha")(item.fechaVencimiento)))]}},{key:"item.importePagado",fn:function(ref){
var item = ref.item;
return [(item.importePagado >= item.total)?_c('v-chip',{attrs:{"label":"","small":"","color":"success"}},[_vm._v("Pagado")]):(item.importePagado < item.total && item.importePagado && new Date(item.fechaVencimiento) < new Date())?_c('v-chip',{attrs:{"label":"","small":"","color":"warning"}},[_vm._v("Parcial")]):(!item.importePagado && new Date(item.fechaVencimiento) < new Date())?_c('v-chip',{attrs:{"label":"","small":"","color":"error"}},[_vm._v("Vencida")]):_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v("Pendiente")])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }