<template>
  <v-container>
    <h1 class="subtitle-1 grey--text">Costes</h1>
    <ul>
      <li>
        <router-link :to="{ name: 'costes.variables' }"
          >Principales</router-link
        >
      </li>
      <li>
        <router-link :to="{ name: 'costes.laminables' }"
          >Laminables</router-link
        >
      </li>
      <li>
        <router-link :to="{ name: 'costes.transportes' }"
          >Transportes</router-link
        >
      </li>
      <li>
        <router-link :to="{ name: 'costes.produccion' }"
          >Produccion</router-link
        >
      </li>
    </ul>
  </v-container>
</template>

<script>
export default {};
</script>