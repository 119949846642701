<template>
  <v-container>
    <v-row>
      <v-col>
        <h1 class="subtitle-1 grey--text">
          <router-link :to="{ name: 'proveedores.index' }"
            >Proveedores</router-link
          >
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="proveedor.nombre"
                  label="Nombre completo"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="proveedor.cif"
                  label="CIF"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  v-model="proveedor.grupoId"
                  item-text="nombre"
                  item-value="id"
                  :items="grupos"
                  label="Grupo"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  v-model="proveedor.pagoId"
                  item-text="nombre"
                  item-value="id"
                  :items="pagos"
                  label="Forma de pago"
                ></v-select>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="proveedor.vencimiento"
                  label="Vencimiento"
                  placeholder="*Opcional"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="proveedor.banco"
                  label="Cuenta Corriente"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn v-if="!proveedor.id" block color="primary" @click="crear">Crear proveedor</v-btn>
                <v-btn v-else block color="primary" @click="actualizar">Actualizar proveedor</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProveedorService from "@/services/proveedor.service";
import SnackbarService from "@/services/snackbar.service";

export default {
  name: "ProveedoresForm",

  data() {
    return {
      proveedor: {
        id: null,
        nombre: null,
        cif: null,
        vencimiento: null,
        banco: null,
        grupoId: null,
        pagoId: null,
      },

      grupos: [],
      pagos: [],
    };
  },

  async created() {
    this.pagos = await ProveedorService.findPagos();
    this.grupos = await ProveedorService.findGrupos();

    const {id} = this.$route.params
    if(!id) return false
    this.proveedor = await ProveedorService.findByPk(id)
  },

  methods: {
    async crear() {
      await ProveedorService.create(this.proveedor);
      await SnackbarService.set({
        message: "Se ha creado el proveedor",
      });
      this.$router.push({ name: "proveedores.index" });
    },

    async actualizar() {
      await ProveedorService.update(this.proveedor.id,this.proveedor);
      await SnackbarService.set({
        message: "Se ha actualizado el proveedor",
      });
      this.$router.push({ name: "proveedores.index" });
    },
  },
};
</script>