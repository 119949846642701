<template>
  <v-container>
    <v-form>
      <v-card>
        <v-container>
          <v-row>
            <v-col>
              <h2>Laminables</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="4"
              v-for="laminable in laminables"
              :key="laminable.nombre"
            >
              <v-text-field
                :label="laminable.nombre"
                type="number"
                v-model.number="laminable.precio"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn color="primary" block @click="updateAll">Guardar</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import LaminableService from "@/services/laminable.service";
import Snackbar from '@/services/snackbar.service'

export default {
  name: "CostesLaminables",

  data() {
    return {
      laminables: [
      ],
    };
  },

  async created() {
    this.laminables = await LaminableService.findAll();
  },

  methods:{
    async updateAll(){
      await LaminableService.updateAll(this.laminables)
      await Snackbar.set({message:'Se han actualizado los costes laminados'})
      await this.$router.push({name:'costes.index'})
    }
  }
};
</script>