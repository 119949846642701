import axios from '@/axios.config'

class ConsultaFacturacionService {
  static facturasVencidas(){
    return axios.get('/api/consultas/clientes-facturas-vencidas')
      .then(res=>res.data)
  }

  static enviarMails(arrayNombresClientes){
    return axios.post('/api/consultas/enviar-mails-reclamaciones',arrayNombresClientes)
      .then(res=>res.data)
  }
}

export default ConsultaFacturacionService