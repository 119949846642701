<template>
  <v-container>
    <v-row>
      <v-col>
        <v-alert
          v-if="user.roles.includes('administrador')"
          type="info"
          text
          dismissible
        >
          La base de URL usada actualmente es: <strong>{{ apiBaseUrl }}</strong>
          <pre>{{ datosEnv }}</pre>
        </v-alert>
      </v-col>
    </v-row>
    <!-- Facturas Vencidas -->
    <v-row>
      <v-col>
        <BarChart :chart-data="chartData" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h1 class="subtitle-1 grey--text">Facturas Impagadas</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          small
          :disabled="clientesSeleccionados.length < 1"
          color="primary"
          @click="enviarMailsReclamaciones"
          >Enviar mail reclamacion</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-container>
            <v-row>
              <v-col>
                <v-data-table
                  :headers="headers"
                  :items="facturasVencidasFormateado"
                  dense
                  item-key="cliente"
                  :items-per-page="-1"
                  hide-default-footer
                  sort-by="importeTotal"
                  sort-desc
                  show-select
                  v-model="clientesSeleccionados"
                >
                  <template v-slot:[`item.alerta`]="{ item }">
                    <v-icon v-if="item.alerta" color="red"
                      >mdi-alert-outline</v-icon
                    >
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <!-- Codigos -->
    <v-row>
      <v-col>
        <h1 class="subtitle-1 grey--text">Codigos</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-simple-table dense>
          <tbody>
            <tr>
              <td>Rollo burbuja</td>
              <td>RB</td>
            </tr>
            <tr>
              <td>Rollo foam</td>
              <td>RF</td>
            </tr>
            <tr>
              <td>Minirollo Burbuja</td>
              <td>MB</td>
            </tr>
            <tr>
              <td>Minirollo Foam</td>
              <td>MF</td>
            </tr>
            <tr>
              <td>Formato Burbuja</td>
              <td>FB</td>
            </tr>
            <tr>
              <td>Formato Foam</td>
              <td>FF</td>
            </tr>
            <tr>
              <td>Sobre Burbuja</td>
              <td>SB</td>
            </tr>
            <tr>
              <td>Sobre Foam</td>
              <td>SF</td>
            </tr>
            <tr>
              <td>Compra Venta</td>
              <td>CV</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-simple-table dense>
          <tbody>
            <tr>
              <td>Adhesivo</td>
              <td>Codigo 'A'. Solo en sobres</td>
            </tr>
            <tr>
              <td>Ancho y Largo</td>
              <td>
                3digitos + digitos(con decimales opcionales usando un punto)
              </td>
            </tr>
            <tr>
              <td>Mandril</td>
              <td>
                Codigo con mandril en minirollos 'wm'. Sin mandril para rollos
                grandes 'sm'
              </td>
            </tr>
            <tr>
              <td>Cortes</td>
              <td>
                Codigo 'C'. digitos + adigitos o entero x digito .Se calcula en
                dm (10+5 es 100+50cm).
              </td>
            </tr>
            <tr>
              <td>Diametro</td>
              <td>Codigo 'D' mas digito</td>
            </tr>
            <tr>
              <td>Espesor</td>
              <td>Solo en foam. Codigo 'E'</td>
            </tr>
            <tr>
              <td>Gramaje</td>
              <td>Solo en burbuja. Codigo 'G'</td>
            </tr>
            <tr>
              <td>Laminados</td>
              <td>Codigo 'L' + el codigo del laminado</td>
            </tr>
            <tr>
              <td>Precorte</td>
              <td>Codigo 'PR'. Se calcula en cm</td>
            </tr>
            <tr>
              <td>Solapa</td>
              <td>Codigo 'Z'. Solo en sobres. Se calcula en cm</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-simple-table dense>
          <tbody>
            <tr>
              <td>Foam 1mm</td>
              <td>LF1</td>
            </tr>
            <tr>
              <td>Foam 1.5mm</td>
              <td>LF15</td>
            </tr>
            <tr>
              <td>Foam 2mm</td>
              <td>LF2</td>
            </tr>
            <tr>
              <td>Kraft</td>
              <td>LK</td>
            </tr>
            <tr>
              <td>Lamina</td>
              <td>LL</td>
            </tr>
            <tr>
              <td>Lamina blanca</td>
              <td>LB</td>
            </tr>
            <tr>
              <td>Pet</td>
              <td>LP</td>
            </tr>
            <tr>
              <td>Pet blanco</td>
              <td>LP0</td>
            </tr>
            <tr>
              <td>Alta densidad</td>
              <td>LH</td>
            </tr>
            <tr>
              <td>Aluminio</td>
              <td>LA</td>
            </tr>
            <tr>
              <td>Rafia</td>
              <td>LR</td>
            </tr>
            <tr>
              <td>Foam 3mm</td>
              <td>LF3</td>
            </tr>
            <tr>
              <td>Foam 5mm</td>
              <td>LF5</td>
            </tr>
            <tr>
              <td>Foam 5mm azul</td>
              <td>LF5!</td>
            </tr>
            <tr>
              <td>aluref</td>
              <td>LA!</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BarChart from "@/components/BarChart.vue";
import SnackbarService from "@/services/snackbar.service"

import AuthService from "@/services/auth.service";
import AdminUtilesService from "@/services/admin/utiles.service";
import ConsultaFacturacionService from "@/services/consultas/consulta-facturacion.service.js";

export default {
  name: "HomeView",

  components: {
    BarChart,
  },

  data() {
    return {
      clientesSeleccionados: [],
      datosEnv: null,
      facturasVencidas: [],
      headers: [
        { text: ">60 dias", value: "alerta" },
        { text: "Cliente", value: "cliente" },
        { text: "Importe", value: "importeTotal", align: "right" },
        { text: "Facturas", value: "numerosFacturas" },
      ],
    };
  },

  computed: {
    user() {
      return AuthService.user();
    },

    apiBaseUrl() {
      return process.env.VUE_APP_API_BASE_URL;
    },

    facturasVencidasFormateado() {
      return this.facturasVencidas.map((factura) => {
        const importeTotal = factura.facturas.reduce((total, factura) => {
          return total + Number(factura.total);
        }, 0);

        const arrayNumerosFacturas = factura.facturas.reduce(
          (array, factura) => {
            array.push(factura.numero);
            return array;
          },
          []
        );

        const numerosFacturas = arrayNumerosFacturas.join(" / ");

        return {
          cliente: factura.cliente,
          importeTotal: importeTotal.toFixed(2),
          numerosFacturas,
          alerta: factura.alerta,
        };
      });
    },

    chartData() {
      const data = this.facturasVencidasFormateado.map((factura) => {
        return {
          x: factura.cliente,
          y: parseFloat(factura.importeTotal),
          backgroundColor: "red",
        };
      });

      const sortedData = data.sort((a, b) => {
        if (a.y > b.y) return -1;
        else if (a.y < b.y) return 1;
        else return 0;
      });

      return {
        datasets: [
          {
            label: "Importe debido",
            data: sortedData,
            backgroundColor: ["#1976d2"],
          },
        ],
      };
    },
  },

  methods: {
    async enviarMailsReclamaciones() {
      let msg = `Se van a enviar correos de reclamación a los siguientes clientes: \n`
      for(let cliente of this.clientesSeleccionados){
        msg+= `\n ${cliente.cliente}`
      }
      if (!confirm(msg)) return false
      const arrayNombreClientes = this.clientesSeleccionados.map(cliente=>cliente.cliente)
      const res = await ConsultaFacturacionService.enviarMails(arrayNombreClientes)
      await SnackbarService.set({
        message:res,
        color:'green',
      })
    },
  },

  async created() {
    // Datos de entorno de trabajo
    try {
      const res = await AdminUtilesService.datosEntorno();
      this.datosEnv = res;
    } catch (error) {
      console.error(error, 'Error al obtener los datos de entorno');
    }
    // Facturas Vencidas
    const facturasVencidas =
    await ConsultaFacturacionService.facturasVencidas();
    this.facturasVencidas = facturasVencidas;
  },
};
</script>
