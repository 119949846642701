<template>
  <v-container>
    <v-row>
      <v-col>
        <h1 class="subtitle-1 grey--text">Albaranes</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn color="success" :to="{ name: 'albaranes.form' }"
          >Crear albaran</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          solo
          label="Filtro"
          placeholder="Insertar nombre de cliente a buscar"
          v-model="filtroCliente"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-switch
          v-model="soloSinFacturar"
          label="Solo sin facturar"
        ></v-switch>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :loading="loading"
          :items="albaranes"
          :headers="headers"
          :server-items-length="totalAlbaranes"
          :options.sync="options"
          disable-sort
          dense
          :footer-props="footerProps"
        >
          <template v-slot:[`item.numero`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span
                  v-on="on"
                  @mouseover="fetchAlbaran(item.id)"
                  @mouseleave="fetchAlbaran(null)"
                >
                  <router-link
                    :to="{ name: 'albaranes.show', params: { id: item.id } }"
                    >{{ item.numero }}</router-link
                  >
                </span>
              </template>
              <table>
                <thead>
                  <th class="text-left">Cantidad</th>
                  <th class="text-left pl-5">Referencia</th>
                </thead>
                <tbody v-if="albaranFetched">
                  <tr
                    v-for="detalle in albaranFetched.detalle"
                    :key="detalle.id"
                  >
                    <td class="text-center">{{ detalle.cantidad }}</td>
                    <td class="text-left pl-5">
                      {{ detalle.referencia.nombre }}
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td class="text-center" colspan="4">Cargando...</td>
                  </tr>
                </tbody>
              </table>
            </v-tooltip>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ item.createdAt | fecha }}
          </template>
          <template v-slot:[`item.factura`]="{ item }">
            <v-chip v-if="item.anulado" small label> Anulado </v-chip>
            <v-chip
              v-else-if="item.factura"
              small
              label
              :to="{ name: 'facturas.show', params: { id: item.factura.id } }"
              >{{ item.factura.numero }}</v-chip
            >
            <v-chip
              v-else
              color="success"
              small
              label
              :to="{ name: 'facturas.crear', params: { albaranId: item.id } }"
              >Facturar</v-chip
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AlbaranService from "@/services/albaran.service";

export default {
  name: "AlbaranesIndex",

  data() {
    return {
      loading: true,
      headers: [
        { text: "ID", value: "id" },
        { text: "Cliente", value: "cliente.nombre" },
        { text: "Numero", value: "numero" },
        { text: "Fecha", value: "createdAt" },
        { text: "Factura", value: "factura" },
      ],
      albaranes: [],
      totalAlbaranes: -1,
      options: {},
      filtroCliente: "",
      footerProps: {
        "items-per-page-options": [25, 50, 100],
      },
      soloSinFacturar: false,
      albaranFetched: null,
    };
  },

  methods: {
    async fetchAlbaranes() {
      this.loading = true;
      const albaranesRespuesta = await AlbaranService.findAll({
        cliente: this.filtroCliente,
        ...this.options,
        soloSinFacturar: this.soloSinFacturar,
      });
      this.albaranes = albaranesRespuesta.rows;
      this.totalAlbaranes = albaranesRespuesta.count;
      this.loading = false;
    },

    async fetchAlbaran(albaranId) {
      if (!albaranId) this.albaranFetched = null;
      else {
        this.albaranFetched = await AlbaranService.findByPk(albaranId);
      }
    },
  },

  watch: {
    options: {
      handler() {
        this.fetchAlbaranes();
      },
      deep: true,
    },

    filtroCliente() {
      this.options.page = 1;
      this.fetchAlbaranes();
    },

    soloSinFacturar() {
      this.options.page = 1;
      this.fetchAlbaranes();
    },
  },

  created() {
    this.fetchAlbaranes();
  },
};
</script>