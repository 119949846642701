<template>
  <v-container>
    <v-card>
      <v-container>
        <v-text-field label="Mail" v-model="mail" @input="$v.mail.$touch()" :error-messages="errors.mail"></v-text-field>
        <v-btn v-if="!id" @click="registrarUsuario" :disabled="$v.$invalid" block color="primary" @input="$v.passwordRepeat.$touch()">Guardar</v-btn>
        <v-btn v-else @click="update" :disabled="$v.$invalid" block color="primary" @input="$v.passwordRepeat.$touch()">Editar</v-btn>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import UsuarioServicio from '@/services/usuario.service'
import SnackbarService from '@/services/snackbar.service'

import {required,email} from 'vuelidate/lib/validators'

export default {
  name:'UsuariosForm',

  data(){
    return{
      id:null,
      mail:null,
    }
  },

  validations:{
    mail:{required,email},
  },

  computed:{
    errors(){
      let errors = {
        mail:[],
      }
      // email
      if(this.$v.mail.$dirty && !this.$v.mail.required) errors.mail.push('El mail es requerido')
      if(this.$v.mail.$dirty && !this.$v.mail.email) errors.mail.push('El mail debe tener un formato valido')
      return errors
    }
  },


  methods:{
    registrarUsuario(){
      UsuarioServicio.registrarUsuario({
        email:this.mail,
      })
        .then(()=>{
          this.$router.push({name:"usuarios.index"})
          SnackbarService.set({message:"Se ha editado el usuario"}) 
        })
        .catch(error=>console.error(error))
    },

    async update(){
      await UsuarioServicio.update(this.id,{_id:this.id,email:this.mail})
      this.$router.push({name:"usuarios.index"})
      SnackbarService.set({message:"Se ha editado el usuario"}) 
    }
  },

  async beforeMount(){
    const {id} = this.$route.params
    if(!id) return false
    const usuario = await UsuarioServicio.getUsuario(id)
    this.id = usuario._id
    this.mail = usuario.email
  }
}
</script>