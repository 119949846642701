import LoginView from "@/views/auth/LoginView";
// import ResetPassword from '@/views/auth/ResetPassword'
import AuthService from "@/services/auth.service";

const rutasAlbaranes = [
  {
    path: "/auth/login",
    component: LoginView,
    name: "auth.login",
  },
  {
    path: "/auth/jwt/:token",
    redirect: (data) => {
      const { token } = data.params;
      if (!token) return { name: "auth.login" };
      AuthService.loginWithJWT(data.params.token);
      return { name: "home" };
    },
    name: "auth.jwt",
  },
  // {
  //   path:'/auth/reset-password',
  //   component:ResetPassword,
  //   name:'auth.reset'
  // }
];

export default rutasAlbaranes;
