<template>
  <v-container>
    <v-row>
      <v-col>
        <h1 class="subtitle-1 grey--text">Referencias</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn color="primary" :to="{ name: 'referencias.crear' }"
          >crear referencia</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="filtroReferencia"
          solo
          dense
          placeholder="Filtro"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :loading="loading"
          :server-items-length="totalReferencias"
          dense
          :headers="headers"
          :items="referencias"
          sort-by="nombre"
          :options.sync="options"
        >
          <template v-slot:[`item.codigo`]="{ item }">
            <router-link
              :to="{ name: 'referencias.editar', params: { id: item.id } }"
            >
              {{ item.codigo }}
            </router-link>
          </template>
          <template v-slot:[`item.precio.precio`]="{ value }">
            <span v-if="value"> {{ value.toFixed(3) }}€ </span>
          </template>
          <template v-slot:[`item.precio.precioM2`]="{ value }">
            <span v-if="value"> {{ value.toFixed(3) }}€</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn color="primary" :to="{ name: 'referencias.especiales.form' }"
          >crear referencia especial</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-simple-table dense>
          <thead>
            <th>Codigo</th>
            <th>Nombre referencia</th>
          </thead>
          <tbody>
            <tr
              v-for="referencia in referenciasEspeciales"
              :key="referencia.id"
            >
              <td>
                <router-link
                  :to="{
                    name: 'referencias.especiales.form',
                    params: { id: referencia.id },
                  }"
                  >{{ referencia.codigo }}</router-link
                >
              </td>
              <td>{{ referencia.nombre }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ReferenciaService from "@/services/referencia.service";
import ReferenciasEspecialesService from "@/services/referencia-especial.service";

export default {
  name: "ReferenciasIndex",

  data() {
    return {
      headers: [
        { text: "id", value: "id", sortable: false},
        { text: "Codigo", value: "codigo" },
        { text: "Nombre", value: "nombre" },
        { text: "Coste", value: "precio.precio", align: "right", sortable: false},
        { text: "C.M2", value: "precio.precioM2", align: "right", sortable: false},
        { text: "Stock", value: "stock", align: "right", sortable: false},
      ],
      totalReferencias: -1,
      referencias: [],
      referenciasEspeciales: [],
      options: {},
      loading: true,
      filtroReferencia: null,
    };
  },

  async created() {
    this.fetchReferencias();
    this.referenciasEspeciales = await ReferenciasEspecialesService.findAll();
  },

  methods: {
    async fetchReferencias() {
      this.loading = true;
      const referenciasRespuesta = await ReferenciaService.findAll({
        nombre: this.filtroReferencia,
        ...this.options,
      });
      this.referencias = referenciasRespuesta.rows;
      this.totalReferencias = referenciasRespuesta.count;
      this.loading = false;
    },
  },

  watch: {
    options: {
      handler() {
        this.fetchReferencias();
      },
      deep: true,
    },
    filtroReferencia() {
      this.fetchReferencias();
    },
  },
};
</script>