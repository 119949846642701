import AuthService from '@/services/auth.service'

import HerramientasIndex from '@/views/herramientas/HerramientasIndex'
import HerramientasReferencia from '@/views/herramientas/HerramientasReferencia'
import HerramientasPrecio from '@/views/herramientas/HerramientasPrecio'

import HerramientasExportarVentas from '@/views/herramientas/exportar/HerramientasExportVentas.vue'
import HerramientasImportVentas from '@/views/herramientas/importar/HerramientasImportVentas.vue'

import HerramientasExportarVentasAbonos from '@/views/herramientas/exportar/HerramientasExportVentasAbonos.vue'
import HerramientasImportarVentasAbonos from '@/views/herramientas/importar/HerramientasImportVentasAbonos.vue'

import HerramientasExportarCompras from '@/views/herramientas/exportar/HerramientasExportCompras.vue'
import HerramientasImportarCompras from '@/views/herramientas/importar/HerramientasImportCompras.vue'

const rutasFacturas = [
  {
    path:'/herramientas',
    component:HerramientasIndex,
    name:'herramientas.index',
  },
  {
    path:'/herramientas/referencia',
    component:HerramientasReferencia,
    name:'herramientas.referencia'
  },
  {
    path:'/herramientas/precio',
    component:HerramientasPrecio,
    name:'herramientas.precio'
  },
  {
    path:'/herramientas/exportar-ventas',
    component: HerramientasExportarVentas
  },
  {
    path:'/herramientas/exportar-compras',
    component: HerramientasExportarCompras
  },
  {
    path:'/herramientas/exportar-ventas-abonos',
    component: HerramientasExportarVentasAbonos
  },
  {
    path:'/herramientas/importar-ventas',
    component: HerramientasImportVentas
  },
  {
    path:'/herramientas/importar-ventas-abonos',
    component: HerramientasImportarVentasAbonos
  },
  {
    path:'/herramientas/importar-compras',
    component: HerramientasImportarCompras
  },
]

export default rutasFacturas.map(route=>{
  // Agregar a cada ruta un Guard Before Enter para comprobar si el usuario autenticado tiene el rol
  return{
    ...route,
    beforeEnter:(to,from,next)=>{
      if(AuthService.user().roles.includes('herramientas')) next()
      else next({name:'Error404View'})
    }
  } 
})