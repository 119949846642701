<template>
	<v-container>
		<v-row>
				<v-col>
					<router-link to="/herramientas">Volver</router-link>
				</v-col>
			</v-row>
		<v-row>
			<v-col>
				<h2>Marcar como presentadas - FACTURAS VENTAS</h2>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<p>No marques como presentadas las facturas directamente.</p>
				<p>De otra forma podriamos tener incongruencias en las facturas presentadas finales.</p>
				<p>Solo marcar como presentadas despues de estar completamente seguros y haber sido presentadas en hacienda.</p>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-file-input v-model="file" accept="text/csv" label="Archivo CSV" @change="handleFileUpload( $event )"></v-file-input>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-btn @click="marcarComoPresentadas" :loading="loading" :disabled="!numerosFacturas[0]" color="primary">Marcar como presentadas</v-btn>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import Papa from 'papaparse'
import SnackbarService from '@/services/snackbar.service';
import FacturaService from '@/services/factura.service';

export default{
	name:'HerramientasImportVentas',

	data(){
		return{
			file: null,
			content: [],
			numerosFacturas:[],
			parsed: false,
			loading: false,
		}
	},

	methods:{
		handleFileUpload( file ){
			this.file = file
			this.parseFile();
		},

		parseFile(){
			try{
				Papa.parse( this.file, {
					header: true,
					skipEmptyLines: true,
					complete: function( results ){
						this.content = results;
	
						this.numerosFacturas = results.data.map(data => data.numero)
	
						this.parsed = true;
					}.bind(this)
				} );
			}catch(error){
				this.numerosFacturas = []
			}
		},

		async marcarComoPresentadas(){
			this.loading = true

			try {
				const data = await FacturaService.importarPresentadas(this.numerosFacturas)
				SnackbarService.set({
					message:'Se han actualizado: '+ data.cantidadActualizada +' facturas',
					color:'green',
				})
			} catch (error) {
				SnackbarService.set({
					message:'Hubo un error al intentar subir el archivo',
					color:'red',
				})
			}

			this.loading = false
		}
	}
}
</script>