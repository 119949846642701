var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('h1',{staticClass:"subtitle-1 grey--text"},[_vm._v("Albaranes")])])],1),_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"color":"success","to":{ name: 'albaranes.form' }}},[_vm._v("Crear albaran")])],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"solo":"","label":"Filtro","placeholder":"Insertar nombre de cliente a buscar"},model:{value:(_vm.filtroCliente),callback:function ($$v) {_vm.filtroCliente=$$v},expression:"filtroCliente"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-switch',{attrs:{"label":"Solo sin facturar"},model:{value:(_vm.soloSinFacturar),callback:function ($$v) {_vm.soloSinFacturar=$$v},expression:"soloSinFacturar"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"loading":_vm.loading,"items":_vm.albaranes,"headers":_vm.headers,"server-items-length":_vm.totalAlbaranes,"options":_vm.options,"disable-sort":"","dense":"","footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.numero",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({on:{"mouseover":function($event){return _vm.fetchAlbaran(item.id)},"mouseleave":function($event){return _vm.fetchAlbaran(null)}}},on),[_c('router-link',{attrs:{"to":{ name: 'albaranes.show', params: { id: item.id } }}},[_vm._v(_vm._s(item.numero))])],1)]}}],null,true)},[_c('table',[_c('thead',[_c('th',{staticClass:"text-left"},[_vm._v("Cantidad")]),_c('th',{staticClass:"text-left pl-5"},[_vm._v("Referencia")])]),(_vm.albaranFetched)?_c('tbody',_vm._l((_vm.albaranFetched.detalle),function(detalle){return _c('tr',{key:detalle.id},[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(detalle.cantidad))]),_c('td',{staticClass:"text-left pl-5"},[_vm._v(" "+_vm._s(detalle.referencia.nombre)+" ")])])}),0):_c('tbody',[_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"4"}},[_vm._v("Cargando...")])])])])])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("fecha")(item.createdAt))+" ")]}},{key:"item.factura",fn:function(ref){
var item = ref.item;
return [(item.anulado)?_c('v-chip',{attrs:{"small":"","label":""}},[_vm._v(" Anulado ")]):(item.factura)?_c('v-chip',{attrs:{"small":"","label":"","to":{ name: 'facturas.show', params: { id: item.factura.id } }}},[_vm._v(_vm._s(item.factura.numero))]):_c('v-chip',{attrs:{"color":"success","small":"","label":"","to":{ name: 'facturas.crear', params: { albaranId: item.id } }}},[_vm._v("Facturar")])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }