<template>
  <v-container>
    <FacturasModal :factura="seleccionado" @close="cerrarModal" />
    <v-row>
      <v-col>
        <h1 class="subtitle-1 grey--text">Facturas</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          solo
          label="Filtro"
          placeholder="Insertar nombre de cliente a buscar"
          v-model="filtroCliente"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-switch v-model="soloVencidas" label="Solo vencidos"></v-switch>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :loading="loading"
          :items="facturas"
          :headers="headers"
          :server-items-length="totalFacturas"
          :options.sync="options"
          dense
          :footer-props="footerProps"
          disable-sort
        >
          <template v-slot:[`item.numero`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span
                  v-on="on"
                  @mouseover="fetchFactura(item.id)"
                  @mouseleave="fetchFactura(null)"
                >
                  <router-link
                    :to="{ name: 'facturas.show', params: { id: item.id } }"
                    >{{ item.numero }}</router-link
                  >
                </span>
              </template>
              <table>
                <thead>
                  <th class="text-left">Cantidad</th>
                  <th class="text-left pl-5">Referencia</th>
                  <th class="text-right px-5">Precio</th>
                  <th class="text-right">Total</th>
                </thead>
                <tbody v-if="facturaFetched">
                  <tr
                    v-for="detalle in facturaFetched.detalle"
                    :key="detalle.id"
                  >
                    <td class="text-center">{{ detalle.cantidad }}</td>
                    <td class="text-left pl-5">
                      {{ detalle.referencia.nombre }}
                    </td>
                    <td class="text-right px-5">{{ detalle.precio }}</td>
                    <td class="text-right">
                      {{ (detalle.precio * detalle.cantidad).toFixed(2) }}
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td class="text-center" colspan="4">Cargando...</td>
                  </tr>
                </tbody>
              </table>
            </v-tooltip>
          </template>
          <template v-slot:[`item.importeConAbono`]="{ item }">
            {{ Number(item.importeConAbono).toFixed(2) }}
          </template>
          <template v-slot:[`item.fechaVencimiento`]="{ item }">
            {{ item.fechaVencimiento | fecha }}
          </template>
          <template v-slot:[`item.importeTotal`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <v-icon small color="warning" v-if="item.importeAbono > 0"
                    >mdi-help-circle-outline</v-icon
                  >
                  {{ item.importeTotal }}
                </span>
              </template>
              <span>
                <p>Importe Base: {{ item.importeTotal }}</p>
                <p v-if="item.importeAbono > 0">
                  Importe Abonos: {{ item.importeAbono }}
                </p>
                <p v-if="item.importeAbono > 0">
                  Importe Con Abonos Aplicados:
                  {{ item.importeTotal - item.importeAbono }}
                </p>
                <hr />
                <p>IVA: {{ item.iva }}%</p>
                <p v-if="item.recEq > 0">Rec.Eq.: {{ item.recEq }}%</p>
                <p v-if="item.descuento > 0">
                  Descuento: {{ item.descuento }}%
                </p>
              </span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ item.createdAt | fecha }}
          </template>
          <template v-slot:[`item.importePagado`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  x-small
                  color="primary"
                  v-if="
                    Number(item.importePagado) > 0 &&
                    Number(item.importePagado) >= Number(item.importeConAbono)
                  "
                  @click="setSeleccionado(item)"
                  >Si</v-btn
                ><v-btn
                  v-on="on"
                  x-small
                  color="warning"
                  v-else-if="
                    Number(item.importePagado) > 0 &&
                    Number(item.importePagado) < Number(item.importeConAbono)
                  "
                  @click="setSeleccionado(item)"
                  >Parcial</v-btn
                ><v-btn
                  v-on="on"
                  x-small
                  v-else
                  :color="
                    new Date(item.fechaVencimiento) < new Date()
                      ? 'error'
                      : 'grey lighten-3'
                  "
                  @click="setSeleccionado(item)"
                  >No</v-btn
                >
              </template>
              <span>
                <p>Fecha pago: {{ item.fechaPago | fecha }}</p>
                <p>Importe pagado: {{ item.importePagado || "Sin pago" }}</p>
              </span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FacturasModal from "@/views/facturas/FacturasModal";

import FacturaService from "@/services/factura.service";

export default {
  name: "FacturasIndex",

  components: { FacturasModal },

  data() {
    return {
      loading: true,
      headers: [
        { text: "ID", value: "id" },
        { text: "Cliente", value: "cliente.nombre" },
        { text: "Numero", value: "numero" },
        { text: "Importe", value: "importeConAbono", align: "right" },
        { text: "Fecha", value: "createdAt" },
        { text: "Vencimiento", value: "fechaVencimiento" },
        { text: "Pago", value: "importePagado" },
      ],
      facturas: [],
      totalFacturas: -1,
      options: {
        page: 1,
        itemsPerPage: 25,
      },
      filtroCliente: "",
      footerProps: {
        "items-per-page-options": [25, 50, 100],
      },
      seleccionado: null,
      soloVencidas: false,
      facturaFetched: null,
    };
  },

  async created() {
    await this.fetchFacturas();
  },

  methods: {
    async fetchFacturas() {
      this.loading = true;
      const facturasRespuesta = await FacturaService.findAll({
        cliente: this.filtroCliente,
        ...this.options,
        soloVencidas: this.soloVencidas,
      });
      this.facturas = facturasRespuesta.rows;
      this.totalFacturas = facturasRespuesta.count;
      this.loading = false;
    },

    setSeleccionado(factura) {
      this.seleccionado = factura;
    },

    async cerrarModal() {
      await this.fetchFacturas();
      this.seleccionado = null;
    },

    async fetchFactura(facturaId) {
      if (!facturaId) this.facturaFetched = null;
      else {
        this.facturaFetched = await FacturaService.findByPk(facturaId);
      }
    },
  },

  watch: {
    options: {
      handler() {
        this.fetchFacturas();
      },
      deep: true,
    },

    filtroCliente() {
      this.options.page = 1;
      this.fetchFacturas();
    },

    soloVencidas() {
      this.options.page = 1;
      this.fetchFacturas();
    },
  },
};
</script>