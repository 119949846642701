<template>
  <v-container>
    <Modal ref="confirm" />
    <v-row class="mb-5">
      <h3>Registro actual</h3>
    </v-row>
    <v-row>
      <v-text-field
        label="Tipo"
        readonly
        :value="registro.tipo.nombre"
      ></v-text-field>
    </v-row>
    <v-row>
      <v-text-field
        label="Comienzo"
      
        readonly
        :value="entrada"
      ></v-text-field>
    </v-row>
    <v-row>
      <v-text-field
        label="Duracion"
      
        readonly
        :value="duracion"
        color="green"
      ></v-text-field>
    </v-row>
    <v-row>
      <v-btn class="red darken-3" dark block @click="end">FINALIZAR</v-btn>
    </v-row>
  </v-container>
</template>

<script>
import AuthService from "@/services/auth.service";
import SnackbarService from '@/services/snackbar.service';
import HorasService from "@/services/horas.service";

import Modal from "./HorasModal.vue";

import date from './../utils/date'

export default {
  name: "HorasCurrent",

  props: {
    registro:{
      type: Object,
      default: () => {
        return{}
      }
    }
  },

  components: {
    Modal,
  },

  data() {
    return {
      duracion: null,
      interval: null,
    };
  },

  computed: {
    entrada() {
      return new Date(this.registro.entrada).toLocaleString('es');
    },
  },

  methods: {
    async end() {
      if (
        await this.$refs.confirm.open(
          "Confirm",
          "Are you sure you want to delete this record?"
        )
      ) {
        const user = await AuthService.user();
        await HorasService.closeLast(user.id);
        await SnackbarService.set({
          message:'Se ha cerrado el ultimo registro',
        })
        this.$emit("end");
      }
    },
  },

  beforeMount() {
    this.interval = setInterval(() => {
      const entrada = new Date(this.registro.entrada);
      const ahora = new Date();
      const diff = ahora - entrada;
      this.duracion = date.msToTime(diff)
    }, 1000);
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>