<template>
  <v-dialog
    :value="fabricacion"
    max-width="600px"
  >
    <v-card v-if="fabricacion">
      <v-card-title>
        <span>Fabricacion</span>
      </v-card-title>
      <v-card-text>
        <v-simple-table>
          <tr>
            <td>Cliente</td>
            <td>{{fabricacion.cliente.nombre}}</td>
          </tr>
          <tr>
            <td>Cantidad</td>
            <td><v-text-field v-model="datos.cantidad"></v-text-field></td>
          </tr>
          <tr>
            <td>Fabricados</td>
            <td><v-text-field v-model="datos.cantidadFabricada"></v-text-field></td>
          </tr>
          <tr>
            <td>Terminado</td>
            <td><v-switch v-model="datos.cerrado"></v-switch></td>
          </tr>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" block @click="marcarFabricado">Guardar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash'

import FabricacionService from "@/services/fabricacion.service";

export default {
  name: 'FabricacionModal',

  props:['fabricacion'],

  data(){
    return{
      datos:{cerrado:false}
    }
  },

  watch:{
    fabricacion(){
      this.datos = _.cloneDeep(this.fabricacion)
    }
  },

  methods:{
    async marcarFabricado(){
      if(!this.datos.id) return alert('Error en el modal de fabricaicon!')
      await FabricacionService.marcarFabricado(this.datos.id, this.datos)
      this.$emit('close')
    }
  }

}
</script>

<style>

</style>