import AuthService from '@/services/auth.service'

import AlbaranesIndex from '@/views/albaranes/AlbaranesIndex'
import AlbaranesForm from '@/views/albaranes/AlbaranesForm'
import AlbaranesShow from '@/views/albaranes/AlbaranesShow'
import AlbaranesCMR from '@/views/albaranes/AlbaranesCMR'

const rutasAlbaranes = [
  {
    path:'/albaranes',
    component:AlbaranesIndex,
    name:'albaranes.index',
  },
  {
    path:'/albaranes/formulario/:id?',
    component:AlbaranesForm,
    name:'albaranes.form',
  },
  {
    path:'/albaranes/:id',
    component:AlbaranesShow,
    name:'albaranes.show',
  },
  {
    path:'/albaranes/cmr/:id',
    component:AlbaranesCMR,
    name:'albaranes.cmr',
  }
]


export default rutasAlbaranes.map(route=>{
  // Agregar a cada ruta un Guard Before Enter para comprobar si el usuario autenticado tiene el rol
  return{
    ...route,
    beforeEnter:(to,from,next)=>{
      if(AuthService.user().roles.includes('albaranes')) next()
      else next({name:'Error404View'})
    }
  } 
})