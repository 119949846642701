import axios from "@/axios.config";
import SnackbarService from "./snackbar.service";

class HorasService {
  static findAll(userId, queryParams = {}) {
    return axios
      .get(`/api/horas/${userId}`, { params: queryParams })
      .then((res) => res.data);
  }

  static lastRegistro(qrToken) {
    try {
      return axios
        .post("/api/horas", { qrToken })
        .then(() => {
          SnackbarService.set({
            message: "Se ha registrado la hora correctamente",
          });
        })
        .catch(() => {
          SnackbarService.set({
            message: "No se ha podido registrar la hora!",
            color: "red",
          });
        });
    } catch (error) {
      console.error("CUSTOM ERROR:", error);
    }
  }

  static closeLast(userId) {
    return axios.post(`/api/horas/${userId}/close`).then((res) => res.data);
  }

  static createNew(userId, tipoId) {
    return axios
      .post(`/api/horas/${userId}`, { tipoId })
      .then((res) => res.data);
  }

  static consulta(usuarioId, from, to) {
    return axios.get(`/api/horas/consulta`, {
      params: {
        usuarioId,
        from,
        to,
      },
    });
  }
}

export default HorasService;
