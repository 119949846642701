import axios from '@/axios.config'

class FacturaService {
  static findAll(queryParams = {}) {
    return axios
      .get("/api/facturas", { params: queryParams })
      .then((res) => res.data);
  }

  static findByPk(id) {
    return axios.get(`/api/facturas/${id}`).then((res) => res.data);
  }

  static create(data) {
    return axios.post("/api/facturas", data).then((res) => res.data);
  }

  static update(facturaId, data) {
    return axios
      .put(`/api/facturas/${facturaId}`, data)
      .then((res) => res.data);
  }

  static destroy(facturaId) {
		if(!confirm('Seguro que quieres eliminar esta factura?')) return false

    return axios
      .delete(`/api/facturas/${facturaId}`)
      .then((res) => res.data)
			.catch((err) =>{
				alert(err)
			})
  }

  static downloadPDF(facturaId) {
    return axios
      .get(`/api/facturas/${facturaId}/pdf`, { responseType: "blob" })
      .then((response) => {
        window.open(URL.createObjectURL(response.data));
      })
      .catch((err) => {
        alert(err);
      });
  }

  static marcarPagada(facturaId, data) {
    return axios
      .put(`/api/facturas/${facturaId}/marcar-pagada`, data)
      .then((res) => res.data);
  }

  static exportar(fechaLimite) {
    return axios
      .get(`/api/facturas/exportar`, {
        params: { fechaLimite },
        responseType: "blob",
      })
      .then((res) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(res.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute(
          "download",
          `factura_venta_${new Date().toLocaleDateString()}.csv`
        ); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);

        return res.data;
      })
      .catch((error) => {
        alert(error);
        return error;
      });
  }

  static importarPresentadas(arrayNumerosFacturasPresentadas) {
    return axios
      .post(
        "/api/facturas/importar-presentadas",
        arrayNumerosFacturasPresentadas
      )
      .then((res) => res.data);
  }
}

export default FacturaService