<template>
  <v-container>
    <v-card>
      <v-container>
        <v-text-field label="Telefono" v-model="telefono.telefono"></v-text-field>
        <v-text-field label="Contacto" v-model="telefono.contacto"></v-text-field>
        <v-btn v-if="!telefono.id" color="primary" block @click="crear">Crear telefono</v-btn>
        <v-btn v-else color="primary" block @click="actualizar">Actualizar telefono</v-btn>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import ClienteService from '@/services/cliente.service'
import SnackbarService from '@/services/snackbar.service'

export default {
  name: 'ClientesTelefonoForm',

  data(){
    return{
      telefono:{
        id:null,
        telefono:'',
        contacto:'',
        clienteId:null,
      }
    }
  },

  methods:{
    async crear(){
      await ClienteService.addTelefono(this.telefono)
      SnackbarService.set({message:'Se ha creado correctamente el telefono'})
      this.$router.push({name:'clientes.show',params:{id:this.telefono.clienteId}})
    },

    async actualizar(){
      await ClienteService.updateTelefono(this.telefono.id,this.telefono)
      SnackbarService.set({message:'Se ha actualizado correctamente el telefono'})
      this.$router.push({name:'clientes.show',params:{id:this.telefono.clienteId}})
    }
  },

  async created(){
    const {id, clienteId} = this.$route.params
    this.telefono.clienteId = clienteId
    if(id){
      this.telefono = await ClienteService.findTelefono(id)
    }
  }
}
</script>