import AuthService from '@/services/auth.service'

import FabricacionIndex from '@/views/fabricacion/FabricacionIndex'

const rutasFabricacion = [
  {
    path:'/fabricacion',
    component:FabricacionIndex,
    name:'fabricacion.index',
  },
]

export default rutasFabricacion.map(route=>{
  // Agregar a cada ruta un Guard Before Enter para comprobar si el usuario autenticado tiene el rol
  return{
    ...route,
    beforeEnter:(to,from,next)=>{
      if(AuthService.user().roles.includes('fabricacion')) next()
      else next({name:'Error404View'})
    }
  } 
})