import axios from '@/axios.config'

class ReciboService {

  static findAll(){
    return axios.get('/api/recibos')
      .then(res=>res.data)
  }

  static findByPk(id){
    return axios.get(`/api/recibos/${id}`)
      .then(res=>res.data)
  }

  static create(recibo){
    return axios.post(`/api/recibos`,recibo)
      .then(res=>res.data)
  }

  static update(reciboId,recibo){
    return axios.put(`/api/recibos/${reciboId}`,recibo)
      .then(res=>res.data)
  }

  static findAllEstados(){
    return axios.get('/api/recibos/estados')
      .then(res=>res.data)
  }

}

export default ReciboService