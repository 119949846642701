<template>
  <div>
    <v-simple-table v-if="historial.length>0" dense>
        <tbody class="text-left">
            <tr v-for="register in historialFormated" :key="register._id">
                <td>{{register.tipo.nombre}}</td>
                <td class="text-right">{{register.entrada}}</td>
                <td class="text-right">
                    <span v-if="register.duracion!=='ACTUAL'">{{register.duracion}}</span>
                    <v-chip v-else
                        color="green"
                        class="white--text"
                        label
                        x-small
                    >{{register.duracion}}</v-chip>
                </td>
            </tr>
        </tbody>
    </v-simple-table>
    <v-simple-table v-else>
        <tbody class="text-left">
            <tr>
                <td>Cargando registros...</td>
            </tr>
        </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import date from '../utils/date'

export default {
    name:'HorasHistorial',
    props:{
        historial:{
            type: Array,
            default: ()=>[]
        }
    },
    computed:{
        historialFormated(){
            if(!this.historial || this.historial.lenght<1) return []
            return this.historial.map(registro=>{
                const entrada = new Date(registro.entrada)
                const salida = new Date(registro.salida)
                const duracion = salida - entrada
                const formated = date.msToTime(duracion)
                return{
                    _id:registro._id,
                    tipo:registro.tipo,
                    entrada:entrada.toLocaleDateString(),
                    duracion:duracion>0?formated:'ACTUAL',
                }
            })
        }
    }
}
</script>