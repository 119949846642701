<template>
  <v-container>
    <v-row>
      <v-col>
        <h1 class="subtitle-1 grey--text">
          <router-link :to="{ name: 'compras.index' }">Compras</router-link>
        </h1>
      </v-col>
    </v-row>
    <v-card>
      <v-container>
      <v-row>
      <v-col>
        <v-btn color="error" x-small @click="eliminar">Eliminar factura</v-btn>
      </v-col>
    </v-row>
        <v-row>
          <v-col>
            <AutoProveedor :proveedorId="compra.proveedor.id" v-model="compra.proveedor" ></AutoProveedor>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field v-model="compra.numero" label="Numero Factura"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <InputDate v-model="compra.fecha" label="Fecha Factura"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <InputDate v-model="compra.fechaVencimiento" label="Fecha Vencimiento"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field v-model="compra.baseImponible" label="Base Imponible"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field v-model="compra.ivaAmount" label="Importe IVA"></v-text-field>
          </v-col>
          <v-col>
            <v-text-field v-model="compra.iva" label="% IVA"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field v-model="compra.tasas" label="Otras tasas"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field v-model="compra.total" label="Importe Total"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field v-model="compra.importePagado" label="Importe Pagado"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <InputDate v-model="compra.fechaPago" label="Fecha Pago"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea v-model="compra.nota" label="Nota"></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn v-if="!compra.id" color="primary" block @click="crear">Guardar factura de compra</v-btn>
            <v-btn v-else color="primary" block @click="actualizar">Actualizar factura de compra</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>



<script>
import AutoProveedor from "@/components/AutoProveedor";
import InputDate from '@/components/InputDate'

import CompraService from '@/services/compra.service'
import SnackbarService from "@/services/snackbar.service";

export default {
  name: "CompraForm",

  components: { AutoProveedor, InputDate},

  data() {
    return {
      compra:{
        id:null,
        proveedor: {},
        numero:null,
        importePagado:null,
        fecha:null,
        fechaPago:null,
        fechaVencimiento:null,
        baseImponible:0,
        iva:0,
        ivaAmount:0,
        tasas:null,
        total:0,
        nota:null,
      }
    };
  },

  async created(){
    const { id } = this.$route.params;
    if(!id) return false
    const compra = await CompraService.findByPk(id)
    this.compra = {
        id:compra.id,
        proveedor: compra.proveedor,
        numero:compra.numero,
        importePagado:compra.importePagado,
        fecha:new Date(compra.fecha),
        fechaPago:new Date(compra.fechaPago),
        fechaVencimiento:new Date(compra.fechaVencimiento),
        baseImponible:compra.baseImponible,
        iva:compra.iva,
        ivaAmount:compra.ivaAmount,
        tasas:compra.tasas,
        total:compra.total,
        nota:compra.nota,
    }
  },


  methods:{
    async crear(){
      await CompraService.create(this.compra)
       await SnackbarService.set({ message: "Se ha creado la factura de compra" });
      this.$router.push({name:'compras.index'})
    },

    async actualizar(){
      await CompraService.update(this.compra.id,this.compra)
       await SnackbarService.set({ message: "Se ha actualizado la factura de compra" });
      this.$router.push({name:'compras.index'})
    },

    async eliminar(){
      const res = confirm('Seguro que quieres eliminar esta factura?')
      if(!res) return false
      await CompraService.destroy(this.compra.id)
      await SnackbarService.set({ message: "Se ha eliminado la factura de compra" });
      this.$router.push({name:'compras.index'})
    }
  }
};
</script>