import axios from "@/axios.config";

class AdminFeaturesFlagsService {
  static findAll() {
    return axios.get("/api/admin/features-flags").then((res) => res.data)
  }

  static update(id,data){
    console.log(id,data)
    return axios.put(`/api/admin/features-flags/${id}`,data).then(res=>res.data)
  }
}

export default AdminFeaturesFlagsService;
