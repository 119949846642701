<template>
  <v-container>
    <v-row>
      <v-col>
        <h1 class="subtitle-1 grey--text">Pedidos</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn color="primary" dark :to="{ name: 'pedidos.crear' }"
          >crear pedido</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          solo
          label="Filtro"
          placeholder="Insertar nombre de cliente a buscar"
          v-model="filtroCliente"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-switch
          v-model="soloPendientes"
          label="Ver solo pendientes"
        ></v-switch>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="pedidos"
          :server-items-length="totalPedidos"
          :options.sync="options"
          :footer-props="footerProps"
          dense
          disable-sort
        >
          <template v-slot:[`item.numero`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span
                  v-on="on"
                  @mouseover="fetchPedido(item.id)"
                  @mouseleave="fetchPedido(null)"
                >
                  <router-link
                    :to="{ name: 'pedidos.show', params: { id: item.id } }"
                    >{{ item.numero || "SN" }}</router-link
                  ></span
                >
              </template>
              <table>
                <thead>
                  <th class="text-left">Cantidad</th>
                  <th class="text-left pl-5">Referencia</th>
                  <th class="text-right px-5">Precio</th>
                  <th class="text-right">Total</th>
                </thead>
                <tbody v-if="pedidoFetched">
                  <tr
                    v-for="detalle in pedidoFetched.detalle"
                    :key="detalle.id"
                  >
                    <td class="text-center">{{ detalle.cantidad }}</td>
                    <td class="text-left pl-5">
                      {{ detalle.referencia.nombre }}
                    </td>
                    <td class="text-right px-5">{{ detalle.precio }}</td>
                    <td class="text-right">
                      {{ (detalle.precio * detalle.cantidad).toFixed(2) }}
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td class="text-center" colspan="4">Cargando...</td>
                  </tr>
                </tbody>
              </table>
            </v-tooltip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <span v-if="item.presupuestos.length > 0"
              ><v-chip
                label
                small
                v-for="presupuesto in item.presupuestos"
                :key="presupuesto.id"
                ><router-link
                  :to="{
                    name: 'presupuestos.show',
                    params: { id: presupuesto.id },
                  }"
                  >{{ presupuesto.numero }}</router-link
                ></v-chip
              ></span
            >
            <v-btn
              v-else
              color="success"
              x-small
              :to="{
                name: 'presupuestos.crear',
                params: { pedidoId: item.id },
              }"
            >
              Presupuestar
            </v-btn>
          </template>
          <template v-slot:[`item.fecha`]="{ item }">
            {{ item.fecha | fecha }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PedidoService from "@/services/pedido.service";

export default {
  name: "PedidosIndex",

  data() {
    return {
      loading: true,
      headers: [
        { text: "Id", value: "id" },
        { text: "Cliente", value: "cliente.nombre" },
        { text: "Numero", value: "numero" },
        { text: "Fecha", value: "fecha" },
        { text: "Presupuestos", value: "actions", sortable: false },
      ],
      pedidos: [],
      totalPedidos: -1,
      filtroCliente: "",
      options: {},
      footerProps: {
        "items-per-page-options": [25, 50, 100],
      },
      soloPendientes: false,
      pedidoFetched: null,
    };
  },

  async created() {
    this.fetchPedidos();
  },

  methods: {
    async fetchPedidos() {
      this.loading = true;
      const res = await PedidoService.findAll({
        cliente: this.filtroCliente,
        ...this.options,
        soloPendientes: this.soloPendientes,
      });
      this.pedidos = res.rows;
      this.totalPedidos = res.count;
      this.loading = false;
    },

    async fetchPedido(pedidoId) {
      if (!pedidoId) this.pedidoFetched = null;
      else {
        this.pedidoFetched = await PedidoService.findByPk(pedidoId);
      }
    },
  },

  watch: {
    options: {
      handler() {
        this.fetchPedidos();
      },
      deep: true,
    },

    filtroCliente() {
      this.options.page = 1;
      this.fetchPedidos();
    },

    soloPendientes() {
      this.options.page = 1;
      this.fetchPedidos();
    },
  },
};
</script>