<template>
  <v-dialog v-model="show" @click:outside="$emit('close')">
    <v-card v-if="produccion">
      <v-card-title>{{produccion.cliente.nombre}}</v-card-title>
      <v-card-text>
        <p>Referencia: {{produccion.referenciaNombre}}</p>
        <p>Cantidad: {{produccion.cantidad}}</p>
        <v-text-field label="Fabricado" type="number" v-model="fabricado"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn block color="primary" @click="updateProduccion">Guardar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ProduccionService from '@/services/produccion.service'

export default {
  name:'ProduccionModal',

  props:['produccion'],

  data(){
    return{
      show:false,
      fabricado:0,
    }
  },

  methods:{
    async updateProduccion(){
      await ProduccionService.updateProduccion(this.produccion.id,this.fabricado)
      this.show = false
      this.fabricado = 0
      this.$emit('close')
    }
  },  

  watch:{
    produccion:{
      handler(){
        if(this.produccion){
          this.show = true
        }
        if(this.produccion?.fabricado){
          this.fabricado = this.produccion.fabricado
        }
      },
      deep:true
    },
    // produccion(){
    //   if(this.produccion){
    //       this.show = true
    //   }
    // },
    // 'produccion?.fabricado':function(){
    //   this.fabricado = this.produccion.fabricado
    // }
  }
 
}
</script>