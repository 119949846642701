<template>
  <v-container>
    <v-card>
      <v-form>
        <v-container>
          <v-text-field
            @input="nombreReferencia"
            label="Referencia"
            v-model="codigo"
            :error-messages="nombre ? nombre.message : null"
            :messages="nombre ? nombre.nombre : null"
            readonly
          ></v-text-field>
          <v-text-field label="Stock" v-model="stock"></v-text-field>
          <v-textarea label="Nota" v-model="nota"></v-textarea>
          <v-btn color="primary" block @click="actualizar">Actualizar referencia</v-btn>
        </v-container>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import ReferenciaService from "@/services/referencia.service";

export default {
  name: "ReferenciasEdit",

  data() {
    return {
      codigo: null,
      stock: 0,
      nota: null,

      nombre: null,
    };
  },

  methods: {
    async nombreReferencia() {
      this.nombre = await ReferenciaService.getData(this.codigo);
    },

    async actualizar() {
      const res = await ReferenciaService.update(this.$route.params.id,{
        codigo: this.codigo,
        stock: this.stock,
        nota: this.nota,
      });
      console.log(res)
    },
  },

  async created(){
    const referencia = await ReferenciaService.findByPk(this.$route.params.id)
    this.codigo = referencia.codigo
    this.stock = referencia.stock
    this.nota = referencia.nota
  }
};
</script>