<template>
  <v-container>
    <v-row>
      <v-col>
        <h1 class="subtitle-1 grey--text">Presupuestos</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn color="primary" :to="{ name: 'presupuestos.crear' }"
          >Nuevo presupuesto</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          solo
          label="Filtro"
          placeholder="Insertar nombre de cliente a buscar"
          v-model="filtroCliente"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-switch v-model="soloSinAceptar" label="Solo sin aceptar"></v-switch>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="presupuestos"
          :server-items-length="totalPresupuestos"
          :options.sync="options"
          :footer-props="footerProps"
          dense
          disable-sort
        >
          <template v-slot:[`item.presupuesto`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span
                  v-on="on"
                  @mouseover="fetchPresupuesto(item.id)"
                  @mouseleave="fetchPresupuesto(null)"
                >
                  <router-link
                    :to="{ name: 'presupuestos.show', params: { id: item.id } }"
                  >
                    {{ item.numero }}
                  </router-link>
                </span>
              </template>
              <table>
                <thead>
                  <th class="text-left">Cantidad</th>
                  <th class="text-left pl-5">Referencia</th>
                  <th class="text-right px-5">Precio</th>
                  <th class="text-right">Total</th>
                </thead>
                <tbody v-if="presupuestoFetched">
                  <tr
                    v-for="detalle in presupuestoFetched.detalle"
                    :key="detalle.id"
                  >
                    <td class="text-center">{{ detalle.cantidad }}</td>
                    <td class="text-left pl-5">
                      {{ detalle.referencia.nombre }}
                    </td>
                    <td class="text-right px-5">{{ detalle.precio }}</td>
                    <td class="text-right">
                      {{ (detalle.precio * detalle.cantidad).toFixed(2) }}
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td class="text-center" colspan="4">Cargando...</td>
                  </tr>
                </tbody>
              </table>
            </v-tooltip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              x-small
              color="primary"
              v-if="
                !item.aceptadoAt &&
                new Date() <
                  new Date(
                    new Date(item.createdAt).getTime() +
                      new Date(1000 * 60 * 60 * 24 * 7).getTime()
                  )
              "
              @click="marcarComoAceptado(item.id)"
            >
              aceptar
            </v-btn>
            <v-btn
              x-small
              color="grey lighten-3"
              v-else-if="
                !item.aceptadoAt &&
                new Date() >
                  new Date(
                    new Date(item.createdAt).getTime() +
                      new Date(1000 * 60 * 60 * 24 * 7).getTime()
                  )
              "
              @click="marcarComoAceptado(item.id)"
              >caducado</v-btn
            >
            <v-btn
              color="green white--text"
              v-else
              x-small
              @click="desaceptar(item.id)"
            >
              Aceptado
            </v-btn>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">{{
            item.createdAt | fecha
          }}</template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PresupuestoService from "@/services/presupuesto.service";
import SnackbarService from "@/services/snackbar.service";

export default {
  name: "PresupuestosIndex",

  data() {
    return {
      headers: [
        { text: "id", value: "id" },
        { text: "presupuesto", value: "presupuesto" },
        { text: "cliente", value: "cliente.nombre" },
        { text: "pedido", value: "pedido.numero" },
        { text: "fecha", value: "createdAt" },
        { text: "Aceptados", value: "actions" },
      ],
      presupuestos: [],
      loading: true,
      filtroCliente: "",
      totalPresupuestos: -1,
      options: {},
      footerProps: {
        "items-per-page-options": [25, 50, 100],
      },
      soloSinAceptar: false,
      presupuestoFetched: null,
    };
  },

  async created() {
    await this.fetchPresupuestos();
  },

  methods: {
    async fetchPresupuestos() {
      this.loading = true;
      const res = await PresupuestoService.findAll({
        cliente: this.filtroCliente,
        ...this.options,
        soloSinAceptar: this.soloSinAceptar,
      });
      this.presupuestos = res.rows;
      this.totalPresupuestos = res.count;
      this.loading = false;
    },

    async marcarComoAceptado(pedidoId) {
      if (!confirm("Quiere aceptar el pedido?" + pedidoId)) return false;
      await PresupuestoService.aceptar(pedidoId);
      await SnackbarService.set({
        message:
          "Se ha marcado el presupuesto como aceptado y se ha generado la fabricación",
      });
      await this.fetchPresupuestos();
    },

    async desaceptar(pedidoId) {
      if (
        !confirm(
          "Estas seguro que quieres desaceptar el pedido? Se borraran las fabricaciones relacionadas!"
        )
      )
        return false;
      PresupuestoService.desaceptar(pedidoId)
        .then(async () => {
          await SnackbarService.set({
            message:
              "Se ha marcado el presupuesto como NO presentado y se han borrado las ordenes de fabricación.",
          });
        })
        .then(async () => {
          await this.fetchPresupuestos();
        })
        .catch(async () => {
          await SnackbarService.set({
            message:
              "No se puede desmarcar porque ya existe un albaran hecho con este presupuesto!",
            color: "red",
          });
        });

      console.log(pedidoId);
    },

    async fetchPresupuesto(presupuestoId) {
      if (!presupuestoId) this.presupuestoFetched = null;
      else {
        const presupuesto = await PresupuestoService.findByPk(presupuestoId);
        this.presupuestoFetched = presupuesto;
      }
    },
  },

  watch: {
    options: {
      handler() {
        this.fetchPresupuestos();
      },
      deep: true,
    },

    filtroCliente() {
      this.options.page = 1;
      this.fetchPresupuestos();
    },

    soloSinAceptar() {
      this.options.page = 1;
      this.fetchPresupuestos();
    },
  },
};
</script>