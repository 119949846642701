<template>
  <v-text-field
    v-model="data.codigo"
    label="Referencia"
    :messages="data.referencia.detalle.nombre"
    :error-messages="data.referencia.detalle.message"
    :append-icon="readonly ? '' : 'mdi-close'"
    @click:append="close"
    :readonly="readonly"
  ></v-text-field>
</template>

<script>
import ReferenciaService from "@/services/referencia.service";


export default {
  name: "InputReferencia",
  
  props: ["codigo", "readonly", "idx", "clienteId"],

  data() {
    return {
      data: {
        codigo: this.codigo, // La prop codigo se setea como valor inicial para el data.codigo
        referencia: {
          detalle: {},
        },
      },
    }
  },

  methods: {
    async fetchDetalle() {
      this.data.referencia = await ReferenciaService.detalle(this.data.codigo, this.clienteId);
      this.$emit("fetch", {...this.data.referencia,idx:this.idx,codigo:this.data.codigo}); // El evento input emite los datos de la referencia
    },

    close() {
      if(this.readonly) return false
      this.data.codigo = ''
      this.$emit("close");
    },
  },

  watch: {
    'data.codigo':function() {
      this.fetchDetalle();
    },

    'codigo':function(){
      this.data.codigo = this.codigo
    }
  },

  created(){
    this.fetchDetalle()
  }
};
</script>