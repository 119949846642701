<template>
  <v-container>
    <v-row>
      <v-col>
        <h1 class="subtitle-1 grey--text">
          <router-link :to="{ name: 'referencias.index' }"
            >Referencias</router-link
          >
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field v-model="codigo" label="Codigo"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="nombre"
                  label="Nombre referencia"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn v-if="!id" block color="primary" @click="crear"
                  >Crear referencia especial</v-btn
                >
                <v-btn v-else block color="primary" @click="actualizar"
                  >Actualizar referencia especial</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SnackbarService from '@/services/snackbar.service'
import ReferenciaEspecialService from "@/services/referencia-especial.service";

export default {
  name: "ReferenciasEspecialesForm",

  data() {
    return {
      id:null,
      codigo: null,
      nombre: null,
    };
  },

  methods: {
    async crear() {
      ReferenciaEspecialService.create({
        codigo: this.codigo,
        nombre: this.nombre,
      })
      .then(()=>this.$router.push({ name: "referencias.index" }))
      .then(()=>SnackbarService.set({
        message:'Se ha generado la referencia especial'
      }))
      .catch(err=>{
        SnackbarService.set({
          message:`No se ha guardado la referencia. ${err.response.data.message}`,
          color:'red',
        })
      })
      
    },
    async actualizar() {
      await ReferenciaEspecialService.update(this.id,{
        codigo: this.codigo,
        nombre: this.nombre,
      })      .then(()=>this.$router.push({ name: "referencias.index" }))
      .then(()=>SnackbarService.set({
        message:'Se ha actualizado la referencia especial'
      }))
      .catch(err=>{
        SnackbarService.set({
          message:`No se ha guardado la referencia. ${err.response.data.message}`,
          color:'red',
        })
      })
      this.$router.push({ name: "referencias.index" });
    },
  },

  async created(){
    const {id} = this.$route.params
    if(!id) return false
    const referencia = await ReferenciaEspecialService.findByPk(id)
    this.id = referencia.id
    this.codigo = referencia.codigo
    this.nombre = referencia.nombre

  }
};
</script>