import store from "@/store";
import router from "@/router";

import axios from "@/axios.config";
import * as jose from "jose";

class AuthService {
  static user() {
    return store.getters["auth/getUser"];
  }

  static login(loginCredentials) {
    store.dispatch("auth/LOGIN", loginCredentials);
  }

  static loginWithJWT(accessToken) {
    store.dispatch("auth/SET_USER_FROM_TOKEN", accessToken);
  }

  static logout() {
    store.dispatch("auth/LOGOUT");
  }

  static register(newUserData) {
    axios
      .post("/auth/register", newUserData)
      .then(() => {
        router.push({ name: "auth.login" });
      })
      .catch((err) => console.log(err));
  }

  static hasRoles(...requiredRoles) {
    // If no user logged in
    if (!this.user()) {
      return false;
    }
    // Get current user roles
    const userRoles = this.user().roles;
    // Check if every role in reequiredRoles exists in userRoles (if some is not present, then user is not authorized)
    const hasRoles = requiredRoles.every((role) => userRoles.includes(role));
    // Returns boolean
    return hasRoles;
  }

  static tokenNotExpired() {
    const user = this.user();
    if (!user) {
      return false;
    }
    // Get token
    const token = this.user().accessToken;
    // Exipration of token
    const { exp } = jose.decodeJwt(token);
    // Check if exipred
    if (Date.now() >= exp * 1000) {
      return false;
    }
    return true;
  }
}

export default AuthService;
