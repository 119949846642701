<template>
  <v-container>
    <h1 class="subtitle-1 grey--text">
      <router-link :to="{ name: 'presupuestos.index' }"
        >Presupuestos</router-link
      >
    </h1>
    <v-card :loading="loaders.card">
      <v-card-title>Presupuesto</v-card-title>
      <v-card-actions>
        <v-btn
          color="primary"
          outlined
          small
          @click="downloadPDF"
          :loading="loaders.btn"
          >pdf</v-btn
        >
        <v-btn
          color="primary"
          v-if="presupuesto"
          outlined
          small
          :to="{
            name: 'presupuestos.rehacer',
            params: { presupuestoId: presupuesto.id },
          }"
          >rehacer</v-btn
        >
      </v-card-actions>
      <v-card-text v-if="presupuesto">
        <p>Numero: {{ presupuesto.numero }}</p>
        <p>Fecha: {{ presupuesto.createdAt | fecha }}</p>
        <p>
          Fecha Solicitada:
          <span v-if="presupuesto.fechaExigida">{{
            presupuesto.fechaExigida | fecha
          }}</span
          ><span v-else>Sin fecha exigida</span>
        </p>
        <p>Cliente: {{ presupuesto.cliente.nombre }}</p>
        <p>
          Lugar de entrega: {{ presupuesto.direccion.nombre }}
          <v-chip small v-if="presupuesto.recogidaPropia" color="primary"
            >Recogida propia</v-chip
          >
        </p>
        <p>Creado por: {{ presupuesto.usuario.mail }}</p>
        <p>
          Pedido:
          <span v-if="presupuesto.pedido"
            ><router-link
              :to="{
                name: 'pedidos.show',
                params: { id: presupuesto.pedido.id },
              }"
              >{{ presupuesto.pedido.numero || "SN" }}</router-link
            ></span
          ><span v-else>SN</span>
        </p>
        <p>
          Albaran:
          <router-link
            v-for="albaran in albaranes"
            :key="albaran.id"
            :to="{ name: 'albaranes.show', params: { id: albaran.id } }"
            >{{ albaran.numero }}</router-link
          >
        </p>
        <p>Beneficio calculado: {{ presupuesto.beneficio }}</p>

        <v-divider class="my-5"></v-divider>

        <p>Detalles:</p>
        <ul>
          <li v-for="detalle in presupuesto.detalle" :key="detalle.id">
            <p>
              {{ detalle.cantidad }} - {{ detalle.referencia.nombre }} -
              {{ detalle.precio }}€
							<span v-if="detalle.referencia.detalle.excento"> - impuesto excluido</span>
              <v-chip small color="success" v-if="detalle.completar == 1"
                >Rellenar</v-chip
              ><v-chip small color="error" v-if="detalle.completar == -1"
                >Quitar</v-chip
              >
            </p>
          </li>
        </ul>

        <p>Nota:</p>
        <p>{{ presupuesto.nota }}</p>

        <v-divider class="my-5"></v-divider>

        <table>
          <tr>
            <td>Base Imponible</td>
            <td class="text-right">{{ presupuesto.importeBaseImponible }}€</td>
          </tr>
          <tr>
            <td>KGs aplicados a impuesto plastico</td>
            <td class="text-right">{{ presupuesto.kgs_impuesto_especial}} Kgs</td>
          </tr>
          <tr>
            <td>Base impuesto especial</td>
            <td class="text-right">{{ presupuesto.importe_impuesto_especial }}€</td>
          </tr>
          <tr>
            <td>IVA</td>
            <td class="text-right">{{ presupuesto.iva }}%</td>
          </tr>
          <tr>
            <td>Base IVA</td>
            <td class="text-right">{{ presupuesto.importeIva }}€</td>
          </tr>
          <tr>
            <td>RecEq</td>
            <td class="text-right">{{ presupuesto.recEq || 0 }}%</td>
          </tr>
          <tr>
            <td>Base RecEq</td>
            <td class="text-right">{{ presupuesto.importeRecEq }}€</td>
          </tr>
          <tr>
            <td>Descuento</td>
            <td class="text-right">{{ presupuesto.descuento || 0 }}%</td>
          </tr>
          <tr>
            <td>Base Descuento</td>
            <td class="text-right">{{ presupuesto.importeDescuento }}€</td>
          </tr>
          <tr>
            <td>Total</td>
            <td class="text-right">{{ presupuesto.importeTotal }}€</td>
          </tr>
        </table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import PresupuestoService from "@/services/presupuesto.service";

export default {
  name: "PresupuestoService",

  data() {
    return {
      presupuesto: null,
      loaders: {
        btn: false,
        card: true,
      },
    };
  },

  async beforeCreate() {
    const presupuestoId = this.$route.params.id;
    this.presupuesto = await PresupuestoService.findByPk(presupuestoId);
    this.loaders.card = false;
  },

  methods: {
    async downloadPDF() {
      this.loaders.btn = true;
      const pdf = await PresupuestoService.downloadPDF(this.presupuesto.id);
      console.log(pdf);
      this.loaders.btn = false;
    },
  },

  computed: {
    albaranes() {
      let arrayNumeroAlbaranes = [];
      this.presupuesto.detalle.forEach((detalle) => {
        detalle.fabricacion.forEach((fabricacion) => {
          fabricacion.albaranesDetalles.forEach((detalle) => {
            arrayNumeroAlbaranes.push({
              numero: detalle.albaran.numero,
              id: detalle.albaran.id,
            });
          });
        });
      });

      // Devolver el array removiendo los objetos duplicados
      return [
        ...new Map(
          arrayNumeroAlbaranes.map((item, key) => [item[key], item])
        ).values(),
      ];
    },
  },
};
</script>