<template>
	<div>
		<v-container>
			<v-row>
				<v-col>
					<router-link to="/herramientas">Volver</router-link>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<h2>Exportar ABONOS VENTAS</h2>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<h2>Fecha limite</h2>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-date-picker v-model="fechaLimite" locale="ES-es" first-day-of-week="1" no-title/>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-btn :loading="loading" @click="exportar" color="primary">Exportar abonos pendientes</v-btn>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import AbonoService from '@/services/factura/abono.service';
import SnackbarService from '@/services/snackbar.service';

export default {
	data() {
		return {
			loading:false,
			fechaLimite: new Date().toISOString(),
		}
	},

	methods:{
		async exportar(){
			this.loading = true
			try{
				await AbonoService.exportar(this.fechaLimite)
			}catch{
				SnackbarService.set({
					message:'Error al descargar los datos',
					color:'red',
				})
			}
			this.loading=false
		}
	}
}
</script>