import axios from "@/axios.config";

class ReferenciaService {
  static detalle(data, cliente = null) {
    return axios
      .post("/api/referencias/detalle", { code: data, cliente: cliente })
      .then((res) => res.data);
  }

  static create(data) {
    return axios.post("/api/referencias", data).then((res) => res.data);
  }

  static findAll(queryParams = {}) {
    return axios
      .get("/api/referencias", { params: queryParams })
      .then((res) => res.data);
  }

  static findByPk(id) {
    return axios.get(`/api/referencias/${id}`).then((res) => res.data);
  }

  static update(id, data) {
    return axios.put(`/api/referencias/${id}`, data).then((res) => res.data);
  }
}

export default ReferenciaService;
