var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('h1',{staticClass:"subtitle-1 grey--text"},[_vm._v("Referencias")])])],1),_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"color":"primary","to":{ name: 'referencias.crear' }}},[_vm._v("crear referencia")])],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"solo":"","dense":"","placeholder":"Filtro"},model:{value:(_vm.filtroReferencia),callback:function ($$v) {_vm.filtroReferencia=$$v},expression:"filtroReferencia"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"loading":_vm.loading,"server-items-length":_vm.totalReferencias,"dense":"","headers":_vm.headers,"items":_vm.referencias,"sort-by":"nombre","options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.codigo",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'referencias.editar', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.codigo)+" ")])]}},{key:"item.precio.precio",fn:function(ref){
var value = ref.value;
return [(value)?_c('span',[_vm._v(" "+_vm._s(value.toFixed(3))+"€ ")]):_vm._e()]}},{key:"item.precio.precioM2",fn:function(ref){
var value = ref.value;
return [(value)?_c('span',[_vm._v(" "+_vm._s(value.toFixed(3))+"€")]):_vm._e()]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"color":"primary","to":{ name: 'referencias.especiales.form' }}},[_vm._v("crear referencia especial")])],1)],1),_c('v-row',[_c('v-col',[_c('v-simple-table',{attrs:{"dense":""}},[_c('thead',[_c('th',[_vm._v("Codigo")]),_c('th',[_vm._v("Nombre referencia")])]),_c('tbody',_vm._l((_vm.referenciasEspeciales),function(referencia){return _c('tr',{key:referencia.id},[_c('td',[_c('router-link',{attrs:{"to":{
                  name: 'referencias.especiales.form',
                  params: { id: referencia.id },
                }}},[_vm._v(_vm._s(referencia.codigo))])],1),_c('td',[_vm._v(_vm._s(referencia.nombre))])])}),0)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }