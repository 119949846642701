import axios from '@/axios.config'

class ProduccionService {
  static getMaquinas (){
    return axios.get('/api/producciones/maquinas')
      .then(res=>res.data)
  }

  static actualizarListaProduccion(data){
    return axios.post('/api/producciones',data)
      .then(res=>res.data)
  }

  static getListaProduccion(maquinaId){
    return axios.get('/api/producciones',{params:{maquinaId}})
      .then(res=>res.data)
      .then(data=>data.map(produccion=>{
        return{
          id:produccion.id,
          cantidad:produccion.cantidad, 
          fabricacionId:produccion.fabricacionId,
          fabricado:produccion.fabricado,
          maquinaId:produccion.maquinaId,
          order:produccion.orden,
          referenciaCode:produccion.referencia.codigo,
          referenciaNombre:produccion.referencia.nombre,
          cliente:produccion.fabricacion.cliente
        }
      }))
  }


  static updateProduccion(produccionId,fabricado){
    return axios.put(`/api/producciones/${produccionId}`,{fabricado})
      .then(res=>res.data)
  }
}

export default ProduccionService