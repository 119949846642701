<template>
	<v-container>
		<h1 class="subtitle-1 grey--text">
			<router-link :to="{ name: 'presupuestos.index' }">Presupuestos</router-link>
		</h1>
		<v-alert v-if="!checkTransporteGiven" type="error">
			La direccion seleccionada no tiene asignado un coste de transporte. No se
			puede calcular costes.
		</v-alert>
		<v-tooltip bottom v-else>
			<template v-slot:activator="{ on }">
				<v-alert v-on="on" :type="BeneficioTotal > 200 ? 'success' : 'warning'">
					El beneficio total del presupuesto
					<strong>(calculo para un trailer)</strong> es de:
					{{ BeneficioTotal }}
				</v-alert>
			</template>
			<!-- Detalle del presupuesto de costes -->
			<v-simple-table class="transparent white--text">
				<thead>
					<th>Uds.</th>
					<th>Ref.</th>
					<th>B/horas</th>
					<th>B/camion</th>
					<th>Coste</th>
					<th>Coste M2</th>
					<th>Bnf/R</th>
					<th>Bnf. Total</th>
				</thead>
				<tbody>
					<tr v-for="(detalle, idx) in detalleCosteCalculado" :key="idx">
						<td>{{ detalle.cantidad }}</td>
						<td>{{ detalle.referencia.codigo }}</td>
						<td>
							<span v-if="
	detalle.referencia.precio && detalle.referencia.precio.detalle
">{{
		detalle.referencia.precio.detalle.bobinasHora
}}</span><span v-else>0</span>
						</td>
						<td>
							<span v-if="
	detalle.referencia.volumen &&
	detalle.referencia.volumen &&
	detalle.referencia.volumen.bobinasTrailer
">{{
		detalle.referencia.volumen.bobinasTrailer
}}</span><span v-else>0</span>
						</td>
						<td>
							<span v-if="
	detalle.referencia.precio && detalle.referencia.precio.precio
">{{ detalle.referencia.precio.precio }}</span>
						</td>
						<td>
							<span v-if="
	detalle.referencia.precio &&
	detalle.referencia.precio.precioM2
">{{ detalle.referencia.precio.precioM2 }}</span>
						</td>
						<td>
							<span v-if="detalle.referencia.precio">{{
		(detalle.precio - detalle.precioConPorte)
}}</span>
						</td>
						<td>
							<span v-if="detalle.referencia.precio">{{
		(
			detalle.cantidad *
			(detalle.precio - detalle.precioConPorte)
		)
}}</span>
						</td>
					</tr>
				</tbody>
			</v-simple-table>
		</v-tooltip>
		<v-alert v-if="porcentajeVolumen <= 90 && porcentajeVolumen" type="warning">
			El pedido esta incompleto, {{ SumaVolumenM2 }}m2. Hace un total del
			<strong>{{ porcentajeVolumen }}%</strong> del camion.
		</v-alert>
		<v-alert v-if="porcentajeVolumen > 90 && porcentajeVolumen < 110" type="success">
			El pedido esta completo, {{ SumaVolumenM2 }}m2. Hace un total del
			<strong>{{ porcentajeVolumen }}%</strong> del camion.
		</v-alert>
		<v-alert v-if="porcentajeVolumen >= 110 && porcentajeVolumen" type="error">
			El pedido es superior a un trailer, {{ SumaVolumenM2 }}m2. Hace un total
			del <strong>{{ porcentajeVolumen }}%</strong> del camion.
		</v-alert>
		<v-card :loading="loaders.card">
			<v-container>
				<h2 class="mb-5 grey--text">Formulario Presupuesto</h2>
				<v-row>
					<v-col>
						<v-text-field label="Numero Pedido" readonly :value="pedido.numero ? pedido.numero : 'SN'"></v-text-field>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<InputDate v-model="presupuesto.fecha" read-only />
					</v-col>
					<v-col>
						<InputDate v-model="presupuesto.fechaExigida" label="Fecha Exigida" />
					</v-col>
				</v-row>

				<v-row>
					<v-col>
						<AutoCliente :clienteId="presupuesto.cliente.id" v-model="presupuesto.cliente" />
						<AutoDireccion :clienteId="presupuesto.cliente.id" :direccionId="pedido.direccion.id"
							v-model="presupuesto.direccion" />
						<v-switch label="Recogida propia" v-model="presupuesto.recogidaPropia"></v-switch>
					</v-col>
				</v-row>

				<v-row v-for="(d, idx) in detalleCosteCalculado" :key="d.id">
					<v-col cols="2">
						<v-text-field v-model.number="presupuesto.detalle[idx].cantidad" label="Uds"
							@click:append="swapCompletar(idx)" :append-icon="
	presupuesto.detalle[idx].completar
		? presupuesto.detalle[idx].completar == 1
			? 'mdi-plus'
			: 'mdi-minus'
		: 'mdi-equal'
">
						</v-text-field>
					</v-col>
					<v-col>
						<InputReferencia :idx="idx" :codigo="d.referencia.codigo" @fetch="setDetalle" @close="removeDetalle(idx)"
							:clienteId="presupuesto.cliente.id" />
					</v-col>
					<v-col cols="2">
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-text-field v-on="on" type="number" step="any" label="Precio" :idx="idx"
									v-model="presupuesto.detalle[idx].precio" :error-messages="
	d.precio < d.precioConPorte
		? `El precio minimo del producto es ${d.precioConPorte.toFixed(
			2
		)}`
		: null
"></v-text-field>
							</template>
							<!-- Tooltip content -->
							<span v-if="presupuesto.detalle[idx].referencia.precio">
								<table class="text-right">
									<tr>
										<td class="text-left">Coste</td>
										<td>
											<span v-if="presupuesto.detalle[idx].referencia.precio.precio">{{
		presupuesto.detalle[
			idx
		].referencia.precio.precio
}}</span>
										</td>
									</tr>
									<tr>
										<td class="text-left">Coste+P</td>
										<td>
											<span v-if="d.precioConPorte">{{
		d.precioConPorte
}}</span>
										</td>
									</tr>
									<tr>
										<td class="text-left">Beneficio</td>
										<td>
											<span v-if="d.precio && d.precioConPorte">{{
		(d.precio - d.precioConPorte)
}}</span>
										</td>
									</tr>
									<tr v-if="
	d.referencia.queries &&
	d.referencia.queries.ultimoPrecioPresupuestado > 0
">
										<td class="text-left">U.Pres.</td>
										<td>
											<span>{{
		d.referencia.queries.ultimoPrecioPresupuestado
}}</span>
										</td>
									</tr>
									<tr v-if="
	d.referencia.queries &&
	d.referencia.queries.ultimoPrecioFacturado > 0
">
										<td class="text-left">U.Fact.</td>
										<td>
											<span>{{
		d.referencia.queries.ultimoPrecioFacturado
}}</span>
										</td>
									</tr>
								</table>
							</span>
						</v-tooltip>
					</v-col>
					<v-col cols="2">
						<v-text-field label="Precio Imp." readonly :value="d.precioConImpuesto">

						</v-text-field>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="text-center">
						<v-btn color="primary" fab small @click="addDetalle">
							<v-icon>mdi-plus</v-icon>
						</v-btn>
					</v-col>
				</v-row>
				<v-row>
					<v-spacer></v-spacer>
					<v-col cols="4">
						<v-text-field label="KGs manipulados" :value="kgsTotal" readonly>
						</v-text-field>
						<v-text-field type="number" label="IVA" v-model.number="presupuesto.iva"></v-text-field>
						<v-text-field type="number" label="Rec. Eq." v-model="presupuesto.recEq"></v-text-field>
						<v-text-field type="number" label="Descuento" v-model="presupuesto.descuento"></v-text-field>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-textarea label="Nota" v-model="presupuesto.nota"></v-textarea>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-btn color="primary" :disabled="$v.$invalid" block @click="crear" :loading="loaders.btn">Guardar
							presupuesto</v-btn>
					</v-col>
				</v-row>
			</v-container>
		</v-card>
	</v-container>
</template>

<script>
import _ from "lodash";

import { required, numeric } from "vuelidate/lib/validators";

import InputReferencia from "@/components/InputReferencia";
import InputDate from "@/components/InputDate";
import AutoCliente from "@/components/AutoCliente";
import AutoDireccion from "@/components/AutoDireccion";

import PedidoService from "@/services/pedido.service";
import PresupuestoService from "@/services/presupuesto.service";
import SnackbarService from "@/services/snackbar.service";

export default {
	name: "PresupuestoForm",

	components: {
		InputReferencia,
		InputDate,
		AutoCliente,
		AutoDireccion,
	},

	data() {
		return {
			pedido: {
				cliente: {},
				direccion: {},
				detalle: [],
			},

			presupuesto: {
				fecha: new Date(),
				fechaExigida: null,
				cliente: {},
				direccion: {},
				detalle: [],
				iva: 21,
				recEq: null,
				recogidaPropia: false,
				nota: null,
				descuento: null,
			},

			loaders: {
				btn: false,
				card: true,
			},
		};
	},

	methods: {
		async crear() {
			this.loaders.btn = true;

			// Formatear los datos segun el middleware de validacion de datos y schema del backend. Borrar datos que no se aceptan en el request
			const detalleReq = this.detalleCosteCalculado.map((detalle) => {
				return {
					beneficio: detalle.precio - detalle.precioConPorte,
					cantidad: detalle.cantidad,
					codigo: detalle.referencia.codigo,
					completar: detalle.completar,
					precio: detalle.precio,
					precioConPorte: detalle.precioConPorte,
					precioConImpuesto: detalle.precioConImpuesto,
					referenciaId: detalle.referenciaId,
					codeNormalizado: detalle.referencia.detalle.codeNormalizado
				};
			});

			await PresupuestoService.create({
				iva: this.presupuesto.iva,
				recEq: this.presupuesto.recEq,
				descuento: this.presupuesto.descuento,
				detalle: detalleReq,
				pedidoId: this.pedido.id,
				direccionId: this.presupuesto.direccion.id,
				clienteId: this.presupuesto.cliente.id,
				fechaExigida: this.presupuesto.fechaExigida,
				recogidaPropia: this.presupuesto.recogidaPropia,
				nota: this.presupuesto.nota,
				kgs_impuesto_especial: this.kgsTotal,
				importe_impuesto_especial: this.kgsTotal * 0.45
			});

			await SnackbarService.set({
				message: "Se ha creado el presupuesto correctamente",
			});
			this.$router.push({ name: "presupuestos.index" });
		},

		setDetalle(data) {
			const { idx } = data;
			this.presupuesto.detalle[idx].referencia = data;
		},

		swapCompletar(idx) {
			switch (this.presupuesto.detalle[idx].completar) {
				case 1:
					this.presupuesto.detalle[idx].completar = -1;
					break;
				case -1:
					this.presupuesto.detalle[idx].completar = 0;
					break;
				default:
					this.presupuesto.detalle[idx].completar = 1;
			}
		},

		removeDetalle(idx) {
			this.presupuesto.detalle.splice(idx, 1);
		},

		addDetalle() {
			this.presupuesto.detalle.push({
				cantidad: 0,
				precio: 0,
				referencia: {},
				completar: 0,
			});
		},
	},

	computed: {
		kgsTotal() {
			if (!this.presupuesto || !this.presupuesto.detalle || this.presupuesto.detalle.length < 1) return 0
			const total = this.presupuesto.detalle.reduce((totalKgs, detalle) => {
				// Excentos
				if (detalle?.referencia?.detalle?.excento) {
					return totalKgs
				}
				// No excentos
				if (detalle?.referencia?.detalle?.kgs) {
					return totalKgs + (detalle.cantidad * detalle.referencia.detalle.kgs)
				} else {
					return totalKgs
				}
			}, 0)

			return total
		},

		BeneficioTotal() {
			if (this.detalleCosteCalculado.length < 1) return 0;

			let costeTransporte
			if (!this.presupuesto.direccion.transporte && this.presupuesto.recogidaPropia) {
				costeTransporte = 0
			} else {
				costeTransporte = this.presupuesto.direccion.transporte?.precio || 0;
			}
			let total = this.detalleCosteCalculado.reduce((total, detalle) => {
				if (detalle.precio) {
					const suma = detalle.cantidad * detalle.precio;
					return total + suma;
				} else {
					return total
				}
			}, 0);
			let costeTotal = this.detalleCosteCalculado.reduce((total, detalle) => {
				if (!detalle.referencia.precio || !detalle.referencia.precio.precio) {
					return total
				} else {
					const sumaCoste = detalle.cantidad * detalle.referencia.precio?.precio;
					return total + sumaCoste;
				}
			}, 0);
			const coste = this.presupuesto.recogidaPropia
				? costeTotal
				: costeTotal + costeTransporte;

			const beneficio = total - coste;

			if (isNaN(beneficio)) return 0;
			return beneficio;
		},

		SumaVolumenM2() {
			// Si no hay referencias
			if (this.presupuesto.detalle.length < 1) return 0;
			// Reducer
			return this.presupuesto.detalle.reduce((total, detalle) => {
				// Si la referencia iterada no es correcta (El objeto esta vacio),no se puede calcular su volumen. Pasar a la siguiente.
				if (Object.entries(detalle.referencia).length === 0) return total;
				// Suma total de volumenes
				if (!detalle.referencia.volumen) return 0;
				return total + detalle.cantidad * detalle.referencia.volumen.volumen;
			}, 0);
		},

		porcentajeVolumen() {
			const m2Trailer = 22_000;
			return parseInt((this.SumaVolumenM2 / m2Trailer) * 100);
		},

		checkTransporteGiven() {
			if ((!this.presupuesto.direccion || !this.presupuesto.direccion.transporte) && !this.presupuesto.recogidaPropia)
				return false;
			return true;
		},

		detalleCosteCalculado() {

			const detalle = this.presupuesto.detalle.map(detalle => {
				let precioConImpuesto;
				if (detalle?.referencia?.detalle && detalle?.referencia?.detalle?.excento == false && detalle.precio && detalle.referencia.detalle.kgs) {
					precioConImpuesto = detalle.referencia.detalle.kgs * 0.45 + Number(detalle.precio)
				} else {
					precioConImpuesto = detalle.precio
				}
				return {
					...detalle,
					precioConImpuesto
				}
			})

			// El coste de transporte se saca de la direccion
			if (!this.presupuesto.direccion || !this.presupuesto.direccion.transporte)
				return detalle;
			// Calcular el coste de transporte por m2 y aplicar la subida sobre el precio para tener precioCoste+precioTransporte
			let costeTransporte = this.presupuesto.direccion.transporte.precio;
			if (this.presupuesto.recogidaPropia) {
				costeTransporte = 0;
			}
			const m2CosteTransporte = costeTransporte / 22_000; // 22_000m2 por trailer
			return detalle.map((detalle) => {
				if (!detalle.referencia) return detalle;
				if (
					!detalle.referencia.detalle ||
					detalle.referencia.error ||
					!detalle.referencia.volumen
				)
					return detalle;
				const sobreCosteTransporte =
					detalle.referencia.volumen.volumen * m2CosteTransporte;
				return {
					...detalle,
					precioConPorte:
						detalle.referencia.precio.precio + sobreCosteTransporte,
				};
			});
		},
	},

	validations: {
		presupuesto: {
			cliente: { required },
			direccion: { required },
			detalle: {
				required,
				$each: {
					cantidad: { required, numeric },
					referencia: { detalle: { codeNormalizado: { required } } },
				},
			},
			iva: { required, numeric },
		},
	},

	async created() {
		// Params ruta
		const { pedidoId, presupuestoId } = this.$route.params;

		// Comprobar si la ruta trae un presupuestoID. Si lo trae, poblar el formulario con sus datos.
		if (presupuestoId) {
			const presupuestoFetch = await PresupuestoService.findByPk(presupuestoId);
			this.presupuesto = presupuestoFetch;
			this.presupuesto.detalle = presupuestoFetch.detalle;
			return (this.loaders.card = false);
		}

		if (!pedidoId) return (this.loaders.card = false);
		this.pedido = await PedidoService.findByPk(pedidoId);
		this.presupuesto = _.cloneDeep(this.pedido);
		if (this.pedido.fecha) this.presupuesto.fecha = new Date(this.pedido.fecha);
		if (this.pedido.fechaExigida)
			this.presupuesto.fechaExigida = new Date(this.pedido.fechaExigida);
		if (this.pedido.detalles) this.presupuesto.detalle = this.pedido.detalles;

		this.loaders.card = false;
	},
};
</script>