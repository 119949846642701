<template>
  <v-container>
    <v-row>
      <v-col>
        <router-link :to="{name:'usuarios.index'}">Volver a usuarios</router-link>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
    <v-form>
      <v-card>
        <v-container>
          <v-row>
            <v-col>
              <h2>Roles usuario: {{usuario.email}}</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <router-link :to="{name:'usuarios.editar',params:{id:usuario._id}}">Editar datos</router-link>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-for="rol in roles" :key="rol.id" cols="4">
              <v-switch  :label="rol.name" v-model="rol.seleccionado"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn color="primary" dark block @click="actualizarRolesUsuario">Guardar</v-btn>
            </v-col>
          </v-row>
        
        </v-container>
      </v-card>
    </v-form>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UsuarioService from '@/services/usuario.service'
import SnackbarService from '@/services/snackbar.service'

export default {
  name: "UsuariosUpdate",

  data() {
    return {
      roles: [],
      usuario: {},
    };
  },

  computed:{
    rolesSeleccionados(){
      let roles = this.roles.filter(rol=>rol.seleccionado)
      roles = roles.map(rol=>{
        delete rol.seleccionado
        return rol
      })
      return roles
    }
  },

  async created(){
    const {id} = this.$route.params

    await UsuarioService.getUsuario(id)
      .then(usuario=>this.usuario = usuario)
      .catch(error=>console.error(error))
    
    await UsuarioService.getRoles()
      .then(roles=>this.roles = roles)
      .catch(error=>console.error(error))


    // Roles que trae el usuario_________
    const UsuarioRolsNames = await this.usuario.rols.reduce((arrayRolsNames,rol)=>{
      arrayRolsNames.push(rol.name)
      return arrayRolsNames
    },[])

    this.roles = this.roles.map(rol=>{
      if(UsuarioRolsNames.includes(rol.name)) rol.seleccionado = true
      else rol.seleccionado = false
      return rol
    })
  },

  methods:{
    actualizarRolesUsuario(){
      UsuarioService.actualizarRolesUsuario(this.usuario._id,this.rolesSeleccionados)
        .then(()=>{
          SnackbarService.set({
            message:'Se han actualizado los roles del usuario'
          })
        })
        .then(()=>this.$router.push({name:'usuarios.index'}))
        .catch(error=>console.error(error))
    }
  }

};
</script>