import AuthService from "@/services/auth.service";

// import HistorialIndex from '@/views/historial/HistorialIndex'

const rutasReferencias = [
  // {
  //   path:'/historial',
  //   component:HistorialIndex,
  //   name:'historial.index',
  // },
];

export default rutasReferencias.map((route) => {
  // Agregar a cada ruta un Guard Before Enter para comprobar si el usuario autenticado tiene el rol
  return {
    ...route,
    beforeEnter: (to, from, next) => {
      if (AuthService.user().roles.includes("historial")) next();
      else next({ name: "Error404View" });
    },
  };
});
