import AuthService from '@/services/auth.service'

import ComprasIndex from '@/views/compras/ComprasIndex'
import ComprasForm from '@/views/compras/ComprasForm'

const rutasFacturas = [
  {
    path:'/compras',
    component:ComprasIndex,
    name:'compras.index',
  },
  {
    path:'/compras/formulario/:id?',
    component:ComprasForm,
    name:'compras.formulario',
  },

]

export default rutasFacturas.map(route=>{
  // Agregar a cada ruta un Guard Before Enter para comprobar si el usuario autenticado tiene el rol
  return{
    ...route,
    beforeEnter:(to,from,next)=>{
      if(AuthService.user().roles.includes('compras')) next()
      else next({name:'Error404View'})
    }
  } 
})