<template>
  <v-container>
    <v-card>
      <v-container>
        <v-form>
          <p>Datos facturacion</p>
          <v-text-field
            label="Nombre"
            v-model.trim="cliente.nombre"
            :error-messages="$v.cliente.nombre.$invalid?'El nombre es obligatorio':null"
          ></v-text-field>
          <v-text-field label="Cif" v-model.trim="cliente.cif" :error-messages="$v.cliente.cif.$invalid?'El CIF es obligatorio':null"></v-text-field>
          <v-text-field
            label="Direccion"
            v-model.trim="cliente.direccion"
            :error-messages="$v.cliente.direccion.$invalid?'La direccion es obligatoria':null"
          ></v-text-field>
          <v-text-field
            label="Localidad"
            v-model.trim="cliente.localidad"
            :error-messages="$v.cliente.localidad.$invalid?'La localidad es obligatoria':null"
          ></v-text-field>
          <p class="mt-10">Datos de pago</p>
          <v-select
            label="Categoria"
            :items="categorias"
            v-model="cliente.categoriaId"
            item-text="nombre"
            item-value="id"
            :error-messages="$v.cliente.categoriaId.$invalid?'La categoria de cliente es obligatoria':null"
          ></v-select>
          <v-select
            label="Forma de pago"
            :items="pagos"
            v-model="cliente.banco.pagoId"
            item-text="nombre"
            item-value="id"
            :error-messages="$v.cliente.banco.pagoId.$invalid?'La forma de pago es obligatoria':null"
          ></v-select>
          <v-text-field
            label="Vencimiento"
            type="number"
            v-model.number="cliente.banco.vencimiento"
          ></v-text-field>
          <v-text-field
            label="Dia de pago"
            type="number"
            v-model.number="cliente.banco.dia"
          ></v-text-field>
          <v-text-field
            label="Cuenta corriente"
            v-model.number="cliente.banco.banco"
          ></v-text-field>
          <v-btn
            class="mt-10"
            v-if="!cliente.id"
            @click="crear"
            color="primary"
            block
            :disabled="$v.$invalid"
            >Crear cliente</v-btn
          >
          <v-btn
            class="mt-10"
            v-else
            @click="actualizar"
            color="primary"
            block
            :disabled="$v.$invalid"
            >Actualizar cliente</v-btn
          >
        </v-form>
        <pre>{{ cliente.diasDePago }}</pre>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import { required, numeric } from "vuelidate/lib/validators";

import ClienteService from "@/services/cliente.service";
import SnackbarService from "@/services/snackbar.service";

export default {
  name: "ClientesForm",

  data() {
    return {
      cliente: { banco: {} },
      categorias: [],
      pagos: [],
    };
  },

  methods: {
    async crear() {
      const cliente = await ClienteService.create({
        nombre: this.cliente.nombre,
        cif: this.cliente.cif,
        direccion: this.cliente.direccion,
        localidad: this.cliente.localidad,
        categoriaId: this.cliente.categoriaId,
        banco: this.cliente.banco,
      });
      SnackbarService.set({ message: "Se ha creado el cliente" });
      this.$router.push({ name: "clientes.show", params: { id: cliente.id } });
    },

    async actualizar() {
      await ClienteService.update(this.cliente.id, {
        nombre: this.cliente.nombre,
        cif: this.cliente.cif,
        direccion: this.cliente.direccion,
        localidad: this.cliente.localidad,
        categoriaId: this.cliente.categoriaId,
        banco: this.cliente.banco,
      });
      SnackbarService.set({ message: "Se ha actualizado el cliente" });
      this.$router.push({
        name: "clientes.show",
        params: { id: this.cliente.id },
      });
    },
  },

  async created() {
    if (this.$route.params.id) {
      const clienteId = this.$route.params.id;
      this.cliente = await ClienteService.findByPk(clienteId);
      if (!this.cliente.banco) {
        this.cliente.banco = {};
      }
    }

    this.pagos = await ClienteService.findPagos();
    this.categorias = await ClienteService.getCategorias();
  },

  validations: {
    cliente: {
      nombre: {required},
      cif: {required},
      direccion: {required},
      localidad: {required},
        categoriaId:{required,numeric},
      banco:{
        pagoId:{required,numeric},
      }
    },
  },
};
</script>