import axios from '@/axios.config'

class AbonoService {

  static findByPk(facturaId,id){
    return axios.get(`/api/facturas/${facturaId}/abono/${id}`)
      .then(res=>res.data)
  }

  static create(facturaId,data){
    return axios.post(`/api/facturas/${facturaId}/abono`,data)
      .then(res=>res.data)
  }

  static update(facturaId,abonoId,data){
    return axios.put(`/api/facturas/${facturaId}/abono/${abonoId}`,data)
      .then(res=>res.data)
  }

  static delete(facturaId,abonoId){
    return axios.delete(`/api/facturas/${facturaId}/abono/${abonoId}`)
      .then(res=>res.data)
  }

  static downloadPDF(facturaId,abonoId){
    return axios.get(`/api/facturas/${facturaId}/abono/${abonoId}/pdf`,{responseType:'blob'})
    .then(response=>{
      window.open(URL.createObjectURL(response.data));
    })
    .catch(err=>{
      alert(err)
    })
  }


  static exportar(fechaLimite){
    return axios.get(`/api/facturas/exportar-abonos`,{params:{fechaLimite},responseType:'blob'})
      .then(res=>{
        // create file link in browser's memory
        const href = URL.createObjectURL(res.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `abonos_venta_${new Date().toLocaleDateString()}.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);

        return res.data
      })
      .catch(error=>{
        alert(error)
        return error
      })
  }


  static importarPresentadas(arrayNumerosFacturasPresentadas){
    return axios.post('/api/facturas/importar-presentadas-abonos', arrayNumerosFacturasPresentadas)
      .then(res=>res.data)
  }

}

export default AbonoService