import AuthService from '@/services/auth.service'

import UsuariosIndex from '@/views/usuarios/UsuariosIndex'
import UsuariosForm from '@/views/usuarios/UsuariosForm'
import UsuariosRoles from '@/views/usuarios/UsuariosRoles'

const rutasUsuarios = [
  {
    path:'/usuarios',
    component:UsuariosIndex,
    name:'usuarios.index',
  },
  {
    path:'/usuarios/crear',
    component:UsuariosForm,
    name: 'usuarios.crear',    
  },
  {
    path:'/usuarios/editar',
    component:UsuariosForm,
    name: 'usuarios.editar'
  },
  {
    path:'/usuarios/roles/:id',
    component:UsuariosRoles,
    name: 'usuarios.roles',    
  }
]

export default rutasUsuarios.map(route=>{
  // Agregar a cada ruta un Guard Before Enter para comprobar si el usuario autenticado tiene el rol
  return{
    ...route,
    beforeEnter:(to,from,next)=>{
      if(AuthService.user().roles.includes('usuarios')) next()
      else next({name:'Error404View'})
    }
  } 
})