<template>
  <v-dialog @click:outside="$emit('close')" :value="factura" max-width="600px">
    <v-card v-if="factura">
      <v-card-title class="mb-5">
        {{ factura.numero }}
      </v-card-title>
      <v-card-text>
        <InputDate v-model="fecha" label="Fecha de pago" />
        <v-text-field
          v-model.number="importe"
          label="Importe"
          type="number"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" block @click="marcarPagada">Guardar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";

import InputDate from "@/components/InputDate";

import FacturaService from "@/services/factura.service";

export default {
  name: "FacturasModal",

  props: ["factura"],

  components: { InputDate },

  data() {
    return {
      datos: {},
      fecha: null,
      importe: null,
    };
  },

  watch: {
    factura() {
      if(!this.factura) return false
      this.datos = _.cloneDeep(this.factura);
      this.fecha = this.factura.fechaPago ? new Date(this.factura.fechaPago) : null
      this.importe = this.factura.importePagado ? this.factura.importePagado : null
    },
  },

  methods: {
    async marcarPagada() {
      await FacturaService.marcarPagada(this.datos.id,{
        id: this.datos.id,
        fechaPago: this.fecha,
        importePagado: this.importe,
      })
      this.$emit("close");
    },
  },

};
</script>

<style>
</style>