<template>
	<v-container>
		<v-row>
			<v-col>
					<v-container>
						<v-row>
							<v-col cols="5" label="Usuario">
								<v-select v-model="selectedUser" return-object item-text="email" :items="usuarios"></v-select>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-date-picker range v-model="dates"></v-date-picker>
							</v-col>
						</v-row>
					</v-container>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-card :loading="loading">
					<v-card-title v-if="selectedUser">Datos usuario: {{ selectedUser.email }}</v-card-title>
					<v-container>
						<v-row>
							<v-col>Desde: {{ dates[0] | fecha }}</v-col><v-col>Hasta: {{ dates[1] | fecha}}</v-col>
						</v-row>
						<v-row>
							<v-col>
								<h2>Horas totales: {{ sumaHoras }}</h2>
							</v-col>
						</v-row>
					</v-container>
					<v-data-table
						:headers="headers"
						:items="formatedRegistros"
						:items-per-page="-1"
						no-data-text="No existen datos"
					>
					<template v-slot:[`item.entrada`]="{item}">
						{{ item.entrada | datetime }}
					</template>
					<template v-slot:[`item.salida`]="{item}">
						{{ item.salida | datetime }}
					</template>
				</v-data-table>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import UsuarioService from '@/services/usuario.service'
	import HorasService from '@/services/horas.service'
	import dayjs from 'dayjs'

	export default {
		name: 'HorasConsulta',
		data(){
			return{
				dates:[],
				usuarios:[],
				selectedUser:null,
				registros:[],
				loading: false,

				headers:[
					{ value: 'entrada' },
					{value:'salida'},
					{ value: 'diferencia' },
				]
			}
		},

		methods:{
			async fetchData(){
				this.loading = true
				try {
					const res = await HorasService.consulta(this.selectedUser._id, this.dates[0], this.dates[1])
					this.registros = res.data
				} catch (error) {
					console.log(error)
				} finally{
					this.loading = false
				}
				
			}
		},

		computed:{
			formatedRegistros(){
				return this.registros.map(registro=>{
					const date1 = dayjs(registro.entrada)
					const date2 = dayjs(registro.salida)
					const diferencia = date2.diff(date1, 'hours')

					return {
						...registro,
						diferencia
					}
				})
			},

			sumaHoras(){
				return this.formatedRegistros.reduce((suma,registro)=>{
					return suma + registro.diferencia
				},0)
			}
		},

		async created(){
			this.usuarios = await UsuarioService.getUsuarios()
		},

		watch:{
			async selectedUser(){
				await this.fetchData()
			},

			dates:{
				deep:true,
				async handler(){
					await this.fetchData()
				}
			}
		}

	}
</script>