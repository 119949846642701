import axios from "@/axios.config";

class VariableService {
  static findAll() {
    return axios.get("/api/variables").then((res) => res.data);
  }

  static updateAll(data) {
    return axios.put("/api/variables", data).then((res) => res.data);
  }

  static findAllProduccion() {
    return axios.get("/api/variables/produccion").then((res) => res.data);
  }

  static updateAllProdccion(data) {
    return axios.put("/api/variables/produccion", data).then((res) => res.data);
  }
}

export default VariableService;
