<template>
  <v-container>
    <v-card :loading="loaders.card">
      <v-card-title>Albaran</v-card-title>
      <v-card-actions v-if="albaran.id">
        <v-btn
          outlined
          :loading="loaders.btn"
          @click="downloadPDF"
          color="primary"
          small
          >pdf</v-btn
        >
        <v-btn
          outlined
          color="primary"
          small
          :to="{ name: 'albaranes.cmr', params: { id: albaran.id } }"
          >CMR</v-btn
        >
        <v-btn
          outlined
          color="primary"
          small
          :to="{ name: 'albaranes.form', params: { id: albaran.id } }"
          >Editar</v-btn
        >
      </v-card-actions>
      <v-card-text v-if="albaran.cliente">
        <p>
          Numero: {{ albaran.numero }}
          <v-chip small label color="error" v-if="albaran.anulado"
            >Anulado</v-chip
          >
        </p>
        <p>Fecha: {{ albaran.createdAt | fecha }}</p>
        <p>Cliente: {{ albaran.cliente.nombre }}</p>
        <p>
          Lugar de entrega: {{ albaran.direccion.nombre }}
          <v-chip v-if="albaran.recogidaPropia" small color="primary"
            >Recogida propia</v-chip
          >
        </p>
        <p>Creado por: {{ albaran.usuario.mail }}</p>
        <p>
          Presupuestos:
          <router-link
            v-for="presupuesto in albaran.presupuestos"
            :key="presupuesto.id"
            :to="{ name: 'presupuestos.show', params: { id: presupuesto.id } }"
            >{{ presupuesto.numero }}</router-link
          >
        </p>
        <p>
          Factura:
          <span v-if="albaran.factura"
            ><router-link
              :to="{
                name: 'facturas.show',
                params: { id: albaran.factura.id },
              }"
              >{{ albaran.factura.numero }}</router-link
            ></span
          ><span v-else-if="albaran.anulado">Anulado</span
          ><span v-else>Pendiente facturar</span>
        </p>

        <v-divider class="my-5"></v-divider>

        <table>
          <thead>
            <th class="text-center pr-3">Uds</th>
            <th class="text-left">Referencia</th>
          </thead>
          <tbody>
            <tr v-for="detalle in albaran.detalle" :key="detalle.id">
              <td class="text-right pr-3">{{ detalle.cantidad }}</td>
              <td>{{ detalle.referencia.nombre }}</td>
            </tr>
          </tbody>
        </table>
        <div v-if="albaran.nota">
          <p class="mt-5">Notas:</p>
          <p>{{ albaran.nota }}</p>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import AlbaranService from "@/services/albaran.service";

export default {
  name: "AlbaranesShow",

  data() {
    return {
      albaran: {},
      loaders: {
        btn: false,
        card: true,
      },
    };
  },

  async created() {
    const { id } = this.$route.params;
    if (!id) return false;
    this.albaran = await AlbaranService.findByPk(id);
    this.loaders.card = false;
  },

  // computed: {
  //   presupuestos() {
  //     if (!this.albaran) return false;
  //     let arrayPresupuestos = [];
  //     this.albaran.detalle.forEach((detalle) => {
  //       if (!detalle.fabricacion || !detalle.fabricacion.presupuestoDetalle)
  //         return false;
  //       // const numero = detalle.fabricacion.detalle.presupuesto.numero
  //       arrayPresupuestos.push({
  //         numero: detalle.fabricacion.presupuestoDetalle.presupuesto.numero,
  //         id: detalle.fabricacion.presupuestoDetalle.presupuesto.id,
  //       });
  //     });
  //     arrayPresupuestos.filter((presupuesto) => !!presupuesto);
  //     return [
  //       ...new Map(
  //         arrayPresupuestos.map((item, key) => [item[key], item])
  //       ).values(),
  //     ];
  //   },
  // },

  methods: {
    async downloadPDF() {
      this.loaders.btn = true;
      await AlbaranService.downloadPDF(this.albaran.id);
      this.loaders.btn = false;
    },
  },
};
</script>