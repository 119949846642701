import axios from '@/axios.config'

class ReferenciaService {
  static create(data){
    return axios.post('/api/referencias-especiales',data)
      .then(res=>res.data)
  }

  static findAll(){
    return axios.get('/api/referencias-especiales')
      .then(res=>res.data)
  }

  static findByPk(id){
    return axios.get(`/api/referencias-especiales/${id}`)
      .then(res=>res.data)
  }

  static update(id, data){
    return axios.put(`/api/referencias-especiales/${id}`, data)
    .then(res=>res.data)
  }
  
}

export default ReferenciaService