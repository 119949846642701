import AuthService from '@/services/auth.service'

import ReferenciasIndex from '@/views/referencias/ReferenciasIndex'
import ReferenciasCreate from '@/views/referencias/ReferenciasCreate'
import ReferenciasEdit from '@/views/referencias/ReferenciasEdit'
import ReferenciasEspecialesForm from '@/views/referencias/ReferenciasEspecialesForm'

const rutasReferencias = [
  {
    path:'/referencias',
    component:ReferenciasIndex,
    name:'referencias.index',
  },
  {
    path:'/referencias/crear',
    component: ReferenciasCreate,
    name:'referencias.crear'
  },
  {
    path:'/referencias/:id',
    component: ReferenciasEdit,
    name:'referencias.editar'
  },
  {
    path:'/referencias/especiales/:id?',
    component: ReferenciasEspecialesForm,
    name:'referencias.especiales.form'
  },
]

export default rutasReferencias.map(route=>{
  // Agregar a cada ruta un Guard Before Enter para comprobar si el usuario autenticado tiene el rol
  return{
    ...route,
    beforeEnter:(to,from,next)=>{
      if(AuthService.user().roles.includes('referencias')) next()
      else next({name:'Error404View'})
    }
  } 
})