<template>
  <v-container>
    <v-card>
      <v-container>
    <v-form>
      <v-text-field v-model.trim="transporte.localidad" label="localidad"></v-text-field>
      <v-text-field v-model.trim="transporte.proveedor" label="proveedor"></v-text-field>
      <v-text-field v-model.number="transporte.precio" type="number" label="precio"></v-text-field>
      <v-btn v-if="!transporte.id" color="primary" block @click="crear">Crear transporte</v-btn>
      <v-btn v-else color="primary" block @click="actualizar">Actualizar transporte</v-btn>
    </v-form>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import ClienteService from '@/services/cliente.service'
import SnackbarService from '@/services/snackbar.service'

export default {
  name:'CostesTransportesForm',

  data(){
    return{
      transporte:{
        id:null,
        localidad:'',
        proveedor:'',
        precio:0,
      }
    }
  },

  methods:{
    async crear(){
      await ClienteService.createTransporte(this.transporte)
      await SnackbarService.set({message:'Se ha creado un transporte nuevo'})
      this.$router.push({name:'costes.transportes'})
    },
    async actualizar(){
      await ClienteService.updateTransporte(this.transporte.id,this.transporte)
      await SnackbarService.set({message:'Se ha actualizado un transporte nuevo'})
      this.$router.push({name:'costes.transportes'})
    }
  },

  async created(){
    const {id} = this.$route.params
    if(!id) return false
    this.transporte = await ClienteService.getTransporte(id)
  }
}
</script>