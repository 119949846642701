import store from '@/store'

class DataService {
  static setData(data){
    store.commit('data/setData', data)
  }

  static unsetData(){
    store.commit('data/unsetData')
  }

  static getData(){
    return store.getters['data/getData']
  }
}

export default DataService