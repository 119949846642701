<template>
  <div>
    <h3>Error 404</h3>
    <p>El recurso no se ha encontrado o no existe</p>
    <router-link :to="{name:'home'}">Volver al inicio</router-link>
  </div>
</template>

<script>
export default {
  name:'Error404View'
}
</script>