<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Recibos Index</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn color="primary" :to="{ name: 'banco.recibos.crear' }"
          >Crear recibo</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          dense
          :headers="headers"
          :items="recibos"
          @click:row="editarRecibo"
          sorty-by="fechaVencimiento"
          :sort-desc="true"
        >
          <template v-slot:[`item.fechaVencimiento`]="{ item }">{{
            item.fechaVencimiento | fecha
          }}</template>
          <template v-slot:[`item.estado.estado`]="{ item }">
            <v-chip
              label
              small
              v-if="item.estado.estado == 'Cobrado'"
              color="success"
              >Cobrado</v-chip
            >
            <v-chip
              label
              small
              v-if="item.estado.estado == 'Pendiente Fecha'"
              color="gray"
              >Pendiente Fecha</v-chip
            >
            <v-chip
              label
              small
              v-if="item.estado.estado == 'Pendiente Banco'"
              color="warning"
              >Pendiente Banco</v-chip
            >
            <v-chip
              label
              small
              v-if="item.estado.estado == 'Devuelto'"
              color="red lighten-3 white--text"
              >Devuelto</v-chip
            >
            
            
            
            </template
          >
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ReciboService from "@/services/banco/recibo.service";

export default {
  name: "RecibosIndex",

  data() {
    return {
      headers: [
        { text: "Id", value: "id" },
        { text: "Factura", value: "factura.numero" },
        { text: "Cliente", value: "factura.cliente.nombre" },
        { text: "Importe", value: "importe" },
        { text: "Estado", value: "estado.estado" },
        { text: "Fecha Vencimiento", value: "fechaVencimiento" },
      ],
      recibos: [],
    };
  },

  async created() {
    const res = await ReciboService.findAll();
    this.recibos = res;
  },

  methods: {
    editarRecibo(recibo) {
      this.$router.push({
        name: "banco.recibos.editar",
        params: { id: recibo.id },
      });
    },
  },
};
</script>