import Vue from "vue";
import VueRouter from "vue-router";

import AuthService from "@/services/auth.service";

import HorasIndex from '@/views/horas/HorasIndex'

import AuthRoutes from "@/router/auth.routes";
import UsuariosRoutes from "@/router/usuarios.routes";
import CostesRoutes from "@/router/costes.routes";
import ClientesRoutes from "@/router/clientes.routes";
import ReferenciasRoutes from "@/router/referencias.routes";
import PedidosRoutes from "@/router/pedidos.routes";
import PresupuestosRoutes from "@/router/presupuestos.routes";
import FabricacionRoutes from "@/router/fabricacion.routes";
import AlbaranesRoutes from "@/router/albaranes.routes";
import FacturasRoutes from "@/router/facturas.routes";
import ComprasRoutes from "@/router/compras.routes";
import ProveedoresRoutes from "@/router/proveedores.routes";
import HerramientasRoutes from "@/router/herramientas.routes";
import HistorialRoutes from "@/router/historial.routes";
import BancoRoutes from "@/router/banco.routes";
import ConsultasRoutes from "@/router/consultas.routes";
import FeatureRoutes from "@/router/features.routes"
import HorasRoutes from '@/router/horas.routes'
import ProduccionRoutes from '@/router/produccion.routes'

import Error404View from "@/views/errors/Error404View";

Vue.use(VueRouter);

const routes = [
  // Ruta base
  {
    path: "/home",
    name: "home",
    component: HorasIndex
  },

  // Otros ruters complejos
  ...AuthRoutes,
  ...UsuariosRoutes,
  ...CostesRoutes,
  ...ClientesRoutes,
  ...ReferenciasRoutes,
  ...PedidosRoutes,
  ...PresupuestosRoutes,
  ...FabricacionRoutes,
  ...AlbaranesRoutes,
  ...FacturasRoutes,
  ...HerramientasRoutes,
  ...HistorialRoutes,
  ...ComprasRoutes,
  ...ProveedoresRoutes,
  ...BancoRoutes,
  ...ConsultasRoutes,
  ...FeatureRoutes,
  ...HorasRoutes,
  ...ProduccionRoutes,
  
  // Alias
  {
    path:'/',
    redirect:'/home'
  },

  // Errores
  {
    path: "*",
    name: "Error404View",
    component: Error404View,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// Navigation guard for every route except Login
router.beforeEach((to, from, next) => {
  // Checkif token is not expired
  if (
    to.name !== "auth.login" &&
    to.name !== "auth.jwt" &&
    AuthService.tokenNotExpired()
  ) {
    // Si no hay user hacer logout
    next();
  } else {
    // Check if user is loged and has token
    if (
      to.name !== "auth.login" &&
      to.name !== "auth.jwt" &&
      (!AuthService.user() || !AuthService.user().token)
    ) {
      next({ name: "auth.login" });
    } else {
      next();
    }
  }
});

export default router;
