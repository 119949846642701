import store from '@/store'

class SnackbarService{
  static get(){
    return store.getters['snackbar/getSnackbar']
  }

  static set(snackbarData){
    store.commit('snackbar/setSnackbar',snackbarData)
  }

  static unset(){
    store.commit('snackbar/unsetSnackbar')
  }
}

export default SnackbarService