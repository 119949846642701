<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :return-value.sync="dateValue"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        dense
        v-model="formated"
        prepend-inner-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        :label="label"
        :append-icon="readOnly ? null : 'mdi-close'"
        @click:append="readOnly ? null : clearDateValue"
      ></v-text-field>
    </template>
    <v-date-picker
      :disabled="readOnly"
      :value="formated"
      @input="setDateValue"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "DateSelect",

  // Incoming value must be JS Date
  props: {
    value: {},
    label: {
      type: String,
      default: "Fecha",
    },
    readOnly: {
      type: Boolean,
    },
  },

  // Working with JS Date
  data() {
    return {
      dateValue: this.value,
      menu: false,
    };
  },

  // v-data-picker works with (YYYY-MM-DD) Format. SO must be tranformed to pass as prop
  computed: {
    formated() {
      if (!this.value) return null;
      let valorParseado;
      if (typeof this.value === "string") {
        valorParseado = new Date(this.value);
      } else {
        valorParseado = this.value;
      }
      return valorParseado.toISOString().substr(0, 10);
    },
  },

  // The returned value from v-data-picker is a string in YYYY-MM-DD format. Transform to JS data and $emit the date to parent.
  methods: {
    setDateValue(val) {
      this.$emit("input", new Date(val));
      this.dateValue = new Date(val);
    },

    clearDateValue() {
      this.$emit("input", null);
      this.dateValue = null;
    },
  },
};
</script>