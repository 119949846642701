var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('h1',{staticClass:"subtitle-1 grey--text"},[_vm._v("Pedidos")])])],1),_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"color":"primary","dark":"","to":{ name: 'pedidos.crear' }}},[_vm._v("crear pedido")])],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"solo":"","label":"Filtro","placeholder":"Insertar nombre de cliente a buscar"},model:{value:(_vm.filtroCliente),callback:function ($$v) {_vm.filtroCliente=$$v},expression:"filtroCliente"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-switch',{attrs:{"label":"Ver solo pendientes"},model:{value:(_vm.soloPendientes),callback:function ($$v) {_vm.soloPendientes=$$v},expression:"soloPendientes"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.pedidos,"server-items-length":_vm.totalPedidos,"options":_vm.options,"footer-props":_vm.footerProps,"dense":"","disable-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.numero",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({on:{"mouseover":function($event){return _vm.fetchPedido(item.id)},"mouseleave":function($event){return _vm.fetchPedido(null)}}},on),[_c('router-link',{attrs:{"to":{ name: 'pedidos.show', params: { id: item.id } }}},[_vm._v(_vm._s(item.numero || "SN"))])],1)]}}],null,true)},[_c('table',[_c('thead',[_c('th',{staticClass:"text-left"},[_vm._v("Cantidad")]),_c('th',{staticClass:"text-left pl-5"},[_vm._v("Referencia")]),_c('th',{staticClass:"text-right px-5"},[_vm._v("Precio")]),_c('th',{staticClass:"text-right"},[_vm._v("Total")])]),(_vm.pedidoFetched)?_c('tbody',_vm._l((_vm.pedidoFetched.detalle),function(detalle){return _c('tr',{key:detalle.id},[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(detalle.cantidad))]),_c('td',{staticClass:"text-left pl-5"},[_vm._v(" "+_vm._s(detalle.referencia.nombre)+" ")]),_c('td',{staticClass:"text-right px-5"},[_vm._v(_vm._s(detalle.precio))]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s((detalle.precio * detalle.cantidad).toFixed(2))+" ")])])}),0):_c('tbody',[_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"4"}},[_vm._v("Cargando...")])])])])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.presupuestos.length > 0)?_c('span',_vm._l((item.presupuestos),function(presupuesto){return _c('v-chip',{key:presupuesto.id,attrs:{"label":"","small":""}},[_c('router-link',{attrs:{"to":{
                  name: 'presupuestos.show',
                  params: { id: presupuesto.id },
                }}},[_vm._v(_vm._s(presupuesto.numero))])],1)}),1):_c('v-btn',{attrs:{"color":"success","x-small":"","to":{
              name: 'presupuestos.crear',
              params: { pedidoId: item.id },
            }}},[_vm._v(" Presupuestar ")])]}},{key:"item.fecha",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("fecha")(item.fecha))+" ")]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }