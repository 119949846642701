<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>BancoIndex</h1>
      </v-col>
    </v-row>
    <v-row>
      <ul>
        <li><router-link :to="{name:'banco.recibos.index'}">Recibos</router-link></li>
      </ul>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'BancoIndex'
}
</script>