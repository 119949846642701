<template>
  <v-autocomplete v-model="selectedProveedor" :items="proveedores" item-text="nombre" return-object label="Proveedor" append-icon="mdi-close" @click:append="selectedProveedor={}"/>
</template>

<script>
import ProveedorService from '@/services/proveedor.service'

export default {
  name:'AutoProveedor',

  props:['proveedorId'],

  data(){
    return{
      proveedores:[],
      selectedProveedor:{},
    }
  },

  watch:{
    selectedProveedor(){
      this.$emit('input',this.selectedProveedor)
    },

    async proveedorId(){
      if(this.proveedorId){
        this.selectedProveedor = await ProveedorService.findByPk(this.proveedorId)
      }
    }
  },

  async created(){
    const res = await ProveedorService.findAll()
    this.proveedores = res.rows
    if(this.proveedorId){
      this.selectedProveedor = await ProveedorService.findByPk(this.proveedorId)
    }
  }
}
</script>