<template>
  <v-container>
    <v-card>
      <v-form>
        <v-container>
          <v-text-field
            @input="nombreReferencia"
            label="Referencia"
            v-model.trim="$v.codigo.$model"
            :error-messages="nombre ? nombre.message : null"
            :messages="nombre ? nombre.nombre : null"
          ></v-text-field>
          <v-text-field label="Stock" v-model.number="$v.stock.$model" type="number"></v-text-field>
          <v-textarea label="Nota" v-model="nota"></v-textarea>
          <v-btn @click="crear" color="primary" block :disabled="$v.$invalid">Crear referencia</v-btn>
        </v-container>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import ReferenciaService from "@/services/referencia.service";
import {required} from 'vuelidate/lib/validators'

// Funcion para comprobar que el codigo es valido
const isCodeValid = (nombre) => !nombre.error

export default {
  name: "ReferenciasCreate",

  data() {
    return {
      codigo: null,
      stock: 0,
      nota: null,

      nombre: null,
      codeError: true,
    };
  },

  methods: {
    async nombreReferencia() {
      this.nombre = await ReferenciaService.getData(this.codigo);
      console.log(this.nombre)
    },

    async crear() {
      const res = await ReferenciaService.create({
        codigo: this.codigo,
        stock: this.stock,
        nota: this.nota,
      });
      console.log(res);
    },
  },

  validations:{
    codigo:{required},
    stock:{required},
    nombre:{isCodeValid}
  }
};
</script>