import AuthService from "@/services/auth.service";

import CostesIndex from "@/views/costes/CostesIndex";
import CostesVariables from "@/views/costes/CostesVariables";
import CostesLaminables from "@/views/costes/CostesLaminables";
import CostesProduccion from "@/views/costes/CostesProduccion";
import CostesTransportesIndex from "@/views/costes/CostesTransportesIndex";
import CostesTransportesForm from "@/views/costes/CostesTransportesForm";

const rutasCostes = [
  {
    path: "/costes",
    component: CostesIndex,
    name: "costes.index",
  },
  {
    path: "/costes/variables",
    component: CostesVariables,
    name: "costes.variables",
  },
  {
    path: "/costes/laminables",
    component: CostesLaminables,
    name: "costes.laminables",
  },
  {
    path: "/costes/transportes",
    component: CostesTransportesIndex,
    name: "costes.transportes",
  },
  {
    path: "/costes/transportes/formulario/:id?",
    component: CostesTransportesForm,
    name: "costes.transportes.form",
  },
  {
    path: "/costes/produccion",
    component: CostesProduccion,
    name: "costes.produccion",
  },
];

export default rutasCostes.map((route) => {
  // Agregar a cada ruta un Guard Before Enter para comprobar si el usuario autenticado tiene el rol
  return {
    ...route,
    beforeEnter: (to, from, next) => {
      if (AuthService.user().roles.includes("costes")) next();
      else next({ name: "Error404View" });
    },
  };
});
