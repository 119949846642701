const snackbar = {
  namespaced: true,
  state:{
    snackbar:{
      show:false,
      message:'Esto es un mensaje flotante. Si lo ves avisa a Sergio',
      color:'secondary',
    }
  },
  mutations:{
    setSnackbar(state,snackbar){
      state.snackbar = {
        show:true,
        message:snackbar.message,
        color:snackbar.color ? snackbar.color : 'green',
      }
    },
    unsetSnackbar(state){
      state.snackbar.show = false
      state.message = null
    }
  },
  getters:{
    getSnackbar(state){
      return state.snackbar
    }
  },
}

export default snackbar