<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>Transportes</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn color="primary" :to="{ name: 'costes.transportes.form' }"
          >Crear nuevo</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field v-model="search" solo label="Busqueda"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table :search="search" :headers="headers" :items="transportes" sort-by="localidad">
          <template v-slot:[`item.localidad`]="{item}">
            <router-link :to="{name:'costes.transportes.form',params:{id:item.id}}">{{item.localidad}}</router-link>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ClienteService from "@/services/cliente.service";

export default {
  name: "CostesTransportesIndex",

  data() {
    return {
      headers: [
        { text: "Localidad", value: "localidad" },
        { text: "Precio", value: "precio" },
        { text: "Proveedor", value: "proveedor" },
      ],
      search:'',
      transportes: [],
    };
  },

  async created() {
    this.transportes = await ClienteService.getTransportes();
  },
};
</script>