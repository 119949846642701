import HorasService from "@/services/horas.service";
import HorasConsulta from '@/views/horas/HorasConsulta.vue'

const rutasHoras = [
  {
    path: "/horas/jwt/:token",
    redirect: (data) => {
      console.log(data)
      const { token } = data.params;
      if (token){
        HorasService.lastRegistro(token)
      }
      return '/'
    },
    name: "horas.jwt",
  },
	{
		path:"/horas",
		name:"horas.index",
		component: HorasConsulta
	}
];

export default rutasHoras;
