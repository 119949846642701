<template>
  <v-snackbar 
    app 
    :color="snackbar.color"
    v-model="snackbar.show"
    top
    centered
    :timeout="7000"
    content-class="text-center"
  >
    {{snackbar.message}}
    <template #action>
      <v-icon @click="close">mdi-close</v-icon>
    </template>
  </v-snackbar>
</template>

<script>
import SnackbarService from '@/services/snackbar.service'

export default {
  name:'AppSnackbar',

  computed:{
    snackbar(){
      return SnackbarService.get()
    }
  },

  methods:{
    close(){
      SnackbarService.unset()
    }
  }
}
</script>