import Vue from "vue";
import Vuex from "vuex";

import snackbar from './modules/snackbar.module'
import auth from './modules/auth.module'
import data from './modules/data.module'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    data,
    snackbar,
  },
});