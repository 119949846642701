<template>
  <v-container>
    <v-row>
      <v-col md="10" lg="6" class="mx-auto">
        <v-card flat class="my-5 mb-10">
          <v-container>
            <v-row >
              <v-col v-if="registros[0] && !registros[0].salida">
                <HorasCurrent @end="fetchRegistros" :registro="registros[0]" />
              </v-col>
              <v-col v-else>
                <HorasNew @start="fetchRegistros"/>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="10" lg="6" class="mx-auto">
        <HorasHistorial :historial="registros" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import AuthService from "@/services/auth.service";
import HorasService from "@/services/horas.service";

import HorasHistorial from "./partials/HorasHistorial.vue";
import HorasCurrent from "./partials/HorasCurrent.vue";
import HorasNew from './partials/HorasNew.vue'

export default {
  name: "HorasIndex",
  components: {
    HorasHistorial,
    HorasCurrent,
    HorasNew,
  },
  data() {
    return {
      userId: null,
      registros: [],
    };
  },
  async created() {
    setTimeout(() => {
      this.fetchRegistros();
    }, 1000);
  },
  methods: {
    async fetchRegistros() {
      const user = AuthService.user();
      this.registros = await HorasService.findAll(user.id);
    }
  }
}

</script>