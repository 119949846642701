
<template>
  <v-container>
    <v-row>
      <v-col>
        <h1 class="subtitle-1 grey--text">Proveedores</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn color="primary" dark class="mb-5" :to="{ name: 'proveedores.formulario' }"
          >Crear proveedor</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          solo
          label="Buscar"
          v-model="filtroProveedor"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="proveedores"
          :server-items-length="totalProveedores"
          :options.sync="options"
          :footer-props="footerProps"
          dense
          disable-sort
        >
          <template v-slot:[`item.nombre`]="{ item }">
            <router-link
              :to="{ name: 'proveedores.formulario', params: { id: item.id } }"
              >{{ item.nombre }}</router-link
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProveedorService from "@/services/proveedor.service";

export default {
  name: "ProveedoresIndex",
  data() {
    return {
      headers: [
        { text: "id", value: "id" },
        { text: "Nombre", value: "nombre" },
      ],
      proveedores: [],
      loading: true,
      filtroProveedor: "",
      totalProveedores: -1,
      options: {},
      footerProps: {
        "items-per-page-options": [25, 50, 100],
      },
    };
  },

  async created() {
    await this.fetchProveedores()
  },

  methods: {
    async fetchProveedores() {
      this.loading = true;
      const res = await ProveedorService.findAll({
        proveedor: this.filtroProveedor,
        ...this.options,
      });
      this.proveedores = res.rows;
      this.totalProveedores = res.count;
      this.loading = false;
    },
  },

  watch: {
    options: {
      handler() {
        this.fetchProveedores();
      },
      deep: true,
    },

    filtroProveedor() {
      this.options.page = 1;
      this.fetchProveedores();
    },
  },
};
</script>