import AuthService from '@/services/auth.service'

import PresupuestosIndex from '@/views/presupuestos/PresupuestosIndex'
import PresupuestosForm from '@/views/presupuestos/PresupuestosForm'
import PresupuestosShow from '@/views/presupuestos/PresupuestosShow'

const rutasPresupuestos = [
  {
    path:'/presupuestos',
    component:PresupuestosIndex,
    name:'presupuestos.index',
  },
  {
    path:'/presupuestos/crear/:pedidoId?',
    component:PresupuestosForm,
    name:'presupuestos.crear',
  },
  {
    path:'/presupuestos/rehacer/:presupuestoId?',
    component:PresupuestosForm,
    name:'presupuestos.rehacer',
  },
  {
    path:'/presupuestos/:id',
    component: PresupuestosShow,
    name: 'presupuestos.show',
  }
]

export default rutasPresupuestos.map(route=>{
  // Agregar a cada ruta un Guard Before Enter para comprobar si el usuario autenticado tiene el rol
  return{
    ...route,
    beforeEnter:(to,from,next)=>{
      if(AuthService.user().roles.includes('presupuestos')) next()
      else next({name:'Error404View'})
    }
  } 
})