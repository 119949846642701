<template>
  <v-container>
    <FabricacionModal :fabricacion="seleccionado" @close="cerradoModal" />
    <v-row>
      <v-col>
        <h1 class="subtitle-1 grey--text">Fabricacion</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          solo
          label="Buscar"
          v-model="filtroCliente"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-switch v-model="mostrarFacturados" label="Ver terminados"></v-switch>
      </v-col>
    </v-row>
    <v-row v-if="fabricacionesSeleccionadas.length > 0">
      <v-col>
        <v-btn color="success" @click="crearAlbaran">Crear albaran</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :loading="loading"
          :options.sync="options"
          show-select
          :headers="headers"
          :items="itemsFiltrados"
          @click:row="marcarFabricada"
          v-model="fabricacionesSeleccionadas"
          dense
          :footer-props="footerProps"
          :server-items-length="totalFabricaciones"
          :disable-sort="mostrarFacturados"
        >
          <template v-slot:[`item.referencia.tipo`]="{ item }">
            <span v-if="item.referencia.detalle && item.referencia.tipo">
              {{ item.referencia.tipo }}
            </span>
            <span v-else> Otro </span>
          </template>
          <!-- Ancho -->
          <template v-slot:[`item.referencia.detalle.ancho`]="{ item }"
            ><span
              v-if="item.referencia.detalle && item.referencia.detalle.ancho"
              >{{ item.referencia.detalle.ancho }}</span
            ><span
              v-else-if="item.referencia.referenciaTipo === 'CompraVenta'"
              >{{ item.referencia.nombre }}</span
            >
          </template>
          <!-- Largo -->
          <template v-slot:[`item.referencia.detalle.largo`]="{ item }">
            <span
              v-if="item.referencia.detalle && item.referencia.detalle.largo"
              >{{ item.referencia.detalle.largo }}</span
            >
            <span v-if="(item.referencia.referenciaTipo === 'DetalleSobreBurbuja' || item.referencia.referenciaTipo === 'DetalleSobreFoam') && item.referencia.detalle.solapa">+{{item.referencia.detalle.solapa.data}}</span>
            <span v-if="(item.referencia.referenciaTipo === 'DetalleSobreBurbuja' || item.referencia.referenciaTipo === 'DetalleSobreFoam') && item.referencia.detalle.adhesivo">adhesivo</span>
          </template>
          <!-- Calidad -->
          <template v-slot:[`item.referencia.detalle.calidad`]="{ item }">
            <span
              v-if="item.referencia.detalle && item.referencia.detalle.gramaje"
              >{{ item.referencia.detalle.gramaje.data }}g</span
            >
            <span
              v-else-if="
                item.referencia.detalle && item.referencia.detalle.espesor
              "
              >{{ item.referencia.detalle.espesor.data }}mm</span
            >
												<span v-if="item.referencia.detalle && item.referencia.detalle.precorte">-{{ item.referencia.detalle.precorte.data
													}}pre</span>
						<span v-if="item.referencia.detalle.diametro"> {{ item.referencia.detalle.diametro.code }}</span>
						<span v-if="!item.referencia.detalle.mandril && item.referencia.detalle.tipo == 'Rollo de burbuja'"> S.M.</span>
						<span v-if="!item.referencia.detalle.mandril && item.referencia.detalle.tipo == 'Rollo de foam'"> S.M.</span>
						<span v-if="item.referencia.detalle.mandril && item.referencia.detalle.tipo == 'Minirollo de burbuja'"> C.M.</span>
						<span v-if="item.referencia.detalle.mandril && item.referencia.detalle.tipo == 'Minirollo de foam'"> C.M.</span>
          </template>
          <!-- Triple -->
          <template v-slot:[`item.referencia.detalle.tricapa`]="{ item }">
            <v-icon
              v-if="item.referencia.detalle && item.referencia.detalle.tricapa"
              color="blue darken-2"
              outline
              >mdi-check-circle</v-icon
            >
          </template>
          <!-- Nota -->
          <template
            v-slot:[`item.presupuestoDetalle.presupuesto.nota`]="{ value }"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-if="value" color="warning" v-on="on"
                  >mdi-alert-circle</v-icon
                >
              </template>
              <span>
                {{ value }}
              </span>
            </v-tooltip>
          </template>
          <!-- Fecha -->
          <template v-slot:[`item.createdAt`]="{ item }">{{
            item.createdAt | fecha
          }}</template>
          <!-- FechaExigida -->
          <template v-slot:[`item.fechaExigida`]="{ item }">
            <span v-if="item.presupuestoDetalle">{{
              item.presupuestoDetalle.presupuesto.fechaExigida | fecha
            }}</span>
          </template>
          <!-- Recogida Propia -->
          <template
            v-slot:[`item.presupuestoDetalle.presupuesto.recogidaPropia`]="{
              item,
            }"
          >
            <span v-if="item.presupuestoDetalle">
              <v-chip
                v-if="item.presupuestoDetalle.presupuesto.recogidaPropia"
                outlined
                x-small
                color="success"
                >RP</v-chip
              >
              <v-chip
                v-else-if="
                  item.presupuestoDetalle.presupuesto.direccion.transporte
                "
                outlined
                small
                color="primary"
                >{{
                  item.presupuestoDetalle.presupuesto.direccion.transporte
                    .proveedor
                }}</v-chip
              >
            </span>
          </template>
          <!-- Estado -->
          <template v-slot:[`item.cerrado`]="{ item }">
            <v-chip label small color="primary" v-if="item.cerrado == true"
              >Terminado</v-chip
            >
            <v-chip
              label
              small
              color="purple white--text"
              v-else-if="item.albaranes.length > 0"
              >Entregado</v-chip
            >
            <v-chip
              label
              small
              color="success"
              v-else-if="
                item.cantidad <= item.cantidadFabricada && !item.cerrado
              "
              >Fabricado</v-chip
            >
            <v-chip
              label
              small
              color="warning"
              v-else-if="
                item.cantidad > item.cantidadFabricada && item.cantidadFabricada
              "
              >Fa. Parcial
            </v-chip>
            <v-chip v-else small label>Pendiente</v-chip>
          </template>
          <template v-slot:[`header.data-table-select`]></template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";

import FabricacionModal from "@/views/fabricacion/FabricacionModal";

import FabricacionService from "@/services/fabricacion.service";
import DataService from "@/services/data.service";

export default {
  name: "FabricacionIndex",

  components: { FabricacionModal },

  data() {
    return {
      headers: [
        { text: "Cliente", value: "cliente.nombre" },
        { text: "Tipo", value: "referencia.detalle.tipo" },
        { text: "Ancho", value: "referencia.detalle.ancho", align: "center" },
        {
          text: "Corte",
          value: "referencia.detalle.cortes.nombre",
          align: "center",
        },
        { text: "Largo", value: "referencia.detalle.largo", align: "center" },
        {
          text: "Calidad",
          value: "referencia.detalle.calidad",
          align: "center",
        },
        {
          text: "Laminados",
          value: "referencia.detalle.laminados.nombre",
          align: "center",
        },
        {
          text: "Tricapa",
          value: "referencia.detalle.tricapa",
          align: "center",
        },
        { text: "Cantidad", value: "cantidad", align: "center" },
        { text: "Nota", value: "presupuestoDetalle.presupuesto.nota" },
        { text: "Fecha", value: "createdAt", align: "center" },
        { text: "F.Ex.", value: "fechaExigida", align: "center" },
        {
          text: "RP",
          value: "presupuestoDetalle.presupuesto.recogidaPropia",
          align: "center",
          sortable: false,
        },
        { text: "Estado", value: "cerrado", align: "center", sortable: false },
      ],
      filtroCliente: "",
      mostrarFacturados: false,
      fabricaciones: [],
      totalFabricaciones: -1,
      options: {},
      fabricacionesSeleccionadas: [],
      loading: true,
      seleccionado: null,
      footerProps: {
        "items-per-page-options": [-1],
      },
    };
  },

  async created() {
    await this.fetchFabricaciones();
  },

  methods: {
    async fetchFabricaciones() {
      this.loading = true;
      const res = await FabricacionService.findAll({
        ...this.options,
        cliente: this.filtroCliente,
        cerrado: this.mostrarFacturados,
      });
      this.totalFabricaciones = res.count;
      this.fabricaciones = res.rows;
      this.loading = false;
    },

    async marcarFabricada(fabricacion) {
      this.seleccionado = await _.cloneDeep(fabricacion);
    },

    crearAlbaran() {
      // VUEX agregar los datos a enviar de los detalles para pasar a albaranes
      DataService.setData(this.fabricacionesSeleccionadas);
      this.$router.push({ name: "albaranes.form" });
      console.log("test");
    },

    cerradoModal() {
      this.seleccionado = null;
      this.fetchFabricaciones();
    },
  },

  computed: {
    itemsFiltrados() {
      // Si no se selecciona nada devolver las fabricaciones tal cual
      if (this.fabricacionesSeleccionadas.length < 1) return this.fabricaciones;
      // Si se selecciona alguna fabricacion filtrar todas las que no tengan el mismo cliente
      else {
        return this.fabricaciones.filter((fabricacion) => {
          // Todos los elementos tienen que tener el mismo cliente que el primero seleccionado
          return (
            fabricacion.cliente.id ===
            this.fabricacionesSeleccionadas[0].cliente.id
          );
        });
      }
    },
  },

  watch: {
    options: {
      async handler() {
        await this.fetchFabricaciones();
      },
      deep: true,
    },

    async filtroCliente() {
      this.options.page = 1;
      await this.fetchFabricaciones();
    },

    async mostrarFacturados() {
      if (this.mostrarFacturados) {
        this.footerProps["items-per-page-options"] = [25, 50, 100];
        this.options.itemsPerPage = 25;
      } else {
        this.footerProps["items-per-page-options"] = [-1];
        this.options.itemsPerPage = -1;
      }
      (this.options.page = 1), await this.fetchFabricaciones();
    },
  },
};
</script>