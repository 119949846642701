<template>
  <v-container>
    <v-select
      :items="maquinas"
      label="Maquina"
      item-text="nombre"
      item-value="id"
      v-model="maquinaId"
    />
    <ProduccionModal :produccion="selectedProduccion" @close="closeModal"/>
    <v-form v-if="user.roles.includes('produccion.crear')">
    <h1>Produccion Create</h1>
    <v-switch
      :label="!source ? 'Desde fabricacion' : 'Nueva produccion'"
      v-model="source"
    />
    <v-autocomplete
      v-if="!source"
      label="Fabricacion"
      :items="fabricaciones"
      :item-text="getItemText"
      return-object
      @input="setFabricacion"
      v-model="fabricacion"
    />
    <InputReferencia v-else @fetch="setReferencia" />
    <v-text-field
      type="number"
      label="Uds"
      v-model.number="produccion.cantidad"
    ></v-text-field>
    <v-btn color="primary" block @click="agregarProduccion" :disabled="$v.$invalid">Agregar</v-btn>

    </v-form>
    <v-card class="mt-7">
      <draggable v-model="lista" @change="updateLista" :disabled="!userHasCreateRole">
        <v-list-item
          v-for="(produccion,idx) in lista"
          :key="produccion.id"
        >
          <v-list-item-content>
            <v-list-item-title class="white-space-break">
              {{ produccion.cliente.nombre }}
            </v-list-item-title>
            <v-list-item-subtitle class="white-space-break">
              {{ produccion.referenciaNombre }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              Cantidad: {{ produccion.cantidad }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="produccion.fabricado">
              Fabricado: {{ produccion.fabricado }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-container>
              <v-row>
                <v-col>
                  <v-btn v-if="user.roles.includes('produccion.crear')" fab x-small color="error" @click="deleteProduccion(idx)">
                    <v-icon>mdi-close</v-icon>
                  </v-btn></v-col
                >
                <v-col>
                  <v-btn fab x-small color="primary" @click="selectProduccion(produccion)">
                    <v-icon>mdi-check</v-icon>
                  </v-btn></v-col
                >
              </v-row>
            </v-container>
          </v-list-item-action>
        </v-list-item>
      </draggable>
    </v-card>
  </v-container>
</template>

<script>
// import _ from 'lodash'
import { required, numeric } from "vuelidate/lib/validators";

import ProduccionService from "@/services/produccion.service";
import FabricacionService from "@/services/fabricacion.service";
import AuthService from "@/services/auth.service";

import draggable from "vuedraggable";
import InputReferencia from "@/components/InputReferencia";
import ProduccionModal from '@/views/produccion/ProduccionModal';

export default {
  name: "ProduccionCrear",

  components: {
    InputReferencia,
    draggable,
    ProduccionModal,
  },

  data() {
    return {
      maquinaId:null,
      maquinas: [],
      source: false,
      fabricacion: null,
      fabricaciones: [],
      produccion: {
        id:null,
        cantidad: null,
        referenciaCode: null,
        referenciaNombre: null,
        fabricacionId: null,
        maquinaId: null,
      },
      selectedProduccion:null,
      lista: [],
    };
  },

  async created() {
    const maquinas = await ProduccionService.getMaquinas();
    this.maquinas = maquinas;
    const fabricaciones = await FabricacionService.findAll();
    this.fabricaciones = fabricaciones.rows;
  },

  methods: {
    setFabricacion(e) {
      this.produccion.cantidad = e.cantidad;
      this.produccion.fabricacionId = e.id;
      this.produccion.referenciaCode = e.referencia.codigo;
      this.produccion.referenciaNombre = e.referencia.nombre;
    },

    setReferencia(e) {
      this.produccion.referenciaCode = e.detalle.codeNormalizado;
      this.produccion.referenciaNombre = e.detalle.nombre;
    },

    async agregarProduccion() {
      this.lista = [
        ...this.lista,
        { ...this.produccion, order: this.lista.length + 1 },
      ];
      this.updateLista()
    },

    deleteProduccion(idx){
      this.lista.splice(idx,1)
      this.updateLista()
    },

    async updateLista() {
      this.lista = this.lista.map((elemento, idx) => {
        elemento.order = idx;
        return elemento;
      });
      await ProduccionService.actualizarListaProduccion(this.lista)
    },

    selectProduccion(produccion){
      this.selectedProduccion = produccion
    },

    async closeModal(){
      this.selectedProduccion=null
      const res = await ProduccionService.getListaProduccion(this.produccion.maquinaId)
      this.lista = res
    }, 

    getItemText(item){
      return `${item.referencia.nombre} - ${item.cliente.nombre}`
    }
  },

  watch: {
    source() {
      this.produccion.cantidad = null;
      this.produccion.referenciaCode = null
      this.produccion.fabricacionId = null
      this.produccion.referenciaNombre = null
      this.selectedProduccion = null
    },
    async maquinaId(){
      this.lista = []
      if(this.maquinaId){
        this.produccion.maquinaId = this.maquinaId
        const res = await ProduccionService.getListaProduccion(this.produccion.maquinaId)
        this.lista = res
      }
    },
    'produccion.maquinaId':function(){
      this.fabricacion = null
      this.produccion.cantidad = null;
      this.produccion.referenciaCode = null
      this.produccion.fabricacionId = null
      this.produccion.referenciaNombre = null
      this.selectedProduccion = null
    },
  },

  validations:{
    produccion:{
      maquinaId:{required},
      referenciaCode:{required},
      cantidad:{required,numeric}
    },
  },

  computed:{
    user() {
      return AuthService.user();
    },
    userHasCreateRole(){
      if(this.user.roles.includes('produccion.crear')) return true
      else return false
    }
  }
};
</script>

<style scoped>
.white-space-break {
  white-space: break-spaces;
}
</style>