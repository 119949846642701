import AuthService from '@/services/auth.service'

import FeaturesIndex from '@/views/features/FeaturesIndex'

const featureFacturas = [
  {
    path:'/features',
    component:FeaturesIndex,
    name:'features.index',
  },
]

export default featureFacturas.map(route=>{
  // Agregar a cada ruta un Guard Before Enter para comprobar si el usuario autenticado tiene el rol
  return{
    ...route,
    beforeEnter:(to,from,next)=>{
      if(AuthService.user().roles.includes('features')) next()
      else next({name:'Error404View'})
    }
  } 
})