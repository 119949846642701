<template>
  <v-container>
    <v-row>
      <v-col>
        <router-link :to="{ name: 'banco.recibos.index' }"
          >Volver a recibos</router-link
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-container>
            <v-row>
              <v-col>
                <v-select
                  :items="estados"
                  item-text="estado"
                  item-value="id"
                  label="Estado recibo"
                  v-model="recibo.ReciboEstadoId"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete
                  label="Factura asociada"
                  :items="facturas"
                  item-text="numero"
                  item-value="id"
                  v-model="recibo.facturaId"
                  @input="setImporte"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Importe"
                  v-model="recibo.importe"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <InputDate
                  label="Fecha Vencimiento"
                  v-model="recibo.fechaVencimiento"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <InputDate
                  label="Fecha Presentado"
                  v-model="recibo.fechaEntregado"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <InputDate
                  label="Fecha Cobrado"
                  v-model="recibo.fechaCobrado"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea label="Nota" v-model="recibo.nota"></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  v-if="!recibo.id"
                  color="primary"
                  block
                  @click="crearRecibo"
                  >Crear recibo</v-btn
                >
                <v-btn v-else color="primary" block @click="editarRecibo"
                  >Editar recibo</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ReciboService from "@/services/banco/recibo.service";
import FacturaService from "@/services/factura.service";
import SnackbarService from "@/services/snackbar.service";

import InputDate from "@/components/InputDate";

export default {
  name: "RecibosForm",

  components: { InputDate },

  data() {
    return {
      estados: [],
      facturas: [],
      recibo: {
        id: null,
        facturaId: null,
        importe: 0,
        fechaVencimiento: null,
        fechaEntregado: null,
        fechaCobrado: null,
        nota: null,
        ReciboEstadoId: 1,
      },
    };
  },

  async created() {
    const { id } = this.$route.params;
    if (id) {
      let fetchedRecibo = await ReciboService.findByPk(id);
      fetchedRecibo.fechaVencimiento = fetchedRecibo.fechaVencimiento
        ? new Date(fetchedRecibo.fechaVencimiento)
        : null;
      fetchedRecibo.fechaEntregado = fetchedRecibo.fechaEntregado
        ? new Date(fetchedRecibo.fechaEntregado)
        : null;
      fetchedRecibo.fechaCobrado = fetchedRecibo.fechaCobrado
        ? new Date(fetchedRecibo.fechaCobrado)
        : null;
      this.recibo = fetchedRecibo;
    }

    this.estados = await ReciboService.findAllEstados();
    const res = await FacturaService.findAll();
    this.facturas = res.rows;
  },

  methods: {
    setImporte(value) {
      // El valor retornado por el autocomplete es el id de la factura
      const id = value;
      // Buscar la factura con ese id
      const factura = this.facturas.find((factura) => factura.id == id);
      // Marcar el importe de esa factura como importe del recibo
      this.recibo.importe = factura.importeTotal;
    },

    async crearRecibo() {
      await ReciboService.create(this.recibo);
      await SnackbarService.set({
        message: "Se ha creado un nuevo recibo",
      });
      this.$router.push({ name: "banco.recibos.index" });
    },

    async editarRecibo() {
      await ReciboService.update(this.recibo.id,this.recibo);
      await SnackbarService.set({
        message: "Se ha editado el recibo",
      });
      this.$router.push({ name: "banco.recibos.index" });
    },
  },
};
</script>