<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <router-link
              :to="{ name: 'clientes.form', params: { id: cliente.id } }"
            >
              {{ cliente.nombre }}
            </router-link>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="4">
                <v-simple-table dense>
                  <tbody>
                    <tr>
                      <td>Cliente</td>
                      <td>{{ cliente.nombre }}</td>
                    </tr>
                    <tr>
                      <td>CIF</td>
                      <td>{{ cliente.cif }}</td>
                    </tr>
                    <tr>
                      <td>Direccion</td>
                      <td>{{ cliente.direccion }}</td>
                    </tr>
                    <tr>
                      <td>Localidad</td>
                      <td>{{ cliente.localidad }}</td>
                    </tr>
                    <tr>
                      <td>Categoria</td>
                      <td>{{ cliente.categoria.nombre }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-simple-table dense>
                  <tbody>
                    <tr>
                      <td>Forma de pago</td>
                      <td>
                        <span v-if="cliente.banco">{{
                          cliente.banco.pago.nombre
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Vencimiento</td>
                      <td>
                        <span v-if="cliente.banco">{{
                          cliente.banco.vencimiento
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Dia de pago</td>
                      <td>
                        <span v-if="cliente.banco">{{cliente.banco.dia}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Cuenta banco</td>
                      <td>
                        <span v-if="cliente.banco">{{
                          cliente.banco.banco
                        }}</span>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
            <v-row>
              <v-col>
                <v-list dense>
                  <v-subheader>
                    <v-btn
                      color="primary"
                      x-small
                      :to="{
                        name: 'clientes.mail',
                        params: { clienteId: cliente.id },
                      }"
                      ><v-icon small>mdi-plus</v-icon> Nuevo correo</v-btn
                    >
                  </v-subheader>
                  <v-list-item-group>
                    <v-list-item
                      v-for="mail in cliente.mails"
                      :key="mail.id"
                      :to="{
                        name: 'clientes.mail',
                        params: { clienteId: cliente.id, id: mail.id },
                      }"
                    >
                      <v-list-item-title>
                        {{ mail.mail }}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="mail.enviarFacturas">
                        <v-icon color="success" small>mdi-check</v-icon>
                        <span>Enviar correos</span>
                      </v-list-item-subtitle>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-list>
                  <v-subheader>
                    <v-btn
                      color="primary"
                      x-small
                      :to="{
                        name: 'clientes.direccion',
                        params: { clienteId: cliente.id },
                      }"
                      ><v-icon small>mdi-plus</v-icon> Nueva direccion</v-btn
                    >
                  </v-subheader>
                  <v-list-item-group>
                    <v-list-item
                      :to="{
                        name: 'clientes.direccion',
                        params: { clienteId: cliente.id, id: direccion.id },
                      }"
                      v-for="direccion in cliente.direcciones"
                      :key="direccion.id"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ direccion.nombre }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ direccion.direccion }} / {{ direccion.localidad }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="direccion.transporte">
                          Transporte: {{direccion.transporte.localidad}} - {{direccion.transporte.proveedor}}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-list>
                  <v-subheader>
                    <v-btn
                      color="primary"
                      x-small
                      :to="{
                        name: 'clientes.telefono',
                        params: { clienteId: cliente.id },
                      }"
                      ><v-icon small>mdi-plus</v-icon> nuevo telefono</v-btn
                    >
                  </v-subheader>
                  <v-list-item-group>
                    <v-list-item
                      :to="{
                        name: 'clientes.telefono',
                        params: { clienteId: cliente.id, id: telefono.id },
                      }"
                      v-for="telefono in cliente.telefonos"
                      :key="telefono.id"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ telefono.telefono }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{telefono.contacto}}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-col>
            </v-row>
					<v-row>
						<v-col>
							<v-btn @click="exportarFacturas" :loading="loading" color="primary" :disabled="rangoFechas.length<2">Exportar facturas</v-btn>
						</v-col>
						<v-col>
							Desde: {{ rangoFechas[0] }}
						</v-col>
						<v-col>
							Hasta {{ rangoFechas[1] }}
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-date-picker v-model="rangoFechas" range></v-date-picker>
						</v-col>
					</v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ClienteService from "@/services/cliente.service";
import SnackbarService from "@/services/snackbar.service";

export default {
  name: "ClientesShow",

  data() {
    return {
      cliente: {
        categoria: {},
        mails: [],
        direcciones: [],
      },
			rangoFechas: [],
			loading:false,
    };
  },

  async created() {
    this.cliente = await ClienteService.findByPk(this.$route.params.id);
  },

	methods:{
		async exportarFacturas() {

			this.loading = true
			try {
				await ClienteService.exportarFacturas(this.cliente, this.rangoFechas[0], this.rangoFechas[1])
			} catch {
				SnackbarService.set({
					message: 'Hubo un error al intentar descargar las facturas',
					color: 'red',
				})
			}
			this.loading = false

		}
	}
};
</script>