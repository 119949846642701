var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_c('router-link',{attrs:{"to":{ name: 'clientes.form', params: { id: _vm.cliente.id } }}},[_vm._v(" "+_vm._s(_vm.cliente.nombre)+" ")])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-simple-table',{attrs:{"dense":""}},[_c('tbody',[_c('tr',[_c('td',[_vm._v("Cliente")]),_c('td',[_vm._v(_vm._s(_vm.cliente.nombre))])]),_c('tr',[_c('td',[_vm._v("CIF")]),_c('td',[_vm._v(_vm._s(_vm.cliente.cif))])]),_c('tr',[_c('td',[_vm._v("Direccion")]),_c('td',[_vm._v(_vm._s(_vm.cliente.direccion))])]),_c('tr',[_c('td',[_vm._v("Localidad")]),_c('td',[_vm._v(_vm._s(_vm.cliente.localidad))])]),_c('tr',[_c('td',[_vm._v("Categoria")]),_c('td',[_vm._v(_vm._s(_vm.cliente.categoria.nombre))])])])])],1),_c('v-spacer')],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-simple-table',{attrs:{"dense":""}},[_c('tbody',[_c('tr',[_c('td',[_vm._v("Forma de pago")]),_c('td',[(_vm.cliente.banco)?_c('span',[_vm._v(_vm._s(_vm.cliente.banco.pago.nombre))]):_vm._e()])]),_c('tr',[_c('td',[_vm._v("Vencimiento")]),_c('td',[(_vm.cliente.banco)?_c('span',[_vm._v(_vm._s(_vm.cliente.banco.vencimiento))]):_vm._e()])]),_c('tr',[_c('td',[_vm._v("Dia de pago")]),_c('td',[(_vm.cliente.banco)?_c('span',[_vm._v(_vm._s(_vm.cliente.banco.dia))]):_vm._e()])]),_c('tr',[_c('td',[_vm._v("Cuenta banco")]),_c('td',[(_vm.cliente.banco)?_c('span',[_vm._v(_vm._s(_vm.cliente.banco.banco))]):_vm._e()])])])])],1),_c('v-spacer')],1),_c('v-row',[_c('v-col',[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',[_c('v-btn',{attrs:{"color":"primary","x-small":"","to":{
                        name: 'clientes.mail',
                        params: { clienteId: _vm.cliente.id },
                      }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v(" Nuevo correo")],1)],1),_c('v-list-item-group',_vm._l((_vm.cliente.mails),function(mail){return _c('v-list-item',{key:mail.id,attrs:{"to":{
                        name: 'clientes.mail',
                        params: { clienteId: _vm.cliente.id, id: mail.id },
                      }}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(mail.mail)+" ")]),(mail.enviarFacturas)?_c('v-list-item-subtitle',[_c('v-icon',{attrs:{"color":"success","small":""}},[_vm._v("mdi-check")]),_c('span',[_vm._v("Enviar correos")])],1):_vm._e()],1)}),1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-list',[_c('v-subheader',[_c('v-btn',{attrs:{"color":"primary","x-small":"","to":{
                        name: 'clientes.direccion',
                        params: { clienteId: _vm.cliente.id },
                      }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v(" Nueva direccion")],1)],1),_c('v-list-item-group',_vm._l((_vm.cliente.direcciones),function(direccion){return _c('v-list-item',{key:direccion.id,attrs:{"to":{
                        name: 'clientes.direccion',
                        params: { clienteId: _vm.cliente.id, id: direccion.id },
                      }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(direccion.nombre)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(direccion.direccion)+" / "+_vm._s(direccion.localidad)+" ")]),(direccion.transporte)?_c('v-list-item-subtitle',[_vm._v(" Transporte: "+_vm._s(direccion.transporte.localidad)+" - "+_vm._s(direccion.transporte.proveedor)+" ")]):_vm._e()],1)],1)}),1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-list',[_c('v-subheader',[_c('v-btn',{attrs:{"color":"primary","x-small":"","to":{
                        name: 'clientes.telefono',
                        params: { clienteId: _vm.cliente.id },
                      }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v(" nuevo telefono")],1)],1),_c('v-list-item-group',_vm._l((_vm.cliente.telefonos),function(telefono){return _c('v-list-item',{key:telefono.id,attrs:{"to":{
                        name: 'clientes.telefono',
                        params: { clienteId: _vm.cliente.id, id: telefono.id },
                      }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(telefono.telefono)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(telefono.contacto)+" ")])],1)],1)}),1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"loading":_vm.loading,"color":"primary","disabled":_vm.rangoFechas.length<2},on:{"click":_vm.exportarFacturas}},[_vm._v("Exportar facturas")])],1),_c('v-col',[_vm._v(" Desde: "+_vm._s(_vm.rangoFechas[0])+" ")]),_c('v-col',[_vm._v(" Hasta "+_vm._s(_vm.rangoFechas[1])+" ")])],1),_c('v-row',[_c('v-col',[_c('v-date-picker',{attrs:{"range":""},model:{value:(_vm.rangoFechas),callback:function ($$v) {_vm.rangoFechas=$$v},expression:"rangoFechas"}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }