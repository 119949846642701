import axios from '@/axios.config'

class ProveedorService {

  static findAll(queryParams){
    return axios.get('/api/proveedores',{params:queryParams})
      .then(res=>res.data)
  }

  static findByPk(id){
    return axios.get(`/api/proveedores/${id}`)
      .then(res=>res.data)
    }
    
  static create(data){
    return axios.post('/api/proveedores',data)
      .then(res=>res.data)
  }

  static update(proveedorId,data){
    return axios.put(`/api/proveedores/${proveedorId}`,data)
      .then(res=>res.data)
  }
  
    static findPagos(){
    return axios.get('/api/pagos')
      .then(res=>res.data)
  }

  static findGrupos(){
    return axios.get('/api/proveedores/grupos')
      .then(res=>res.data)
  }


}

export default ProveedorService