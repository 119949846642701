<template>
  <v-form @submit.prevent="login" id="login-form">
    <v-btn
      large
      block
      color="grey darken-3"
      darken
      class="mb-7 white--text"
      @click="loginGoogle"
    >
      Iniciar sesión
    </v-btn>
    <p class="text-center grey--text"><small>En caso de 🔥 mantén la calma y avisa a Sergio</small></p>
  </v-form>
</template>

<script>
// import AuthService from "@/services/auth.service";
import {VUE_APP_MS_AUTH_URL} from '@/config'

export default {
  name: "LoginView",

  data() {
    return {
      mail: "",
      password: "",
      VUE_APP_MS_AUTH_URL,
    };
  },

  methods: {
    loginGoogle() {
      window.location = `${VUE_APP_MS_AUTH_URL}/google`;
    },
  },
};
</script>

<style>
#login-form {
  width: 70%;

  /* Center vertically and horizontally */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#link-contrasena {
  width: 100%;
  display: block;
}

.centered-input input {
  text-align: center;
}

@media (min-width: 700px) {
  #login-form {
    width: 30%;
  }
}

@media (min-width: 950px) {
  #login-form {
    width: 20%;
  }
}
</style>