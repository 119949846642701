<template>
  <v-container>
    <v-row>
      <v-col>
        <h1 class="subtitle-1 grey--text">Compras</h1>
      </v-col>
    </v-row>
        <v-row>
      <v-col>
        <v-btn :to="{name:'compras.formulario'}" color="primary">Crear compra</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          solo
          label="Filtro"
          placeholder="Insertar nombre del proveedor a buscar"
          v-model="filtroProveedor"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-switch v-model="soloVencidas" label="Solo vencidas"></v-switch>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :loading="loading"
          :items="compras"
          :headers="headers"
          :server-items-length="totalCompras"
          :options.sync="options"
          dense
          :footer-props="footerProps"
          disable-sort
        >
          <template v-slot:[`item.numero`]="{item}"><router-link :to="{name:'compras.formulario',params:{id:item.id}}">{{item.numero}}</router-link></template>
          <template v-slot:[`item.fecha`]="{ item }">{{
            item.fecha | fecha
          }}</template>
          <template v-slot:[`item.fechaVencimiento`]="{ item }">{{
            item.fechaVencimiento | fecha
          }}</template>
          <template v-slot:[`item.importePagado`]="{ item }"
            >
            <v-chip
              label
              small
              color="success"
              v-if="item.importePagado >= item.total"
              >Pagado</v-chip
            >
            <v-chip
              label
              small
              color="warning"
              v-else-if="item.importePagado < item.total && item.importePagado && new Date(item.fechaVencimiento) < new Date()"
              >Parcial</v-chip
            >
            <v-chip
              label
              small
              color="error"
              v-else-if="!item.importePagado && new Date(item.fechaVencimiento) < new Date()"
              >Vencida</v-chip
            >
            <v-chip
              label
              small
              v-else
              >Pendiente</v-chip
            >
            </template
          >
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CompraService from "@/services/compra.service";

export default {
  name: "ComprasIndex",

  data() {
    return {
      loading: true,
      headers: [
        { text: "Proveedor", value: "proveedor.nombre" },
        { text: "Numero", value: "numero" },
        { text: "Fecha", value: "fecha" },
        { text: "Vencimiento", value: "fechaVencimiento" },
        { text: "Importe", value: "total" },
        { text: "IVA", value: "iva" },
        { text: "Estado", value: "importePagado" },
      ],
      compras: [],
      totalCompras: -1,
      options: {},
      filtroProveedor: "",
      footerProps: {
        "items-per-page-options": [25, 50, 100],
      },
      soloVencidas:false,
    };
  },

  async created() {
    await this.fetchCompras();
  },

  methods: {
    async fetchCompras() {
      this.loading = true;
      const comprasRespuesta = await CompraService.findAll({
        cliente: this.filtroProveedor,
        ...this.options,
        soloVencidas:this.soloVencidas,
      });
      this.compras = comprasRespuesta.rows;
      this.totalCompras = comprasRespuesta.count;
      this.loading = false;
    },
  },

  watch: {
    options: {
      handler() {
        this.fetchCompras();
      },
      deep: true,
    },

    filtroProveedor() {
      this.options.page = 1;
      this.fetchCompras();
    },

    soloVencidas(){
      this.options.page = 1
      this.fetchCompras()
    }
  },
};
</script>