import * as jose from "jose";

import axios from "@/axios.config";
import router from "@/router";

import SnackbarService from "@/services/snackbar.service";

const auth = {
  namespaced: true,

  state: {
    user: JSON.parse(localStorage.getItem("user")),
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    unsetUser(state) {
      state.user = null;
    },
  },
  actions: {
    SET_USER_FROM_TOKEN({ commit }, accessToken) {
      let accessTokenPayload;
      try {
        accessTokenPayload = jose.decodeJwt(accessToken);
        const user = {
          id: accessTokenPayload.id,
          mail: accessTokenPayload.email,
          roles: accessTokenPayload.rols ?? [],
          accessToken,
        };
        commit("setUser", user);
        localStorage.setItem("user", JSON.stringify(user));
      } catch (error) {
        console.log("El token proporcionado no es valido");
      }
    },
    LOGIN({ commit }, loginCredentials) {
      axios
        .post("/auth/login", loginCredentials)
        // extract jwt token from res
        .then((res) => {
          if (res.data.status === 400) {
            throw Error(res.data.message);
          }
          return res.data.accessToken;
        })
        // From token get User data
        .then((accessToken) => {
          // Decoded data from token
          const accessTokenPayload = jose.decodeJwt(accessToken);
          // Deconstruct the data
          const user = {
            id: accessTokenPayload.id,
            mail: accessTokenPayload.mail,
            roles: accessTokenPayload.rols,
            accessToken,
          };
          // Mutation setUser with data that comes from token
          commit("setUser", user);
          // Save user to localStorage
          localStorage.setItem("user", JSON.stringify(user));
        })
        // After setting the user redirect
        .then(() => {
          router.push({ name: "home" });
        })
        // if something fails just unsetUser from VUEX
        .catch(() => {
          commit("unsetUser");
          localStorage.removeItem("user");
          SnackbarService.set({
            message: "Las credenciales no son validas",
            color: "red",
          });
        });
    },
    LOGOUT({ commit }) {
      commit("unsetUser");
      SnackbarService.set({
        message: "Se ha cerrado sesión correctamente",
      });
      localStorage.removeItem("user");
      router.push({ name: "login" });
    },
  },
  getters: {
    getUser(state) {
      return state.user;
    },
  },
};

export default auth;
