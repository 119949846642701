<template>
  <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :css-classes="cssClasses"
    :styles="styles"
    :height="150"
    :sorted="sorted"
  />
</template>

<script>
import { Bar } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "BarChart",
  components: { Bar },
  props: {
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    width: {
      type: Number,
    },
    height: {
      type: Number,
      default: 400,
    },
    chartData: {
      type: Object,
      required: true,
    },
    sorted: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
      },
    };
  },
};
</script>