import axios from '@/axios.config'
import {VUE_APP_MS_AUTH_URL} from '@/config'

// URL del microservicio de autenticacion
const AUTH_SERVICE_URL = VUE_APP_MS_AUTH_URL

class UsuarioService {
  static getRoles(){
    return axios.get(`${AUTH_SERVICE_URL}/rol`)
      .then(res=>res.data)
      .catch(error=>console.error(error))
  }

  static getUsuario(id){
    return axios.get(`${AUTH_SERVICE_URL}/user/${id}`)
      .then(res=>res.data)
      .catch(error=>console.error(error))
  }

  static getUsuarios(){
    return axios.get(`${AUTH_SERVICE_URL}/user`)
      .then(res=>res.data)
      .catch(error=>console.error(error))
  }

  static registrarUsuario(usuarioData){
    return axios.post(`${AUTH_SERVICE_URL}/user`,usuarioData)
      .then(res=>res.data)
      .catch(error=>console.error(error))
  }

  static actualizarRolesUsuario(usuarioId,roles){
    return axios.post(`${AUTH_SERVICE_URL}/user/${usuarioId}/update-rols`,{roles}) 
      .then(res=>res.data)
      .catch(error=>console.error(error))
  }

  static update(usuarioId,data){
    return axios.put(`${AUTH_SERVICE_URL}/user/${usuarioId}`,data)
      .then(res=>res.data)
  }

}

export default UsuarioService