<template>
  <v-container>
    <v-card>
      <v-container>
        <v-text-field label="Nombre" v-model="direccion.nombre"></v-text-field>
        <v-text-field label="Direccion" v-model="direccion.direccion"></v-text-field>
        <v-text-field label="Localidad" v-model="direccion.localidad"></v-text-field>
        <v-select label="Transporte" :items="transportes" v-model="direccion.transporteId" item-text="localidad" item-value="id"></v-select>
        <v-btn v-if="!direccion.id" color="primary" block @click="crear">Crear direccion</v-btn>
        <v-btn v-else color="primary" :loading="loading" block @click="actualizar">Actualizar direccion</v-btn>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import ClienteService from '@/services/cliente.service'
import SnackbarService from '@/services/snackbar.service'

export default {
  name: 'ClientesDireccionForm',

  data(){
    return{
      direccion:{
        nombre:'',
        direccion:'',
        localidad:'',
        transporteId:null,
        clienteId:null,
      },
      transportes:[],
      loading:false,
    }
  },

  methods:{
    async crear(){
      this.loading = true
      await ClienteService.createDireccion(this.direccion)
      this.loading = false
      this.$router.push({name:'clientes.show',params:{id:this.direccion.clienteId}})
    },

    async actualizar(){
      await ClienteService.updateDireccion(this.direccion.id,this.direccion)
      SnackbarService.set({message:'Se ha actualizado correctamente la direccion'})
      this.$router.push({name:'clientes.show',params:{id:this.direccion.clienteId}})
    }
  },

  async created(){
    const {clienteId, id} = this.$route.params
    this.direccion.clienteId = clienteId
    this.transportes = await ClienteService.getTransportes()
    if(id){
      this.direccion = await ClienteService.findDireccionByPk(id)
    }
  }
}
</script>