<template>
  <v-container>
    <h2>Test calculo precio</h2>
    <v-text-field v-model="codigo" label="Codigo referencia" @input="test"></v-text-field>
    <p>Referencia datos</p>
    <p><small>codigo -> {{codigo}}</small></p>
    <p><small>nombre -> <span v-if="datos">{{datos.detalle.nombre}}</span></small></p>
    <hr>
    <p>Respuesta del servidor</p>
    <pre>{{datos}}</pre>
  </v-container>
</template>

<script>
import ReferenciaService from '@/services/referencia.service'

export default {
  name:'HerramientasReferencia',

  data(){
    return{
      codigo:'',
      datos:null,
    }
  },

  methods:{
    async test(){
      this.datos = await ReferenciaService.detalle(this.codigo)
    }
  }
}
</script>