const data = {
  namespaced: true,
  state:{
    data:null
  },
  mutations:{
    setData(state,data){
      state.data = data
    },
    unsetData(state){
      state.data = null
    }
  },
  getters:{
    getData(state){
      return state.data
    }
  },
}

export default data