var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('FabricacionModal',{attrs:{"fabricacion":_vm.seleccionado},on:{"close":_vm.cerradoModal}}),_c('v-row',[_c('v-col',[_c('h1',{staticClass:"subtitle-1 grey--text"},[_vm._v("Fabricacion")])])],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"solo":"","label":"Buscar"},model:{value:(_vm.filtroCliente),callback:function ($$v) {_vm.filtroCliente=$$v},expression:"filtroCliente"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-switch',{attrs:{"label":"Ver terminados"},model:{value:(_vm.mostrarFacturados),callback:function ($$v) {_vm.mostrarFacturados=$$v},expression:"mostrarFacturados"}})],1)],1),(_vm.fabricacionesSeleccionadas.length > 0)?_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.crearAlbaran}},[_vm._v("Crear albaran")])],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"loading":_vm.loading,"options":_vm.options,"show-select":"","headers":_vm.headers,"items":_vm.itemsFiltrados,"dense":"","footer-props":_vm.footerProps,"server-items-length":_vm.totalFabricaciones,"disable-sort":_vm.mostrarFacturados},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.marcarFabricada},scopedSlots:_vm._u([{key:"item.referencia.tipo",fn:function(ref){
var item = ref.item;
return [(item.referencia.detalle && item.referencia.tipo)?_c('span',[_vm._v(" "+_vm._s(item.referencia.tipo)+" ")]):_c('span',[_vm._v(" Otro ")])]}},{key:"item.referencia.detalle.ancho",fn:function(ref){
var item = ref.item;
return [(item.referencia.detalle && item.referencia.detalle.ancho)?_c('span',[_vm._v(_vm._s(item.referencia.detalle.ancho))]):(item.referencia.referenciaTipo === 'CompraVenta')?_c('span',[_vm._v(_vm._s(item.referencia.nombre))]):_vm._e()]}},{key:"item.referencia.detalle.largo",fn:function(ref){
var item = ref.item;
return [(item.referencia.detalle && item.referencia.detalle.largo)?_c('span',[_vm._v(_vm._s(item.referencia.detalle.largo))]):_vm._e(),((item.referencia.referenciaTipo === 'DetalleSobreBurbuja' || item.referencia.referenciaTipo === 'DetalleSobreFoam') && item.referencia.detalle.solapa)?_c('span',[_vm._v("+"+_vm._s(item.referencia.detalle.solapa.data))]):_vm._e(),((item.referencia.referenciaTipo === 'DetalleSobreBurbuja' || item.referencia.referenciaTipo === 'DetalleSobreFoam') && item.referencia.detalle.adhesivo)?_c('span',[_vm._v("adhesivo")]):_vm._e()]}},{key:"item.referencia.detalle.calidad",fn:function(ref){
var item = ref.item;
return [(item.referencia.detalle && item.referencia.detalle.gramaje)?_c('span',[_vm._v(_vm._s(item.referencia.detalle.gramaje.data)+"g")]):(
                item.referencia.detalle && item.referencia.detalle.espesor
              )?_c('span',[_vm._v(_vm._s(item.referencia.detalle.espesor.data)+"mm")]):_vm._e(),(item.referencia.detalle && item.referencia.detalle.precorte)?_c('span',[_vm._v("-"+_vm._s(item.referencia.detalle.precorte.data)+"pre")]):_vm._e(),(item.referencia.detalle.diametro)?_c('span',[_vm._v(" "+_vm._s(item.referencia.detalle.diametro.code))]):_vm._e(),(!item.referencia.detalle.mandril && item.referencia.detalle.tipo == 'Rollo de burbuja')?_c('span',[_vm._v(" S.M.")]):_vm._e(),(!item.referencia.detalle.mandril && item.referencia.detalle.tipo == 'Rollo de foam')?_c('span',[_vm._v(" S.M.")]):_vm._e(),(item.referencia.detalle.mandril && item.referencia.detalle.tipo == 'Minirollo de burbuja')?_c('span',[_vm._v(" C.M.")]):_vm._e(),(item.referencia.detalle.mandril && item.referencia.detalle.tipo == 'Minirollo de foam')?_c('span',[_vm._v(" C.M.")]):_vm._e()]}},{key:"item.referencia.detalle.tricapa",fn:function(ref){
              var item = ref.item;
return [(item.referencia.detalle && item.referencia.detalle.tricapa)?_c('v-icon',{attrs:{"color":"blue darken-2","outline":""}},[_vm._v("mdi-check-circle")]):_vm._e()]}},{key:"item.presupuestoDetalle.presupuesto.nota",fn:function(ref){
              var value = ref.value;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [(value)?_c('v-icon',_vm._g({attrs:{"color":"warning"}},on),[_vm._v("mdi-alert-circle")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(value)+" ")])])]}},{key:"item.createdAt",fn:function(ref){
              var item = ref.item;
return [_vm._v(_vm._s(_vm._f("fecha")(item.createdAt)))]}},{key:"item.fechaExigida",fn:function(ref){
              var item = ref.item;
return [(item.presupuestoDetalle)?_c('span',[_vm._v(_vm._s(_vm._f("fecha")(item.presupuestoDetalle.presupuesto.fechaExigida)))]):_vm._e()]}},{key:"item.presupuestoDetalle.presupuesto.recogidaPropia",fn:function(ref){
            var item = ref.item;
return [(item.presupuestoDetalle)?_c('span',[(item.presupuestoDetalle.presupuesto.recogidaPropia)?_c('v-chip',{attrs:{"outlined":"","x-small":"","color":"success"}},[_vm._v("RP")]):(
                  item.presupuestoDetalle.presupuesto.direccion.transporte
                )?_c('v-chip',{attrs:{"outlined":"","small":"","color":"primary"}},[_vm._v(_vm._s(item.presupuestoDetalle.presupuesto.direccion.transporte .proveedor))]):_vm._e()],1):_vm._e()]}},{key:"item.cerrado",fn:function(ref){
                var item = ref.item;
return [(item.cerrado == true)?_c('v-chip',{attrs:{"label":"","small":"","color":"primary"}},[_vm._v("Terminado")]):(item.albaranes.length > 0)?_c('v-chip',{attrs:{"label":"","small":"","color":"purple white--text"}},[_vm._v("Entregado")]):(
                item.cantidad <= item.cantidadFabricada && !item.cerrado
              )?_c('v-chip',{attrs:{"label":"","small":"","color":"success"}},[_vm._v("Fabricado")]):(
                item.cantidad > item.cantidadFabricada && item.cantidadFabricada
              )?_c('v-chip',{attrs:{"label":"","small":"","color":"warning"}},[_vm._v("Fa. Parcial ")]):_c('v-chip',{attrs:{"small":"","label":""}},[_vm._v("Pendiente")])]}},{key:"header.data-table-select",fn:function(){return undefined},proxy:true}],null,true),model:{value:(_vm.fabricacionesSeleccionadas),callback:function ($$v) {_vm.fabricacionesSeleccionadas=$$v},expression:"fabricacionesSeleccionadas"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }