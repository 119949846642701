<template>
  <v-container>
    <h2>Herramientas index</h2>
    <ul>
      <!-- <li><router-link :to="{name:'herramientas.referencia'}">Calcular referencia</router-link></li>
      <li><router-link :to="{name:'herramientas.precio'}">Calcular precio</router-link></li> -->
      <router-link to="/herramientas/exportar-ventas">
        <li>Exportar facturas ventas pendientes de presentar</li>
      </router-link>      
      <router-link to="/herramientas/exportar-compras">
        <li>Exportar facturas compras pendientes de presentar</li>
      </router-link>
      <router-link to="/herramientas/exportar-ventas-abonos">
        <li>Exportar facturas abonos ventas pendientes de presentar</li>
      </router-link>
      <hr class="my-5">
      <router-link to="/herramientas/importar-ventas">
        <li>Importar facturas ventas pendientes de presentar</li>
      </router-link>
      <router-link to="/herramientas/importar-compras">
        <li>Importar facturas compras pendientes de presentar</li>
      </router-link>
      <router-link to="/herramientas/importar-ventas-abonos">
        <li>Importar abonos ventas pendientes de presentar</li>
      </router-link>
    </ul>
  </v-container>
</template>

<script>
export default {
  name:'HerramientasIndex'
}
</script>