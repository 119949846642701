<template>
  <v-container>
    <v-card>
      <v-container>
        <v-text-field label="Email" v-model="mail.mail"></v-text-field>
        <v-text-field label="Contacto" v-model="mail.contacto"></v-text-field>
        <v-switch label="Enviar facturas" v-model="mail.enviarFacturas"></v-switch>
        <v-btn v-if="!mail.id" color="primary" block @click="crear">Crear mail</v-btn>
        <v-btn v-else color="primary" block @click="actualizar">Actualizar mail</v-btn>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import ClienteService from '@/services/cliente.service'
import SnackbarService from '@/services/snackbar.service'

export default {
  name: 'ClientesMailForm',

  data(){
    return{
      mail:{
        id:null,
        mail:'',
        contacto:null,
        enviarFacturas:true,
        clienteId:null,
      }
    }
  },

  methods:{
    async crear(){
      await ClienteService.addMail({
        mail:this.mail.mail,
        contacto:this.mail.contacto,
        enviarFacturas:this.mail.enviarFacturas,
        clienteId:this.mail.clienteId,
      })
      SnackbarService.set({message:'Se ha creado el mail'})
      this.$router.push({name:'clientes.show',params:{id:this.mail.clienteId}})
    },

    async actualizar(){
      await ClienteService.updateMail(this.mail.id,this.mail)
      SnackbarService.set({message:'Se ha actualizado correctamente el mail'})
      this.$router.push({name:'clientes.show',params:{id:this.mail.clienteId}})
    }
  },

  async created(){
    const {id, clienteId} = this.$route.params
    this.mail.clienteId = clienteId
    if(id){
      this.mail = await ClienteService.findMail(id)
    }
  }
}
</script>