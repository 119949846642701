var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('FacturasModal',{attrs:{"factura":_vm.seleccionado},on:{"close":_vm.cerrarModal}}),_c('v-row',[_c('v-col',[_c('h1',{staticClass:"subtitle-1 grey--text"},[_vm._v("Facturas")])])],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"solo":"","label":"Filtro","placeholder":"Insertar nombre de cliente a buscar"},model:{value:(_vm.filtroCliente),callback:function ($$v) {_vm.filtroCliente=$$v},expression:"filtroCliente"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-switch',{attrs:{"label":"Solo vencidos"},model:{value:(_vm.soloVencidas),callback:function ($$v) {_vm.soloVencidas=$$v},expression:"soloVencidas"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"loading":_vm.loading,"items":_vm.facturas,"headers":_vm.headers,"server-items-length":_vm.totalFacturas,"options":_vm.options,"dense":"","footer-props":_vm.footerProps,"disable-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.numero",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({on:{"mouseover":function($event){return _vm.fetchFactura(item.id)},"mouseleave":function($event){return _vm.fetchFactura(null)}}},on),[_c('router-link',{attrs:{"to":{ name: 'facturas.show', params: { id: item.id } }}},[_vm._v(_vm._s(item.numero))])],1)]}}],null,true)},[_c('table',[_c('thead',[_c('th',{staticClass:"text-left"},[_vm._v("Cantidad")]),_c('th',{staticClass:"text-left pl-5"},[_vm._v("Referencia")]),_c('th',{staticClass:"text-right px-5"},[_vm._v("Precio")]),_c('th',{staticClass:"text-right"},[_vm._v("Total")])]),(_vm.facturaFetched)?_c('tbody',_vm._l((_vm.facturaFetched.detalle),function(detalle){return _c('tr',{key:detalle.id},[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(detalle.cantidad))]),_c('td',{staticClass:"text-left pl-5"},[_vm._v(" "+_vm._s(detalle.referencia.nombre)+" ")]),_c('td',{staticClass:"text-right px-5"},[_vm._v(_vm._s(detalle.precio))]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s((detalle.precio * detalle.cantidad).toFixed(2))+" ")])])}),0):_c('tbody',[_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"4"}},[_vm._v("Cargando...")])])])])])]}},{key:"item.importeConAbono",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.importeConAbono).toFixed(2))+" ")]}},{key:"item.fechaVencimiento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("fecha")(item.fechaVencimiento))+" ")]}},{key:"item.importeTotal",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[(item.importeAbono > 0)?_c('v-icon',{attrs:{"small":"","color":"warning"}},[_vm._v("mdi-help-circle-outline")]):_vm._e(),_vm._v(" "+_vm._s(item.importeTotal)+" ")],1)]}}],null,true)},[_c('span',[_c('p',[_vm._v("Importe Base: "+_vm._s(item.importeTotal))]),(item.importeAbono > 0)?_c('p',[_vm._v(" Importe Abonos: "+_vm._s(item.importeAbono)+" ")]):_vm._e(),(item.importeAbono > 0)?_c('p',[_vm._v(" Importe Con Abonos Aplicados: "+_vm._s(item.importeTotal - item.importeAbono)+" ")]):_vm._e(),_c('hr'),_c('p',[_vm._v("IVA: "+_vm._s(item.iva)+"%")]),(item.recEq > 0)?_c('p',[_vm._v("Rec.Eq.: "+_vm._s(item.recEq)+"%")]):_vm._e(),(item.descuento > 0)?_c('p',[_vm._v(" Descuento: "+_vm._s(item.descuento)+"% ")]):_vm._e()])])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("fecha")(item.createdAt))+" ")]}},{key:"item.importePagado",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(
                  Number(item.importePagado) > 0 &&
                  Number(item.importePagado) >= Number(item.importeConAbono)
                )?_c('v-btn',_vm._g({attrs:{"x-small":"","color":"primary"},on:{"click":function($event){return _vm.setSeleccionado(item)}}},on),[_vm._v("Si")]):(
                  Number(item.importePagado) > 0 &&
                  Number(item.importePagado) < Number(item.importeConAbono)
                )?_c('v-btn',_vm._g({attrs:{"x-small":"","color":"warning"},on:{"click":function($event){return _vm.setSeleccionado(item)}}},on),[_vm._v("Parcial")]):_c('v-btn',_vm._g({attrs:{"x-small":"","color":new Date(item.fechaVencimiento) < new Date()
                    ? 'error'
                    : 'grey lighten-3'},on:{"click":function($event){return _vm.setSeleccionado(item)}}},on),[_vm._v("No")])]}}],null,true)},[_c('span',[_c('p',[_vm._v("Fecha pago: "+_vm._s(_vm._f("fecha")(item.fechaPago)))]),_c('p',[_vm._v("Importe pagado: "+_vm._s(item.importePagado || "Sin pago"))])])])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }