<template>
  <v-container>
    <v-card>
      <v-container>
        <!-- <v-row>
          <v-col>
            <v-btn color="primary" small>Acciones</v-btn>
          </v-col>
        </v-row> -->
        <v-row>
          <v-col>
            <h2>Transporte</h2>
            <v-text-field
              v-model="cmr.transportista.nombre"
              label="Nombre transportista"
              :error-messages="$v.cmr.transportista.nombre.$invalid?'El campo es obligatorio':null"
            ></v-text-field>
            <v-text-field
              v-model="cmr.transportista.cif"
              label="CIF transportista"
              :error-messages="$v.cmr.transportista.cif.$invalid?'El campo es obligatorio':null"
            ></v-text-field>
            <v-text-field
              v-model="cmr.transportista.direccion"
              label="Direccion transportista"
              :error-messages="$v.cmr.transportista.direccion.$invalid?'El campo es obligatorio':null"
            ></v-text-field>
            <v-text-field
              v-model="cmr.transportista.localidad"
              label="Localidad transportista"
              :error-messages="$v.cmr.transportista.localidad.$invalid?'El campo es obligatorio':null"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2>Origen/Destino</h2>
            <v-row>
              <v-col><v-text-field v-model="cmr.cp.origen" label="CP Origen" :error-messages="$v.cmr.cp.origen.$invalid?'El campo es obligatorio':null"></v-text-field></v-col>
              <v-col><v-text-field v-model="cmr.cp.destino" label="CP Destino" :error-messages="$v.cmr.cp.destino.$invalid?'El campo es obligatorio':null"></v-text-field></v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2>Datos Mercancía</h2>
            <v-row>
              <v-col
                ><v-text-field :error-messages="$v.cmr.datos.tipo.$invalid?'El campo es obligatorio':null" v-model="cmr.datos.tipo" label="Tipo Mercancía"></v-text-field
              ></v-col>
              <v-col><v-text-field :error-messages="$v.cmr.datos.kgs.$invalid?'El campo es obligatorio':null" v-model="cmr.datos.kgs" label="KGs"></v-text-field></v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2>Fecha de envio</h2>
            <InputDate v-model="cmr.fecha"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2>Matrículas</h2>
            <v-row>
              <v-col
                ><v-combobox :error-messages="$v.cmr.matriculas.camion.$invalid?'El campo es obligatorio':null" v-model="cmr.matriculas.camion" label="Matrícula camión" :items="matriculas.camion"></v-combobox
              ></v-col>
              <v-col
                ><v-combobox :error-messages="$v.cmr.matriculas.remolque.$invalid?'El campo es obligatorio':null" v-model="cmr.matriculas.remolque" label="Matrícula remolque" :items="matriculas.remolque"></v-combobox
              ></v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2>Destinatario</h2>
            <v-text-field
              v-model="cmr.cliente.nombre"
              label="Nombre cliente"
              :error-messages="$v.cmr.cliente.nombre.$invalid?'El campo es obligatorio':null"
            ></v-text-field>
            <v-text-field
              v-model="cmr.cliente.cif"
              label="CIF cliente"
              :error-messages="$v.cmr.cliente.cif.$invalid?'El campo es obligatorio':null"
            ></v-text-field>
            <v-text-field
              v-model="cmr.cliente.direccion"
              label="Direccion cliente"
              :error-messages="$v.cmr.cliente.direccion.$invalid?'El campo es obligatorio':null"
            ></v-text-field>
            <v-text-field
              v-model="cmr.cliente.localidad"
              label="Localidad cliente"
              :error-messages="$v.cmr.cliente.localidad.$invalid?'El campo es obligatorio':null"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn @click="generarCMRPDF" :disabled="$v.$invalid" block color="primary">Generar PDF</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import {required} from 'vuelidate/lib/validators'

import InputDate from "@/components/InputDate";
import AlbaranService from '@/services/albaran.service'

export default {
  name: "AlbaranesCMR",

  components: { InputDate },

  data() {
    return {
      cmr: {
        transportista: {
          nombre: "J.M. González-TRANS CHEMA, S.L.",
          cif: "B-47571575",
          direccion: "c/ Antero Garcia,3, Bl. 4, Bj.A",
          localidad: "47420 Iscar (Valladolid)",
        },
        cliente: {
          nombre: "",
          cif: "",
          direccion: "",
          localidad: "",
        },
        cp: {
          origen: "47410 Olmedo",
          destino: "",
        },
        datos: {
          tipo: "Rollos de plástico de burbuja",
          kgs: "1500",
        },
        fecha: new Date(),
        matriculas: {
          camion: "",
          remolque: "",
        },
      },
      matriculas:{
        camion:['2911-DWR','0787-JDS','5313-CSS'],
        remolque:['R-0590-BCG','R-6434-BBY','R-0668-BBX']
      }
    };
  },

  async created(){
    const {id} = this.$route.params
    if(!id) return false
    const albaran = await AlbaranService.findByPk(id)    
    console.log(albaran)
    if(!albaran) return false
    this.cmr = {
      ...this.cmr,
      cliente:{
        nombre: albaran.direccion.nombre,
        cif: albaran.cliente.cif,
        direccion: albaran.direccion.direccion,
        localidad: albaran.direccion.localidad,
      },
      cp:{
        origen:'47410 Olmedo',
        destino:albaran.direccion.localidad
      }
    }
  },

  validations:{
    cmr: {
        transportista: {
          nombre: {required},
          cif: {required},
          direccion: {required},
          localidad: {required},
        },
        cliente: {
          nombre: {required},
          cif: {required},
          direccion: {required},
          localidad: {required},
        },
        cp: {
          origen: {required},
          destino: {required},
        },
        datos: {
          tipo: {required},
          kgs: {required},
        },
        matriculas: {
          camion: {required},
          remolque: {required},
        },
      }
  },

  methods:{
    async generarCMRPDF(){
      await AlbaranService.downloadCMRPDF(this.cmr)
    }
  }
};
</script>

<style>
</style>