<template>
  <v-container>
    <h1 class="subtitle-1 grey--text">
      <router-link :to="{ name: 'facturas.index' }">Facturas</router-link>
    </h1>
    <v-card :loading="loaders.card">
      <v-card-title>Factura</v-card-title>
      <v-card-actions>
        <v-btn
          outlined
          :loading="loaders.btn"
          @click="downloadPDF"
          color="primary"
          small
          >pdf</v-btn
        >
        <v-btn
					v-if="hasFacturaEditRole"
          outlined
          color="primary"
          small
          :to="{ name: 'facturas.editar', params: { id: factura.id } }"
          >editar</v-btn
        >
				<v-btn v-if="hasFacturaDeleteRole" outlined color="error" small @click="eliminarFactura">eliminar</v-btn>
        <v-btn
          outlined
          color="primary"
          small
          :to="{
            name: 'facturas.abono.crear',
            params: { facturaId: factura.id },
          }"
          >abonar</v-btn
        >
      </v-card-actions>
      <v-card-text v-if="factura.numero">
        <p>Numero: {{ factura.numero }}</p>
        <p>Fecha: {{ factura.createdAt | fecha }}</p>
        <p>Cliente: {{ factura.cliente.nombre }}</p>
        <p v-if="factura.albaran">
          Lugar de entrega: {{ factura.albaran.direccion.nombre }}
          <v-chip v-if="factura.recogidaPropia" color="primary"
            >Recogida propia</v-chip
          >
        </p>
        <p>
          Albaran:
          <span v-if="factura.albaran"
            ><router-link
              :to="{
                name: 'albaranes.show',
                params: { id: factura.albaran.id },
              }"
              >{{ factura.albaran.numero }}</router-link
            ></span
          ><span v-else>Sin Albarán</span>
        </p>
        <p>Beneficio: {{ factura.beneficio || "No calculado" }}</p>
        <p>Pagado: {{ factura.importePagado || "Sin pagar" }}</p>
        <p>Vencimiento pago: {{ factura.fechaVencimiento | fecha }}</p>
        <p>Fecha pago: {{ factura.fechaPago | fecha }}</p>
        <p>Creado por: {{ factura.usuario }}</p>
        <p v-if="factura.abonos.length > 0">
          Abonos:
          <span v-for="abono in factura.abonos" :key="abono.id"
            ><router-link
              :to="{
                name: 'facturas.abono.show',
                params: { facturaId: factura.id, id: abono.id },
              }"
              >{{ abono.numero }}
            </router-link></span
          >
        </p>
        <!-- <p>Pedido: <span v-if="factura.pedido"><router-link :to="{name:'pedidos.show',params:{id:factura.pedido.id}}" >{{factura.pedido.numero}}</router-link></span><span v-else>SN</span></p> -->

        <v-divider class="my-5"></v-divider>

        <p>Detalles:</p>
        <ul>
          <li v-for="detalle in factura.detalle" :key="detalle.id">
            <p>
              {{ detalle.cantidad }} - {{ detalle.referencia.nombre }} -
              {{ detalle.precio }}€ 
							<span v-if="detalle.referencia.detalle.excento"> - excento impuesto</span>
            </p>
          </li>
        </ul>
        <div v-if="factura.nota">
          <p class="mt-5">Nota:</p>
          <p>{{ factura.nota }}</p>
        </div>

        <v-divider class="my-5"></v-divider>
        <table>
          <tr>
            <td>Importe Total</td>
            <td class="text-right">{{ factura.importeBaseImponible }}€</td>
          </tr>
          <tr v-if="factura.descuento">
            <td>Descuento %</td>
            <td class="text-right">{{ factura.descuento || 0 }}%</td>
          </tr>
          <tr v-if="factura.descuento">
            <td>Base Descuento</td>
            <td class="text-right">
              {{ factura.importeDescuento.toFixed(2) }}€
            </td>
          </tr>
          <tr>
            <td>Base Imponible</td>
            <td class="text-right">
              {{ factura.importeConDescuentoAplicado }}€
            </td>
          </tr>
          <tr>
            <td>KGs aplicados a impuesto plastico</td>
            <td class="text-right">{{ factura.kgs_impuesto_especial}} Kgs</td>
          </tr>
          <tr>
            <td>Base impuesto especial</td>
            <td class="text-right">{{ factura.importe_impuesto_especial }}€</td>
          </tr>
          <tr>
            <td>IVA %</td>
            <td class="text-right">{{ factura.iva }}%</td>
          </tr>
          <tr>
            <td>Base IVA</td>
            <td class="text-right">{{ factura.importeIva }}€</td>
          </tr>
          <tr v-if="factura.recEq">
            <td>RecEq %</td>
            <td class="text-right">{{ factura.recEq || 0 }}%</td>
          </tr>
          <tr v-if="factura.recEq">
            <td>Base RecEq</td>
            <td class="text-right">{{ factura.importeRecEq }}€</td>
          </tr>
          <tr>
            <td>Total</td>
            <td class="text-right">{{ factura.importeTotal }}€</td>
          </tr>
          <hr v-if="factura.abonos.length > 0" />
          <tr v-if="factura.abonos.length > 0">
            <td>Importe de Abonos</td>
            <td class="text-right">-{{ factura.importeAbono }}€</td>
          </tr>
          <tr v-if="factura.abonos.length > 0">
            <td>Total con Abonos</td>
            <td class="text-right">
              {{ factura.importeConAbono ? Number(factura.importeConAbono).toFixed(3) : '' }}€
            </td>
          </tr>
        </table>
      </v-card-text>
      <v-card-text>
        <!-- <pre>{{factura}}</pre> -->
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import FacturaService from "@/services/factura.service";
import SnackbarService from "@/services/snackbar.service";
import AuthService from "@/services/auth.service";

export default {
  name: "FacturasShow",

  data() {
    return {
      factura: {
        id: this.$route.params.id,
      },
      loaders: {
        btn: false,
        card: true,
      },
    };
  },

  async created() {
    const { id } = this.$route.params;
    if (!id) return false;
    this.factura = await FacturaService.findByPk(id);
    this.loaders.card = false;
  },

  methods: {
    async downloadPDF() {
      this.loaders.btn = true;
      await FacturaService.downloadPDF(this.factura.id);
      this.loaders.btn = false;
    },

		async eliminarFactura(){
			await FacturaService.destroy(this.factura.id)
			this.$router.push({ name: 'facturas.index'})
			SnackbarService.set({
				message:'Se ha eliminado la factura'
			})
		}
  },

	computed:{
		hasFacturaEditRole(){
			return AuthService.hasRoles('facturas.editar')
		},

		hasFacturaDeleteRole() {
			return AuthService.hasRoles('facturas.eliminar')
		}
	}
};
</script>